import { stringify } from 'querystring'
import React from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ErrorDetail } from '../models/ErrorDetail'

export const StandardErrorBox: React.FC<{headingText?: string, errors?: ErrorDetail[]}> = (props) => {
    return <>
        <Alert variant="danger">
            <Alert.Heading>{props.headingText || "Attention, there was an error"}</Alert.Heading>
            { props.errors && <ul>{props.errors.map((e, i) =>
                <li key={i} dangerouslySetInnerHTML={{ __html: e.ErrorMessage }}></li> )}</ul> }
            { props.children }
        </Alert>
    </>
}

export const InputValidationBox: React.FC<{errors?: ErrorDetail[]}> = (props) => {
    return (props.errors && props.errors.length > 0)  ? <>
        
        <Alert variant="danger">     
            {props.errors.map((e, i) =>
                <p style={{listStyle:"none"}} key={i}>{e.ErrorMessage}</p> )
            }          
        </Alert>
    </> : <></>
}

export const ValidationSummary: React.FC<{errors: ErrorDetail[], filterCode?: string}> = (props) => {    
    return (!props.errors || props.errors.length === 0)
        ? <></>
        : props.filterCode ? <InputValidationBox errors={props.errors.filter(er => er.ErrorCode == props.filterCode)}  /> : <StandardErrorBox errors={props.errors} />
}

export const ConfirmationMessageBox: React.FC = (props) => {
    return <>
        <Alert variant="success">
            <Alert.Heading>
                {props.children}
            </Alert.Heading>
        </Alert>
    </>
}

export const LoadingMessageBox: React.FC = (props) => {
    return <>
        <Alert variant="warning">
            <Alert.Heading>
                <Spinner variant="warning" animation="border" role="status" style={{marginRight: "10px"}} />
                {props.children || "Loading..."}
            </Alert.Heading>
        </Alert>
    </>
}

export const ComingSoonMessageBox: React.FC = (props) => {
    return <>
        <Alert variant="secondary">
            <Alert.Heading>
                {props.children || "Coming soon!"}
            </Alert.Heading>
        </Alert>
    </>
}

export function NotInGoodStanding() {
    return <>
        <p className="mb-0">
            Your Farm Bureau membership is no longer in good standing.
            Please <Link to="/MyMembership/PayDues">renew your membership</Link> now and regain access to your member benefits.
        </p>
    </>
}

export const EZPayOnFile: React.FC<{defaultCardLast4: string}> = (props) => {
    return <> 
        <Alert variant="secondary">
            <Alert.Heading>
                You have a card on file to automatically pay your current Dues on their due date
            </Alert.Heading>
            <div>
                <b>You do not need to pay your dues today</b> if you'd still like us to charge your card on file when your dues are due to be paid.<br />
                If you'd like to make a payment right now instead, you may use the form below to do so. <br />
                <b>
                    The card you enter below will replace the ending with {props.defaultCardLast4} as the card that we will use
                    for next year's dues.
                </b>
            </div>
        </Alert>
    </>
}
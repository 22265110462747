import React, {useState} from "react"
import PageHeading from "../../views/PageHeading"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import {ContainsAuthExpiredError, ErrorDetail} from "../../models/ErrorDetail"
import {MyMembershipPageTabs} from "../../views/myMembership/MyMembershipPageTabs"
import {Link, Redirect} from "react-router-dom"
import {HideEzPaySignupPathways} from "../../models/AccountOverview"
import {Alert, Button, Col, Container, Modal, Row, Table} from "react-bootstrap"
import {EZPayTermsAndConditionsModal} from "../../views/EZPayAlerts"
import CurrentDuesStatement from "../../views/CurrentDuesStatement"
import {GetNextDuesAmount} from "../../models/AccountOverview"
import {toMoney} from "../../Utilities"
import PaymentTermsAndConditions from "../../views/PaymentTermsAndConditions"
import {ValidationSummary} from "../../views/MessageBoxes"

export default function EZPaySummary() {
	const {errors, payload} = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload

	const [showUnenrollModal, set_showUnenrollModal] = useState(false)
	const [justUnenrolled, set_justUnenrolled] = useState(false)
	const [unenrollmentLoading, set_unenrollmentLoading] = useState(false)
	const [unenrollmentErrors, set_unenrollmentErrors] = useState<ErrorDetail[]>(
		[]
	)

	if (ContainsAuthExpiredError(errors)) {
		return <Redirect to='/login' />
	}

	// If user doesn't have an active EZPay subscription, redirect them to the form to signup
	if (accountOverview && !accountOverview.MembershipSummary.DuesPlanIsEZPay) {
		if (HideEzPaySignupPathways(accountOverview)) {
			return <Redirect to='/myMembership/payDues' />
		} else {
			return <Redirect to='/myMembership/ezPayEdit' />
		}
	}

	const statePacAmount =
		accountOverview?.CountyOffice.StatePacContributionAmount ?? 0
	const countyName = accountOverview?.CountyOffice.Name ?? ""
	const countyHasPac =
		accountOverview?.CountyOffice.CountyPacContributionAmount ?? false
	const countyPacAmount =
		accountOverview?.CountyOffice.CountyPacContributionAmount ?? 0
	const ezPaySignup =
		accountOverview?.MembershipSummary?.ActiveRecurringDuesSignup
	const includeStatePac = ezPaySignup?.IncludesStatePacContribution ?? false
	const includeCountyPac =
		(ezPaySignup?.IncludesCountyPacContribution && countyHasPac) ?? false
	const nextDuesAmount = GetNextDuesAmount(accountOverview)
	const email = accountOverview?.UserAccount.EmailAddress ?? ""

	const nextEZPayTotalAmount =
		nextDuesAmount +
		(includeStatePac ? statePacAmount : 0) +
		(includeCountyPac ? countyPacAmount : 0)

	const doUnenrollment = async () => {
		set_unenrollmentLoading(true)
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, _errors] = await MyFfbfClientApi.Payment.EndRecurringDuesSignup()
		if (_errors.length > 0) {
			set_unenrollmentErrors(_errors)
			set_unenrollmentLoading(false)
		} else {
			set_justUnenrolled(true)
			set_showUnenrollModal(false)
		}
	}

	return (
		<div className="EZPaySummary">
			<PageHeading>
				My <b>Membership</b>
			</PageHeading>
			<Container className='pageContent'>
				<Row>
					<Col>
						{justUnenrolled && (
							<>
								<Alert variant='primary'>
									<Alert.Heading>
										You have removed the card on file for Membership EZ-Pay.
									</Alert.Heading>
									<p>
										Click here to{" "}
										<Link to='/myMembership/ezPayEdit'>
											put another card on file
										</Link>{" "}
										for your next dues payment if you don't want the hassle of
										remembering to pay your annual dues..
									</p>
								</Alert>
							</>
						)}

						{accountOverview && !justUnenrolled && ezPaySignup && (
							<>
								<MyMembershipPageTabs
									activeTab='EZPay'
									accountOverview={accountOverview}
								/>

								<Alert variant='primary'>
									The following information has been authorized to be used for
									the payment of your county Farm Bureau membership dues.
									<br />
									You will receive a reminder email 60 days prior to your
									renewal due date.
									<EZPayTermsAndConditionsModal showSignupBtn={false} />
								</Alert>

								<Table>
									<tbody>
										<tr>
											<th>Last 4 of Card on File: </th>
											<td>************{ezPaySignup.DefaultCardLast4} </td>
										</tr>
										<tr>
											<th>County Dues Amount: </th>
											<td>
												<CurrentDuesStatement
													countyName={countyName}
													nextDuesAmount={nextDuesAmount}
												/>

												<span className='subtle'>&nbsp;&nbsp;(1)</span>
											</td>
										</tr>
										<tr>
											<th>Optional Contributions: </th>
											<td>
												{includeStatePac && (
													<div>
														{toMoney(statePacAmount)} donation to FarmPAC for
														Florida Farm Bureau Federation
														<span className='subtle'>&nbsp;&nbsp;(2, 3)</span>
													</div>
												)}

												{includeCountyPac && (
													<div>
														{toMoney(countyPacAmount)} donation to FarmPAC for{" "}
														{countyName} County Farm Bureau
														<span className='subtle'>&nbsp;&nbsp;(2, 3)</span>
													</div>
												)}

												{!includeStatePac && !includeCountyPac && (
													<div>
														No FarmPAC contributions selected
														<span className='subtle'>&nbsp;&nbsp;(2, 3)</span>
													</div>
												)}
											</td>
										</tr>
										<tr>
											<th>Total Amount Per Year: </th>
											<td>{toMoney(nextEZPayTotalAmount)}</td>
										</tr>
										<tr>
											<th>Your Email Address: </th>
											<td>{email}</td>
										</tr>
										<tr>
											<td colSpan={2}>
												<PaymentTermsAndConditions />
											</td>
										</tr>
									</tbody>
								</Table>

								<Alert variant='warning'>
									<Alert.Heading>
										Do you need to cancel or update the EZ-Pay Information
										above?
									</Alert.Heading>
									<div>
										You can take yourself off the automatic payment schedule at
										any time by clicking the "Unenroll me from Membership
										EZ-Pay" button below. If you'd like to alter the card on
										file, or your optional contributions, then click the "I need
										to update my payment information" button below.
									</div>
									<div style={{marginTop: "20px"}}>
										<Link
											className='btn btn-primary'
											to='/myMembership/ezPayEdit'>
											I need to update my payment information
										</Link>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button
											color='primary'
											onClick={() => set_showUnenrollModal(true)}>
											Unenroll me from Membership EZ-Pay
										</Button>
									</div>
								</Alert>

								<Modal
									show={showUnenrollModal}
									animation={false}
									onHide={() => set_showUnenrollModal(false)}>
									<Modal.Header closeButton>
										<Modal.Title>
											Are you sure you want to unenroll from Membership EZ-Pay?
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<ValidationSummary errors={unenrollmentErrors} />
									</Modal.Body>
									<Modal.Footer>
										{unenrollmentLoading ? (
											<div>
												<Button variant='primary' disabled>
													Loading...
												</Button>
											</div>
										) : (
											<div>
												<Button variant='primary' onClick={doUnenrollment}>
													Yes, unenroll me now
												</Button>{" "}
												<Button
													variant='warning'
													onClick={() => set_showUnenrollModal(false)}>
													cancel
												</Button>
											</div>
										)}
									</Modal.Footer>
								</Modal>
							</>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

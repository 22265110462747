import React, { useState, useEffect, useCallback } from "react"
import {Alert, Button, Col, Container, Row } from "react-bootstrap"
import { ContainsAuthExpiredError } from "../../models/ErrorDetail"
import { Link, Redirect, useParams } from "react-router-dom"
import OverlaySpinner from "../../views/OverlaySpinner"
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import memMeansMore from '../../images/dashboardSmallCarousel/memMeansMore.jpg'
import hireAg from '../../images/dashboardSmallCarousel/hireAg.jpg'
import buyers from '../../images/dashboardSmallCarousel/buyers.jpg'
import ford from '../../images/dashboardSmallCarousel/ford.jpg'
import fbbank from '../../images/dashboardSmallCarousel/fbbank.jpg'
import choiceHotel from '../../images/dashboardSmallCarousel/choiceHotel.jpg'
import AccountOverview from "../../models/AccountOverview"
import DeathBenefitAmount from "../../views/DeathBenefitAmount"
import MemberStatus from '../../views/MemberStatus'

const Dashboard: React.FC = () => {
    const { isLoading, errors, payload } = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload
    const { isNewlyCreated } = useParams<{isNewlyCreated: string}>()

	if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }
    if (accountOverview && accountOverview.MembershipSummary.IsTerminated) {
        return <Redirect to="/myMembership/PayDues" />
    }

    return (
        <div className="component_Dashboard">
            { isLoading && <OverlaySpinner /> }
            <div>
                <Container>
                    <BannerRotator />
                    <Row>
                        <Col>
                            { isNewlyCreated && <>
                                <Alert variant="primary">
                                    <Alert.Heading>Thank you for joining our Farm Bureau Family!</Alert.Heading>
                                </Alert>
                                <Alert variant="light">
                                    You will receive your new membership cards soon. If you need assistance regarding your membership, 
                                    please call us at 352-374-1502 or email us at FFB-Member-Services@ffbf.org
                                </Alert>
                            </> }
                        </Col>
                    </Row>
                    <Row>
                        <Col md='4' sm>
                            <GreetingMemSummary accountOverview={accountOverview} />
                        </Col>
                        <Col md='8' sm>
                            <div className="promoLinks">
                                <Link to="/memberBenefits"><img src={memMeansMore} alt="" /></Link>
                                <Link to="/memberBenefits/CareerServices"><img src={hireAg} alt="" /></Link>
                                {false && //removing Voyager content due to delay in the contract. Replacing with aest
                                 <>

                                    <Link to="/memberBenefits/Travel/">
                                        <img src="https://apps.floridafarmbureau.com/_cdn/img/FBVoyagerSquare.png" alt="" height="208" />
                                    </Link>
                                                                </>
                                }
                                <Link to="/memberBenefits/CurrentFAMASale"><img src={buyers} alt="" /></Link>
                                <Link to="/memberBenefits/AutoBoat"><img src={ford} alt="" /></Link>
                                <Link to="/memberBenefits/Financial"><img src={fbbank} alt="" /></Link>
                                <Link to="/memberBenefits/Travel"><img src={choiceHotel} alt="" /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>            
        </div> 
    )
}

export default Dashboard


const BannerRotator = () => {
    const [visibleImgItemIndex, set_visibleImgItemIndex] = useState(0)

    const items = [
        { imgSrc: "https://apps.floridafarmbureau.com/_cdn/img/WelcomeBannerCarousel.svg", to: "/myMembership/details" },
    ]

    const showNext = useCallback(() => set_visibleImgItemIndex(i => (i < items.length - 1) ? (i + 1) : 0), [items.length])
    const showPrev = () => set_visibleImgItemIndex(i => (i > 0) ? (i - 1) : (items.length - 1))

    const getItem = () => {
        // This currently only animages the FIRST image in the gallery, need to work on it later.
        return <Link to={items[visibleImgItemIndex].to} style={{animation: "fadein 2s"}}><img src={items[visibleImgItemIndex].imgSrc} alt="" /></Link>
    }

    useEffect(() => {
        if (items.length < 2)
            return
        const timer = setInterval(() => showNext(), 4000)
        return () => clearInterval(timer)
    }, [showNext, items.length])

    return <>
        <div className="component_BannerRotator" style={{marginBottom: "20px"}}>
            { getItem() }
            { items.length > 1 && 
                <div>
                    <Button variant="link" onClick={showPrev}>&lt;&lt;</Button>
                    <Button variant="link" onClick={showNext}>&gt;&gt;</Button>
                </div>
            }
        </div>
    </>
}

const GreetingMemSummary: React.FC<{accountOverview: AccountOverview | null}> = (props) => {
    return (
        <div className="component_GreetingMemSummary">
            <div className="greeting">
                <strong>Welcome</strong>
                <div>
                    { props.accountOverview 
                        ? <>{props.accountOverview.MembershipSummary.FamilyName} Family</> 
                        : <span className="loading">.</span> }
                </div>
            </div>
            <div className="membershipSummary" style={{fontWeight: "bold"}}>
                <div><b>Member Number:</b> { props.accountOverview?.MembershipSummary.MemberNumber }</div>
                <div><b>County:</b> { props.accountOverview?.CountyOffice.Name }</div>
                <div><b>Next Renewal Date:</b> { props.accountOverview?.MembershipSummary.NextRenewalDate }</div>
                <div><b>Member Status:</b> <MemberStatus memSummary={props.accountOverview?.MembershipSummary} /></div>
                <div><b>Death Benefit:</b> { props.accountOverview && <DeathBenefitAmount accountOverview={props.accountOverview} /> }</div>
            </div>
            <div className="actionButtons">
                { props.accountOverview && <>
                    { props.accountOverview.MembershipSummary.Balance > 0 
                        ? <div><Link to="/myMembership/PayDues" className="btn btn-warning btn-lg">Pay Dues Now</Link></div> 
                        : <></>  
                    }
                    <div><Link to="/myMembership/details" className="btn btn-warning btn-lg">My Membership</Link></div>
                </> }
            </div>
        </div>
    )
}




import React from 'react'
import { Modal } from 'react-bootstrap'
import spinner from '../images/spinner.svg'

// This blocks the ENTIRE page with an invisible modal with just a spinner GIF shown.

const OverlaySpinner = () => {
    return <>
        <Modal show={true} style={{visibility: "hidden"}} centered backdrop="static" animation={false} keyboard={false}>
            <Modal.Body style={{}}>
                <div style={{textAlign: "center", visibility: "visible"}}>
                    <img src={spinner} alt="" />
                </div>
            </Modal.Body>
        </Modal>
    </>
}

export default OverlaySpinner
import { CallApi } from './ApiUtilities'
import AccountOverview, { FarmBureauOfficeInfo } from '../models/AccountOverview'
import Farm from '../models/Farm'
import MyFFBFAuthDetails from '../models/MyFFBFAuthDetails' 
import LoginDetails from '../models/LoginDetails'
import { useState, useEffect } from 'react'
import { ErrorDetail } from '../models/ErrorDetail'
import { RemoveMemberContactRequest, ReplacementMembershipCardRequest, SaveMemberContactRequest } from '../models/ApiContact'
import MemberBenefit_old, { MemberBenefitCategory, MemberBenefitPreview } from '../models/MemberBenefit'
import { AccountVerficationDetails, CompletedAccountVerficationDetails, RegistrationVerificationResult } from '../models/RegistrationModels'
import { StripeCardPaymentDetail, EcheckPaymentDetail, PaymentConfirmation, RecurringDuesSignupDetail, GuestStripeCardPaymentDetail, GuestEcheckPaymentDetail, PublicKey, ProcessPaymentResult  } from '../models/PaymentDetail'
import { ChangeLoginRequest, ChangePasswordRequest } from '../models/AccountModels'
import { ResetPasswordRequestObj } from '../models/AccountModels'
import FamaFlyerDetails from '../models/FamaFlyerDetails'
import { FarmBureauOfficeInfoRequest } from '../models/NewMemberSignup'
import { NewMemberSignupRequest } from '../models/NewMemberSignup'
import Commodity from '../models/Commodity'
import MemberBenefit from '../models/MemberBenefit'

// This struct is a mapper to the MyFfbfClientApi.proj Web API
const MyFfbfClientApi = {
    Registration: {
        VerifyRegistration: async (details: AccountVerficationDetails) => 
            await CallApi<RegistrationVerificationResult>("Registration/VerifyRegistration", details),

        CompleteRegistration: async (details: CompletedAccountVerficationDetails) => 
            await CallApi<void>("Registration/CompleteRegistration", details, false),
    },

    GuestCheckout: {
        VerifyGuestCheckout: async (details: AccountVerficationDetails) => 
            await CallApi<AccountOverview>("Registration/VerifyGuestMembership", details),

        ProcessGuestStripeCardPayment: async (paymentDetails: GuestStripeCardPaymentDetail) => 
            await CallApi<ProcessPaymentResult>("Payment/ProcessGuestStripeCardPayment", paymentDetails),

        ProcessGuestECheckPayment: async (paymentDetails: GuestEcheckPaymentDetail) => 
            await CallApi<ProcessPaymentResult>("Payment/ProcessGuestECheckPayment", paymentDetails),
    },

    NewMemberSignup : {
        GetFarmBureauOfficeInfo: async (req: FarmBureauOfficeInfoRequest) => 
            await CallApi<FarmBureauOfficeInfo>("NewMemberSignup/GetFarmBureauOfficeInfo", req),
            
        ProcessNewMemberSignup: async (request: NewMemberSignupRequest) =>
            await CallApi<void>("NewMemberSignup/ProcessNewMemberSignup", request, false)
    }, 

    Auth: {
        AttemptLogin: async (loginDetails: LoginDetails) => 
            await CallApi<MyFFBFAuthDetails>("Auth/AttemptLogin", loginDetails),
    },

    Account: {
        UseAccountOverview: () => UseApiCall<AccountOverview>("Account/GetAccountOverview"),

        GetAccountOverview: async () =>
            await CallApi<AccountOverview>("Account/GetAccountOverview"),

        SubmitMessageFromUser: async (message: string) => 
            await CallApi<void>("Account/SubmitMessageFromUser", {"messageFromMember": message}, false), 

        UpdatePassword: async (details: ChangePasswordRequest) => 
            await CallApi<void>("Account/UpdatePassword", details, false),

        UpdateLogin: async (details: ChangeLoginRequest) => 
            await CallApi<void>("Account/UpdateLogin", details, false),

        ResetPasswordRequest: async (request: ResetPasswordRequestObj) => 
            await CallApi<void>("Account/ResetPasswordRequest", request, false),

        ResetPasswordVerification: async (request: ResetPasswordRequestObj) => 
            await CallApi<ResetPasswordRequestObj>("Account/ResetPasswordVerify", request),

        ResetPasswordComplete: async (request: ResetPasswordRequestObj) => 
            await CallApi<void>("Account/ResetPasswordComplete", request),

        Logout: async () => 
            await CallApi<void>("Account/Logout", null, false),
    },

    Admin: {
        // These choices are just hard coded into the EditContactModal at the moment for v0.5
        GetEthnicityOptions: "",
        GetHispanicOptions: "",
        GetIndustryOptions: "",
        GetGenderOptions: "",

        GetCommodityProduceOptions: async () => await CallApi<Commodity[]>("Commodities/GetCommoditiesList", null),
        GetCommodityInterestOptions: async () => await CallApi<Commodity[]>("Commodities/GetInterestGroupList", null),
        
        GetPublicKey: async () => await CallApi<PublicKey>("Payment/GetPublicKey"),
    },

    Farms: {
        GetAllFarms: () => UseApiCall<Farm[]>("Farms/GetAllFarms"),
        GetNearbyFarms: () => UseApiCall<Farm[]>("Farms/GetNearbyFarms"),
    },

    Benefits: {
        GetMemberBenefitCategories: async () => await CallApi<MemberBenefitCategory[]>("MemberBenefits/GetMemberBenefitCategories", null),
        GetMemberBenefits: async (categoryId: string | null) =>  
             CallApi<MemberBenefit[]>("/MemberBenefits/GetMemberBenefits", {CategoryId:categoryId} ),
        GetMemberBenefitPreviews: async (categoryId: string | null) =>  
            CallApi<MemberBenefitPreview[]>("/MemberBenefits/GetMemberBenefitPreviews", {CategoryId:categoryId} ),
    },

    Payment: {
        ProcessStripeCardPayment: async (paymentDetails: StripeCardPaymentDetail) => 
            await CallApi<ProcessPaymentResult>("Payment/ProcessStripeCardPayment", paymentDetails),

        ProcessECheckPayment: async (paymentDetails: EcheckPaymentDetail) => 
            await CallApi<ProcessPaymentResult>("Payment/ProcessECheckPayment", paymentDetails),

        EndRecurringDuesSignup: async () => 
            await CallApi<void>("Payment/EndRecurringDuesSignup", null, false),

        BeginRecurringDuesSignupWithoutPayment: async (signUpDetails: RecurringDuesSignupDetail) => 
            await CallApi<void>("Payment/BeginRecurringDuesSignupWithoutPayment", signUpDetails, false),
    },

    MemberContacts: {
        SaveMemberContact: async (contactUpdateRequest: SaveMemberContactRequest) => 
            await CallApi<void>("MemberContacts/SaveMemberContact", contactUpdateRequest, false),

        // UpdatePrimaryContactAddress: async (address: ApiMailingAddress) => 
        //     await CallApi<void>("MemberContacts/UpdatePrimaryContactAddress", address, false),
        
        RemoveMemberContact: async (removeContactRequest: RemoveMemberContactRequest) => 
            await CallApi<void>("MemberContacts/RemoveMemberContact", removeContactRequest, false),

        RequestReplacementCard: async (replacementCardRequest: ReplacementMembershipCardRequest) =>
            await CallApi<void>("MemberContacts/RequestReplacementCard", replacementCardRequest, false)
    }
}

export default MyFfbfClientApi



// This CUSTOM HOOK wraps the CallApi method with an async multi-state return response. 
// Because it is a HOOK, it cannot be used in events like form submits on onclicks, JUST "page load" spots.
// Example below:
// const { isLoading, errors, acctOverview } = UseApiCall<AccountOverview>("Account/GetOverview")
// { isLoading ? "Loading..." : 
//   errors.length > 0 : "Omg errors came back from api!" :
//   acctOverview : "U can use yur acctOverview here now" : <></> }
export function UseApiCall<T>(routeName: string, paramObj?: any) {
    const [payload, set_payload] = useState<T | null>(null)
    const [isLoading, set_isLoading] = useState(true);
    const [errors, set_errors] = useState<ErrorDetail[]>([]);

    // This is the equivalent of an "at page load" function for this component. It fires AFTER the first return{} at the bottom.
    // This means you can consume this function (see example above function), and it will update the variables as it starts/runs/finishes.
    useEffect(() => {
        const fetchData = async () => {
            const [_payload, _errors] = await CallApi<T>(routeName, paramObj)
            _errors.length > 0 
                ? set_errors(_errors) 
                : set_payload(_payload!)
            set_isLoading(false)
        }
        fetchData()
    }, [routeName, paramObj])

    // For Debugging, this runs on every state change here. You should see this run 2 TIMES ONLY, if not you are messing
    // with the dependencies ([routeName, paramObj]) too much.
    // const getLogMessage = () => `Running HandleApiCall for ${routeName}, isLoading: ${isLoading}, has errors: ${errors.length > 0}, payload set: ${!!payload}`
    // useEffect(() => console.log(getLogMessage()))

    return { isLoading, errors, payload }; 
}
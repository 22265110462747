import React, {useState} from "react"
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Row,
	Tab,
	Tabs
} from "react-bootstrap"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import {ErrorDetail} from "../../models/ErrorDetail"
import {ValidationSummary} from "../../views/MessageBoxes"
import OverlaySpinner from "../../views/OverlaySpinner"
import PageHeading from "../../views/PageHeading"

const MyAccount = () => {	
	const [oldPassword, set_oldPassword] = useState("")
	const [newPassword, set_newPassword] = useState("")
	const [newPasswordConfirm, set_newPasswordConfirm] = useState("")
	const [newLogin, set_newLogin] = useState("")
	const [newLoginConfirm, set_newLoginConfirm] = useState("")
	const [isLoading, set_isLoading] = useState(false)
	const [errors, set_errors] = useState<ErrorDetail[]>([])
	const [changeConfirmed, set_changeConfirmed] = useState(false)

	
	const {payload} = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload

	const handleSubmitChangePassword = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_isLoading(true)
		set_errors([])
		if (newPassword !== newPasswordConfirm) {
			set_errors([
				{ErrorMessage: "Please re-type your password correctly to confirm it."}
			])
		} else {
			const paramObj = {OldPassword: oldPassword, NewPassword: newPassword}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, _loginErrors] = await MyFfbfClientApi.Account.UpdatePassword(
				paramObj
			)
			if (_loginErrors.length > 0) set_errors(_loginErrors)
			else set_changeConfirmed(true)
			set_oldPassword("")
			set_newPassword("")
			set_newPasswordConfirm("")
		}
		set_isLoading(false)
	}

	const handleSubmitChangeLogin = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_isLoading(true)
		set_errors([])
		if (newLogin !== newLoginConfirm) {
			set_errors([
				{ErrorMessage: "Please re-type your login correctly to confirm it."}
			])
		} else {
			const paramObj = {OldLogin: accountOverview!.UserAccount.EmailAddress,  NewLogin: newLogin}
			
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, _loginErrors] = await MyFfbfClientApi.Account.UpdateLogin(
				paramObj
			)
			
			if (_loginErrors.length > 0) set_errors(_loginErrors)
			
			else set_changeConfirmed(true)
			set_newLogin("")
			set_newLoginConfirm("")
		}
		set_isLoading(false)
	}


	return (
		<>
			{isLoading && <OverlaySpinner />}
			<PageHeading>
				My <b>Account</b>
			</PageHeading>
			<Container className='pageContent'>
				<Row>
					<Col>
						<ValidationSummary errors={errors} />

						{changeConfirmed && (
							<Alert variant='primary'>Your password has been changed</Alert>
						)}

						<Tabs defaultActiveKey='changePassword' className='mb-3'>
							<Tab eventKey='changePassword' title='Change Your Password'>
								<Form onSubmit={handleSubmitChangePassword}>
									<Form.Group as={Row} className='mb-3'>
										<Form.Label column sm='3' htmlFor='oldPassword'>
											Old Password
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='password'
												id='oldPassword'
												value={oldPassword}
												onChange={e => set_oldPassword(e.target.value)}
											/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className='mb-3'>
										<Form.Label column sm='3' htmlFor='newPassword'>
											New Password
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='password'
												id='newPassword'
												value={newPassword}
												onChange={e => set_newPassword(e.target.value)}
											/>
										</Col>
									</Form.Group>

									<Form.Group as={Row} className='mb-3'>
										<Form.Label column sm='3' htmlFor='confirmNewPassword'>
											Confirm New Password
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='password'
												id='confirmNewPassword'
												value={newPasswordConfirm}
												onChange={e => set_newPasswordConfirm(e.target.value)}
											/>
										</Col>
									</Form.Group>

									<Form.Group as={Row} className='mb-3'>
										<Form.Label column sm='3'></Form.Label>
										<Col sm='4'>
											<Button as='input' type='submit' value='Submit' />
										</Col>
									</Form.Group>
								</Form>
							</Tab>
							<Tab eventKey='ChangeLogin' title='Change Your Login'>
								<Form onSubmit={handleSubmitChangeLogin}>
									<Form.Group as={Row} className='mb-4'>
										<Form.Label column sm='3' htmlFor='newLogin'>
											New Login
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='text'
												id='newLogin'
												value={newLogin}
												onChange={e => set_newLogin(e.target.value)}
											/>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className='mb-4'>
										<Form.Label column sm='3' htmlFor='newLoginConfirm'>
											New Login Confirm
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='text'
												id='newLoginConfirm'
												value={newLoginConfirm}
												onChange={e => set_newLoginConfirm(e.target.value)}
											/>
										</Col>
									</Form.Group>

									<Form.Group as={Row} className='mb-4'>
										<Form.Label column sm='3'></Form.Label>
										<Col sm='4'>
											<Button as='input' type='submit' value='Submit' />
										</Col>
									</Form.Group>
								</Form>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default MyAccount

import React, { useEffect, useState } from "react"
import {Container, Row, Col, Breadcrumb, Button, Card, Accordion, Modal} from "react-bootstrap"
import {Redirect, Link, useParams} from "react-router-dom"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import {ContainsAuthExpiredError} from "../../models/ErrorDetail"
import PageHeading from "../../views/PageHeading"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MemberBenefit, { MemberBenefitCategory, MemberBenefitPreview } from "../../models/MemberBenefit"
import { faBriefcase, faCar, faCarrot, faCircle, faDollarSign, faFaceGrinWide, faFilter, faHeartPulse, faHouseChimney, faPlaneUp, faSailboat,  faShareNodes, faSquare, faWrench } from "@fortawesome/free-solid-svg-icons"
import { ApiBaseUrl } from "../../api/ApiUtilities"

import { Keys } from '../../Utilities'
const LoadBenefitPreviews = async (filterCategories:string[]) => {
	var previews: MemberBenefitPreview[] = [];
	filterCategories.forEach(async function (cat) {
		var [_getBenefitPreviews, _error] = await MyFfbfClientApi.Benefits.GetMemberBenefitPreviews(cat);
		if(_getBenefitPreviews)
			previews.concat(_getBenefitPreviews)			
	})
	return await previews;
}


const Index = () => {
	const {errors, payload} = MyFfbfClientApi.Account.UseAccountOverview()	
	const accountOverview = payload
	const redirectToDisabled = accountOverview?.MembershipSummary.IsDelinquent ?? false	
	
	const [categories, set_categories] = useState<MemberBenefitCategory[] >([]);
	const [benefitPreviews, set_benefitPreviews] = useState<MemberBenefitPreview[]>([]);
	//Filters
	const [filterCategories, set_filterCategories] = useState<string[] | null>(null);
	const [doLoadBenefitPreviews, set_doLoadBenefitPreviews] = useState(false);
	const [doLoadBenefitFromLink, set_doLoadBenefitFromLink] = useState(false);
	const [selectedBenefit, set_selectedBenefit] = useState<MemberBenefit | null>(null)
	const [filterIsOpen, set_filterIsOpen] = useState(false);
	var baseUrl = window.location.pathname;
	
	if(!baseUrl.endsWith('memberBenefits')){
		baseUrl = baseUrl.slice(0, baseUrl.lastIndexOf('/'))
	}

	const LoadCategories = async function(){
		//var [_getCategories, _error] = await MyFfbfClientApi.Benefits.GetMemberBenefitCategories();
		//if(_getCategories)
		//	set_categories(_getCategories);
		//set_filterCategories(_getCategories?.map(cat=>cat.id) || []);
		//return  _getCategories;

		fetch(ApiBaseUrl+ "MemberBenefits/GetMemberBenefitCategories", { method: 'post'})
         .then((response) => response.json())
         .then((data) => {
			var t:MemberBenefitCategory[] = data.Payload;
            set_categories(data.Payload)
			var filtercats = t?.map(cat=>cat.id) || [];
            //set_filterCategories(filtercats);
			//updateFilter("")
			set_doLoadBenefitPreviews(true);
			
         })
         .catch((err) => {
            console.log(err.message);
         });
	}

	const LoadPreviews = async function(){
		var p:MemberBenefitPreview[] = [];
		//filterCategories.forEach(function (cat) {
			fetch(ApiBaseUrl+ "MemberBenefits/GetMemberBenefitPreviews", { method: 'post', body:null})
			.then((response) => response.json())
			.then((data) => {
				var t:MemberBenefitPreview[] = data.Payload;
				set_benefitPreviews(t)				
				set_doLoadBenefitFromLink(true);
			})
			.catch((err) => {
				console.log(err.message);
			});
		//});
		set_doLoadBenefitPreviews(false);
	}

	const LoadBenefit = async function(benefitId:string, handleShowModal: () => void){	
		const requestParameter = {
			BenefitId: benefitId,
			CategoryId: null,
			AuthorizationGuid: sessionStorage.getItem(Keys.authorizationGuidKeyName) //will be NULL for login/registration/guestcheckout calls and thats OK
		}	
		fetch(ApiBaseUrl + "MemberBenefits/GetMemberBenefit", {headers: {"Content-Type": "application/json"}, method: 'post', body:JSON.stringify(requestParameter) })
		.then((response) => response.json())
		.then((data) => {				
			set_selectedBenefit(data.Payload)
			handleShowModal()
		})
		.catch((err) => {
			console.log(err.message);
		});		
	}

	const updateFilter = function(catId:string){
		//var newFilter = [...filterCategories];
		var allAffectCats = [...categories.filter(cat=>cat.ParentId == catId).map(cat=>cat.id),catId];
		//var i = filterCategories.indexOf(catId);
		//if(categories.length == filterCategories.length)
			set_filterCategories(allAffectCats)
		//else if(i==-1){
		//	set_filterCategories([...filterCategories, ...allAffectCats])
		//}
		//else{
		//	var newFilter = filterCategories.filter(fc=> !allAffectCats.includes(fc));
		//	if(newFilter.length > 0)
		//		set_filterCategories(newFilter);
		//	else
		//		set_filterCategories(categories?.map(cat=>cat.id))

		//}
	}

	
	const [show, setShow] = useState(false);

	const handleClose = () => {
		
		window.history.pushState("history", "MyFFBF", baseUrl);
		setShow(false)
	};
	const handleShow = () => setShow(true);	
	const handleClick = function(){
		set_filterIsOpen(!filterIsOpen)
	}

	const { permalinkBenefit } = useParams<{permalinkBenefit: string}>()
	useEffect(()=>{			
		if(categories.length == 0)
			LoadCategories();
		
		if(doLoadBenefitPreviews)
			LoadPreviews()

		//const str = window.location.search.split("?")		
		//const parms = new Map();
		//if(str.length > 1) {
		//	const kvPairs = str[1].split("&");
		//	kvPairs.forEach(function(kv){
		//		const pair = kv.split("=");
		//		parms.set(pair[0].toString(), pair[1]);
		//	});

			
		//}

		if(permalinkBenefit != null && doLoadBenefitFromLink ){
			var t = benefitPreviews.find(p=>
				p.Name.replace(/[^a-z0-9]/gi, '').toLowerCase() == permalinkBenefit.toLowerCase()
			);
			if(t)
				LoadBenefit(t?.Id, handleShow)
			set_doLoadBenefitFromLink(false);
		}
		/*
		const loadInitialPage = ()=> {
			const fetchData = async () => {
				LoadCategories();
			if(categories.length > 0 && !previewsLoaded){
				LoadPreviews();				
			
		}
				
			}
			fetchData();
		}
		*/
		//if(categories?.length == 0){
		//	loadInitialPage() 
		//}
	}, [doLoadBenefitPreviews, doLoadBenefitFromLink, selectedBenefit, filterCategories])
	//	
	if (redirectToDisabled) {
		return <Redirect to='/memberBenefits/Disabled' />
	}
	if (accountOverview && accountOverview.MembershipSummary.IsTerminated) {
		return <Redirect to='/myMembership/PayDues' />
	}

	return (
		<div className='component_BenefitsIndex'>
			<PageHeading>
				Member <b>Benefits</b>
			</PageHeading>
			
			<Container className='pageContent'>
				<div >
					<div>
						<Accordion id="benefitsPicker" style={{marginBottom:"10px"}} defaultActiveKey="0" >
							<Card >
								<Accordion.Toggle eventKey="0" onClick={handleClick}> 
									<Card.Header style={{fontSize:"1.5em"}}>									
										Categories <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
									</Card.Header>									
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<Card.Body style={{paddingLeft:"5px", paddingRight:"5px"}}>
										<Row style={{textAlign:"center",lineHeight:"48px"}}>
											<Col>
												<button onClick={() => updateFilter("5")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("5") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}}>
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faWrench} size='2x'/>						
													</span>
													<div>Equipment</div>
												</button>                                
											</Col>
											<Col>
												<button	onClick={() => updateFilter("1")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("1") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />						
														<FontAwesomeIcon style={{color:"white", fontSize:"30px", top:"-15px", left:"-15px"}} icon={faSailboat}/>							
														<FontAwesomeIcon style={{color:"#FF671E", fontSize:"25px",top:"10px", left:"10px"}}  icon={faSquare}  />										
														<FontAwesomeIcon style={{color:"#FF671E", fontSize:"35px", top:"10px", left:"10px"}} icon={faCar} />													
														<FontAwesomeIcon style={{color:"#FF671E", width:"100px", height:"10px", top:"15px", left:"-7px"}}  icon={faSquare}  />
														<FontAwesomeIcon style={{color:"white", fontSize:"30px", top:"10px", left:"10px"}} icon={faCar} />
													</span>
													<div>Auto/Boat</div>
												</button>			
											</Col>
											<Col>
												<button onClick={() => updateFilter("2")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("2") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faPlaneUp} size='2x'/>						
													</span>
													<div>Travel</div>
												</button>			
											</Col>
											<Col>
												<button onClick={() => updateFilter("6")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("6") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faDollarSign} size='2x'/>						
													</span>
													<div>Financial</div>	
												</button>		
											</Col>
											<Col>
												<button onClick={() => updateFilter("3")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("3") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faHouseChimney} size='2x'/>						
													</span>
													<div>Home/Farm</div>	
												</button>		
											</Col>
											<Col>
												<button onClick={() => updateFilter("7")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("7") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faHeartPulse} size='2x'/>						
													</span>
													<div>Health</div>	
												</button>		
											</Col>
											<Col>
												<button onClick={() => updateFilter("4")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("4") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faFaceGrinWide} size='2x'/>						
													</span>
													<div style={{marginTop: "11px",lineHeight: "25px"}}>Attractions/<br/>Sports</div>		
												</button>	
											</Col>
											<Col>
												<button onClick={() => updateFilter("8")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("8") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faBriefcase} size='2x'/>						
													</span>
													<div>Career</div>
												</button>			
											</Col>
											<Col>
												<button onClick={() =>updateFilter("9")} 
													style={{paddingTop:"20px", opacity:!filterCategories || filterCategories.includes("9") ? "1" :  ".5"}}>
													<span className="fa-layers MemberBenefitsNav" style={{width:"6em"}} >
														<FontAwesomeIcon style={{color:"#FF671E"}}  icon={faCircle} size="4x" />							
														<FontAwesomeIcon style={{color:"white"}} icon={faCarrot} size='2x'/>						
													</span>
													<div>FAMA</div>		
												</button>	
											</Col>
										</Row>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						<Row style={{paddingLeft:"5px", paddingRight:"5px"}}>
							{benefitPreviews.filter(preview=>filterCategories && filterCategories.includes(preview.CategoryId)).map(preview => (
								<Col lg={3} xs={6} key={preview.Id} style={{height:"300px", textAlign:"center",  padding:"10px", paddingBottom:"30px",  overflow:"hidden", textOverflow: "ellipsis",}}>  
									<div style={{height:"100%", width:"100%", border:"lightgray 1px solid", borderRadius:"10px", overflow:"hidden", textOverflow: "ellipsis",}}  onClick={() => {LoadBenefit(preview.Id, handleShow)}}>
										<div style={{height:"80%", display: "flex", justifyContent: "bottom", alignItems: "center"}}>
											{preview.Thumbnail && 
												<>
													<img style={{margin:"auto", verticalAlign:"center", maxWidth:"100%", maxHeight:"80%"}} src={"data: "+preview.Thumbnail .contentType+"; base64," +preview.Thumbnail .dataToString }></img>
												</>
											}
											{!preview.Thumbnail && 
												<>
													&nbsp;
												</>
											}	
										</div>
										<div style={{backgroundColor:"lightgray", height:"20%"}}>
											<div style={{fontWeight:"bold"}}>{preview.Name}</div>						
											<div style={{fontStyle:"italic", paddingLeft:"15px", paddingRight:"15px", overflow:"hidden", textOverflow: "ellipsis"}}>{preview.Summary}</div>
										</div>
									</div>
								</Col>  
							))}
						</Row>
					</div>
					<Modal show={show} onHide={handleClose} dialogClassName="memberBenefitsModal">
						<Modal.Header closeButton>
							<Modal.Title>
								{selectedBenefit && selectedBenefit.Name ? selectedBenefit.Name : ""}
								&nbsp;
								<FontAwesomeIcon icon={faShareNodes} 
								onClick={() =>
									{
										var baseUrl = window.location.href;
										baseUrl+="/" + selectedBenefit!.Name.replace(/[^a-z0-9]/gi, '').toLowerCase();
										navigator.clipboard.writeText(baseUrl); 
										alert("Shortcut copied to clipboard\n"+baseUrl)
									}
								}/>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{selectedBenefit && <>
								{selectedBenefit!.Logo && 
									<>
										<img style={{maxWidth:"100%", maxHeight:"40vh", margin:"auto", display:"block"}} src={"data: "+selectedBenefit!.Logo.contentType+"; base64," + selectedBenefit!.Logo.dataToString }></img>
									</>
								}
								<section 
									className="not-found-controller"
									dangerouslySetInnerHTML={{ __html: selectedBenefit!.Detail }}/>
							</>
							}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</Container>
		</div>
	)
}



export default Index

import React from 'react'

const CriticalPaymentErrorMsg = () => {
    return <>
        <h5>
            Attention - we encountered an unexpected error when trying to process your payment. <br />
            Unfortunately, we cannot tell at this time if your payment went through or not. 
        </h5>

        <h5>
            In an effort to make sure you are not overcharged, do not try to purchase a new membership yet. <br />
            Instead, please contact us at 352-374-1517 or ffb-member-services@ffbf.org.
        </h5>

        <h5>
            An alert has been sent to our staff and they will be reaching out to shortly as well.
        </h5>    
    </>
}

export default CriticalPaymentErrorMsg
import React from 'react'
import { FarmBureauOfficeInfo } from '../models/AccountOverview'

const CountyOfficeDetails: React.FC<{countyOffice: FarmBureauOfficeInfo}> = (props) => {
    const countyOffice = props.countyOffice
    return <>
        <div>
            <p>
                <b>{countyOffice.Name} County Farm Bureau Office</b><br />
                <a href={"http://maps.google.com/maps?q=" + formatForQueryString(countyOffice.Address)}  rel="nofollow ugc">{countyOffice.Address}</a><br />
                {countyOffice.City}, FL {countyOffice.Zip}<br />
                Phone : {formatPhoneNumber(countyOffice.Phone)}<br />
                Fax : {formatPhoneNumber(countyOffice.Fax)}<br />
                County Secretary: {countyOffice.Secretary}
                {countyOffice.Id == "6" && 
                    <>
                        <br/>Website: <a href="http://browardfarmbureau.com">http://browardfarmbureau.com</a>
                    </>
                }
                <br />
            </p>
        </div>
    </>
}

const formatPhoneNumber = function(numberStr:string){
    if(numberStr){
        if(numberStr.length === 10){
            return numberStr.substring(0, 3) + '-' + numberStr.substring(3, 6) + '-' + numberStr.substring(6, 10);
        } else if(numberStr.length === 7){
            return numberStr.substring(0, 3) + '-' + numberStr.substring(3, 7);
        }
    }
}

const formatForQueryString = function(qStr:string){
    return qStr.replaceAll(" ", "+")
}

export default CountyOfficeDetails
import React, { useState } from "react"
import AccountOverview from "../../models/AccountOverview"
import { ApiContact, GetName, MemberContactRole } from '../../models/ApiContact'
import EditContactModal from "./EditContactModal"
import RemoveContactModal from "./RemoveContactModal"
import { ModalEvents, toFormatedPhone } from '../../Utilities'
// import { SmallMetroCard } from "../../views/MetroCard"
// import RequestCardModal from './RequestCardModal'
// import PrintCardModal from './PrintCardModal'

interface ContactCardProps {
    accountOverview: AccountOverview,
    contactRole: MemberContactRole, 
    // this needs to be envoked by this Component when this Component is done finishing/cancelling any of its Modals.
    modalEvents: ModalEvents, 
    // passing this as NULL means you are CREATING a new contact instead of EDITING this one
    contact: ApiContact, 
}

const ContactCard: React.FC<ContactCardProps> = (props) => {
    const [showEditContactModal, set_showEditContactModal] = useState(false)
    const [showRemoveContactModal, set_showRemoveContactModal] = useState(false)

    // Daisy-chaining the Modal events together because we want the modals here to close and trigger
    // a re-freshing of the data backk all the way in ManageContacts.tsx via the ModalSharedDataUpdateEvent()
    const modalEvents: ModalEvents = {
        ModalCompletedEvent: () => {
            set_showEditContactModal(false)
            set_showRemoveContactModal(false)
            props.modalEvents.ModalCompletedEvent()
        },
        ModalSharedDataUpdateEvent: () => {
            props.modalEvents.ModalSharedDataUpdateEvent()
        }
    }

    const showRemoveButton = props.contactRole !== "Primary" && props.contact.Id !== props.accountOverview.UserAccount.Contact.Id

    // Halee wants this to be shown as 'Child' for tertiary/other contact roles
    const contactRoleLabel = props.contactRole === "Other" ? "Child" : props.contactRole

    return <>
        <div className="memberContactBlock mb-3">
            <div className="memberContactBlock_window">
                <h5>{ GetName(props.contact) }, { contactRoleLabel } </h5>
                <div>
                    <div>
                        { props.contact.Phones.map(p => <div key={p.Id}>{toFormatedPhone(p.Number)} {p.Type}</div>) }
                        { props.contact.Phones.length === 0 && <>(no phone on file)</> }
                    </div>
                    <div>
                        { props.contact.Email?.Address ?? <>(no email on file)</> }
                    </div>
                    <div>
                        <button className="btn btn-sm btn-primary" onClick={() => set_showEditContactModal(true)}>Edit</button>
                        { showRemoveButton && 
                            <> | <button className="btn btn-sm btn-primary" onClick={() => set_showRemoveContactModal(true)}>Remove</button></> }
                    </div>
                </div>
            </div>
            <div className="memberContactBlock_modals">
                { showRemoveContactModal && 
                    <RemoveContactModal
                        contactId={props.contact.Id}
                        modalEvents={modalEvents} /> }

                { showEditContactModal && 
                    <EditContactModal 
                        accountOverview={props.accountOverview}
                        contactType={props.contact.Discriminator} 
                        contact={props.contact}
                        contactRoleChoices={null}
                        modalEvents={modalEvents} /> } 
            </div>
        </div>
    </>
}

export default ContactCard
import { ErrorDetail } from '../models/ErrorDetail';
import { Keys } from '../Utilities';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

//Rebuild domain from href but without the port. This ensures it works locally too. 
export const ApiBaseUrl = (window.location.hostname !== "localhost")
    ? window.location.protocol + "//" + window.location.hostname + "/MyFfbfClientApi/"         // UAT/PROD
    : window.location.protocol + "//" + window.location.hostname + "/HomesteadClientApi/";     // Local development
    // use the 'HomesteadClientApi' for local development but DONT check in.

// All calls to the MyFfbfClientApi webAPI must be POSTS for now, because that's how we're including the authorization GUID,
// which is stored in session storage by the Login page on a successful Login response.
export async function CallApi<ReturnType>(apiEndpoint: string, paramObj?: any, enforcePayload: boolean = true): Promise<[ReturnType | null, ErrorDetail[]]> {
    let result: ReturnType | null = null
    let errors: ErrorDetail[] = []
    //console.log(`${apiEndpoint} ${method} ${JSON.stringify(paramObj)}`)

    // wrap the param in the shape of the RequestParameter class (see MyFfbfClientApi)
    const requestParameter = {
        ...paramObj,
        AuthorizationGuid: sessionStorage.getItem(Keys.authorizationGuidKeyName) //will be NULL for login/registration/guestcheckout calls and thats OK
    }

    // FAIL - I tried to attach the authGuid as a custom header here but just could not
    //        get the browser to accept the response from the server for CORS-related violations. UGH.
    const config: AxiosRequestConfig = {
        headers: { "Content-Type": "application/json; charset=utf-8" }, //"x-Custom-Auth-header": `Bearer ${authGuid}`
        timeout: 20000,
    }

        ; await axios.post<any>(ApiBaseUrl + apiEndpoint, requestParameter, config)
            .then(response => { result = HandleAxiosResponse<ReturnType>(response, errors, enforcePayload) })
            .catch(ex => { HandleAxiosCatch(ex, errors) });

    if (enforcePayload && result === null && errors.length === 0) {
        errors.push({
            ErrorMessage: "An error occurred getting data from the server. Please try again later.",
            ErrorCode: "MISSING_PAYLOAD"
        })
    }
    return [result, errors]
}


// This takes the 'errors' prop from Axios.response.data and maps it to an ErrorLineItem collection.
// It expects the API response to be shaped like <{payload: T, errors: <{text: string, code: string}>[]}>
// and will kick back errors if it is not in that shape.
function HandleAxiosResponse<Type>(response: AxiosResponse, errorCollection: ErrorDetail[], enforcePayload: boolean = true) {
    //console.log("HandleAxiosResponse")
    const conversionErrors = []
    let result: any = null
    //console.log(response);
    if (!response || !response.data) {
        conversionErrors.push("Invalid response or data object.")
    }
    else {
        // check the errors prop
        if (response.data.ErrorDetails === undefined) {
            conversionErrors.push("Response.data.ErrorDetails is undefined.")
        }
        else if (!Array.isArray(response.data.ErrorDetails)) {
            conversionErrors.push("Response.data.ErrorDetails is not an array.")
        }
        else {
            if (response.data.ErrorDetails.length !== 0) {
                for (let i = 0; i < response.data.ErrorDetails.length; i++) {
                    const errorLineItem = response.data.ErrorDetails[i] as ErrorDetail
                    if (!errorLineItem.ErrorMessage && !errorLineItem.ErrorCode) {
                        conversionErrors.push(`Response.data.ErrorDetails[${i}] is not castable as ErrorDetail`)
                        break;
                    }
                    errorCollection.push(errorLineItem)
                }
            }
        }

        if (enforcePayload && response.data.Payload === undefined) {
            conversionErrors.push("Response.data.payload is missing.")
        }
        else if (enforcePayload && conversionErrors.length === 0) {
            result = response.data.Payload as Type
        }
    }

    // todo - This should do SOMETHING w/ logging if we get to a conversion error
    if (conversionErrors.length > 0) {
        //console.log(conversionErrors)
        errorCollection.push({ ErrorMessage: "A system error occurred, please try your action again later." })
        // todo - maybe push the conversion errors out to an endpoint for logging?
        //        put it in a try/catch and in the catch, log to local storage
        //        on some interval, check that localstorage and try re-posting to server
    }

    return result
}

// Handles Axios ".catch(exception=>{})" response and pushes the error to the errors param collection.
function HandleAxiosCatch(ex: any, errorCollection: ErrorDetail[]) {
    // cancelled Axios calls will trigger here but can be ignored.
    if (!axios.isCancel(ex)) {
        // todo - like 'conversionErrors' in ConvertResponse(), this should try to log the error somewhere
        console.log("HandleAxiosCatch")

        const error = (ex.code === "ECONNABORTED") ? "A network Timeout has happened" :
                      (ex.response?.status === 404) ? "The API endpoint could not be found" :
                      "An unexpected error has occurred";

        errorCollection.push({ ErrorMessage: error })
    }
}
import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import { NotInGoodStanding } from '../../views/MessageBoxes'
import PageHeading from '../../views/PageHeading'

export default function Disabled() {
    return <>
        <PageHeading />
        <Container className="pageContent">
            <Row><Col><NotInGoodStanding /></Col></Row>
        </Container>
    </>
}
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js"
import {loadStripe, Stripe, StripeElements} from "@stripe/stripe-js"
import React, {useEffect, useState} from "react"
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap"
import {Redirect} from "react-router-dom"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import AccountOverview, {GetNextDuesAmount} from "../../models/AccountOverview"
import {ContainsAuthExpiredError, ErrorDetail} from "../../models/ErrorDetail"
import {RecurringDuesSignupDetail} from "../../models/PaymentDetail"
import {toMoney, StripeUtilities} from "../../Utilities"
import CurrentDuesStatement from "../../views/CurrentDuesStatement"
import {
	EZPaySignupConfirmation,
	EZPayTermsAndConditionsModal
} from "../../views/EZPayAlerts"
import {ValidationSummary} from "../../views/MessageBoxes"
import {MyMembershipPageTabs} from "../../views/myMembership/MyMembershipPageTabs"
import OverlaySpinner from "../../views/OverlaySpinner"
import PageHeading from "../../views/PageHeading"

export default function EZPayEdit() {
	const [accountOverview, set_accountOverview] =
		useState<AccountOverview | null>(null)
	const [errors, set_errors] = useState<ErrorDetail[]>([])
	const [publicKey, set_publicKey] = useState("")

	useEffect(() => {
		async function fetchData() {
			const [_acctOvrview, _errors] =
				await MyFfbfClientApi.Account.GetAccountOverview()
			if (_errors.length > 0) set_errors(_errors)
			else {
				set_accountOverview(_acctOvrview!)
				console.log("fetch done")
			}
			var [_key, _error] = await MyFfbfClientApi.Admin.GetPublicKey()
			if (_error.length === 0) {
				set_publicKey(_key?.Key ?? "")
			} else {
				set_errors(_error)
			}
		}
		fetchData()
	}, [])

	if (!publicKey) {
		return <></>
	}
	const stripePromise = loadStripe(publicKey)

	if (ContainsAuthExpiredError(errors)) {
		return <Redirect to='/login' />
	}

	return (
		<div className="component_EZPayEdit">
			<PageHeading>
				My <b>Membership</b>
			</PageHeading>
			<Container className='pageContent'>
				<Row>
					<Col>
						{accountOverview && (
							<>
								<MyMembershipPageTabs
									activeTab='EZPay'
									accountOverview={accountOverview}
								/>

								<Elements stripe={stripePromise}>
									<ElementsConsumer>
										{({stripe, elements}) => (
											<>
												{stripe && elements && (
													<EZPayEditForm
														accountOverview={accountOverview}
														stripe={stripe}
														elements={elements}
													/>
												)}
											</>
										)}
									</ElementsConsumer>
								</Elements>
							</>
						)}{" "}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

const EZPayEditForm: React.FC<{
	accountOverview: AccountOverview
	stripe: Stripe
	elements: StripeElements
}> = props => {
	const [errors, set_errors] = useState<ErrorDetail[]>([])
	const [isLoading, set_isLoading] = useState(false)
	const [signupConfirmed, set_signupConfirmed] = useState(false)

	// temp variables for brevity
	const countyName = props.accountOverview.CountyOffice.Name ?? ""
	const nextDuesAmount = GetNextDuesAmount(props.accountOverview)
	const allowPacContributions =
		props.accountOverview.MembershipSummary.AllowPacContributions
	const memberCountyAllowsCountyPac =
		props.accountOverview.CountyOffice.CountyPacContributionAmount !== null
	const countyPacAmount =
		props.accountOverview.CountyOffice.CountyPacContributionAmount ?? 0
	const statePacAmount =
		props.accountOverview.CountyOffice.StatePacContributionAmount ?? 0
	const countyPacLabelText = !memberCountyAllowsCountyPac
		? ""
		: `$${countyPacAmount}.00 donation to FarmPAC for ${countyName} County Farm Bureau`
	const statePacLabelText = `$${statePacAmount}.00 donation to FarmPAC for Florida Farm Bureau Federation`
	const currentEZPaySignup =
		props.accountOverview.MembershipSummary.ActiveRecurringDuesSignup
	const donateToSPACDefaultChoice =
		currentEZPaySignup?.IncludesStatePacContribution ?? allowPacContributions
	const donateToCPacDefaultChoice =
		currentEZPaySignup?.IncludesCountyPacContribution ?? allowPacContributions

	// UI choices - Default the PACs to YES unless user is editing an existing EZPay signup,
	//              in which case use the pac settings on that signup for the default state
	const [donateToStatePac, set_donateToStatePac] = useState(
		donateToSPACDefaultChoice
	)
	const [donateToCountyPac, set_donateToCountyPac] = useState(
		memberCountyAllowsCountyPac && donateToCPacDefaultChoice
	)

	const nextEZPayTotalAmount =
		nextDuesAmount +
		(donateToStatePac ? statePacAmount : 0) +
		(donateToCountyPac ? countyPacAmount : 0)

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_isLoading(true)
		set_errors([])
		const card = props.elements.getElement(CardElement)
		if (!card) set_errors([{ErrorMessage: StripeUtilities.genericErrorMsg}])
		else {
			const tokenResult = await props.stripe.createToken(card)
			if (tokenResult.error) {
				console.log(tokenResult.error.message)
				set_errors([
					{
						ErrorMessage:
							tokenResult.error!.message ?? StripeUtilities.genericErrorMsg
					}
				])
			} else {
				var paramObj: RecurringDuesSignupDetail = {
					IncludeCountyPac: donateToCountyPac,
					IncludeStatePac: donateToStatePac,
					StripeToken: tokenResult.token.id
				}
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_, _errors] =
					await MyFfbfClientApi.Payment.BeginRecurringDuesSignupWithoutPayment(
						paramObj
					)
				if (_errors.length > 0) set_errors(_errors)
				else set_signupConfirmed(true)
			}
		}
		set_isLoading(false)
	}

	if (signupConfirmed) {
		return <EZPaySignupConfirmation />
	}

	return (
		<>
			{isLoading && <OverlaySpinner />}

			<Alert variant='primary'>
				The following information will be authorized to be used for the payment
				of your county Farm Bureau membership dues.
				<br />
				You will receive a reminder email 60 days prior to your renewal due
				date.
				<EZPayTermsAndConditionsModal showSignupBtn={false} />
			</Alert>

			<Form onSubmit={handleSubmit}>
				<p>
					Membership has never been easier! Add your card to your profile for
					automatic renewal of your membership every year on your due date.
				</p>
				<ValidationSummary errors={errors} />

				<Form.Group as={Row}>
					<Form.Label column sm='3'>
						Card Details
					</Form.Label>
					<Col lg='6'>
						<div className='fakeFormFieldHolder'>
							<CardElement options={StripeUtilities.cardElementOptions} />
						</div>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label column sm='3'>
						County Dues Amount
					</Form.Label>
					<Col lg='6'>
						<CurrentDuesStatement
							countyName={countyName}
							nextDuesAmount={nextDuesAmount}
						/>
					</Col>
				</Form.Group>

				{allowPacContributions && (
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							Optional Contributions
						</Form.Label>
						<Col sm='6'>
							<Form.Check
								type='checkbox'
								label={statePacLabelText}
								name='statePacDonation'
								id='statePacDonation'
								checked={donateToStatePac}
								onChange={() => set_donateToStatePac(!donateToStatePac)}
							/>

							{memberCountyAllowsCountyPac && (
								<>
									<Form.Check
										type='checkbox'
										label={countyPacLabelText}
										name='countyPacDonation'
										id='countyPacDonation'
										checked={donateToCountyPac}
										onChange={() => set_donateToCountyPac(!donateToCountyPac)}
									/>
								</>
							)}
						</Col>
					</Form.Group>
				)}

				<Form.Group as={Row}>
					<Form.Label column sm='3'>
						Total Amount per Year
					</Form.Label>
					<Col lg='6'>{toMoney(nextEZPayTotalAmount)}</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col sm='3'></Col>
					<Col sm='6'>
						<Button color='primary' type='submit'>
							Sign up for Membership EZ-Pay Now
						</Button>
					</Col>
				</Form.Group>

				<Form.Group as={Row}></Form.Group>
			</Form>
		</>
	)
}

// The React App isn't going to play nicely with the whole C# IContact/IPerson/ICompany thing.
// Thus we want to just simplify/flatten the IContact on the server into a single 'FlatContact' interface here.

export interface ApiEmailAddress {
    Id: string;
    Address: string;
}

export interface ApiMailingAddress {
    Id: string
    Line1: string;
    Line2: string;
    City: string;
    StateAbbreviation: string;
    Zip: string; 
}

export function GetMailingAddressText(address: ApiMailingAddress) {
    return `${address.Line1} ${address.Line2 ?? ""} ${address.City} ${address.StateAbbreviation} ${address.Zip}`
}

export interface ApiPhoneNumber {
    Id: string;
    Number: string;
    Type: string;
}

export type ContactType = "Company" | "Person";

export interface ApiContact {
    Id: string;
    Email?: ApiEmailAddress;
    Address: ApiMailingAddress;
    Phones: ApiPhoneNumber[];
    // This allows us to distinguish the types
    Discriminator: ContactType;

    // This will be LAST or COMPANY name from the IContact
    LastOrCompanyName: string;

    // These will only be set on a Person Contact
    FirstName?: string;
    MiddleName?: string;
    Prefix?: string;
    Suffix?: string;
    DateOfBirth?: string; // format this in the service, validate it there too

    DemographicSummary: ContactDemographicSummary | null // will be NULL if not yet saved for this contact
}

// we just collect name/DOB for child contacts, so this is a simpler struct than ApiContact
export interface ChildContact {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    DateOfBirth: string; 
}

export interface ContactDemographicBase {
    ContactId: string | null
    EthnicityId: string | null
    GenderId: string | null
    HispanicId: string | null
    IndustryId: string | null
}

export interface ContactDemographicSummary extends ContactDemographicBase {
    EthnicityValue: string | null
    GenderValue:  string | null
    HispanicValue:  string | null
    IndustryValue:  string | null
}


export interface MemberApiContactMapping {
    RoleLabel: MemberContactRole;
    RoleId: number;
    Contact: ApiContact;
    ContactIdentifier: string;
}

export function GetName(contact: ApiContact) {
    return contact.Discriminator === "Person"
        ? contact.FirstName + " " + contact.LastOrCompanyName
        : contact.LastOrCompanyName
}


export type MemberContactRole = "Primary" | "Secondary" | "Other";

export interface SaveMemberContactRequest {
    ContactToUpdate: ApiContact
    RoleLabel: MemberContactRole
}

export interface RemoveMemberContactRequest {
    ContactIdToRemove: string
}

export const GetMemberContactRoleLabel = (isBusinessMembership: boolean, role: MemberContactRole) => {
    if (isBusinessMembership) {
        return "Contact"
    }
    else if (role === "Primary") {
        return "Primary"
    }
    else if (role === "Secondary") {
        return "Spouse"
    }
    else if (role === "Other") {
        return "Dependent Child"
    }

    return "" 
}

export interface ReplacementMembershipCardRequest {
    ContactName: string
    MemberNumber: string
}

export const ContactPrefixes = [ "Mr.", "Mrs", "Ms", "Dr.", "Miss", "Rev"  ]
export const ContactSuffixes = [ "ATTY",  "CPA",  "D.O.",  "DC",  "DDS",  "DVM",  "ESQ",  "I", "II", "III", "IV", "V", 
                                 "VI", "Jr.", "M.D.", "OD", "PA", "PhD.", "PSY", "Sr."]
                                 
export const IndustryChoices = [
    { id: "1", name: "Agriculture" },
    { id: "2", name: "Healthcare" },
    { id: "3", name: "Finance / Banking" },
    { id: "4", name: "Education" },
    { id: "5", name: "Construction" },
    { id: "6", name: "Insurance" },
    { id: "7", name: "Hospitality" },
    { id: "8", name: "Technology" },
    { id: "9", name: "Government" },
    { id: "10", name: "Nonprofit " },
    { id: "11", name: "Other" },
    { id: "12", name: "Prefer not to say" },
]                                                                  

export const USStates = [
    { name: 'ALABAMA', abbreviation: 'AL'},
    { name: 'ALASKA', abbreviation: 'AK'},
    { name: 'ARIZONA', abbreviation: 'AZ'},
    { name: 'ARKANSAS', abbreviation: 'AR'},
    { name: 'CALIFORNIA', abbreviation: 'CA'},
    { name: 'COLORADO', abbreviation: 'CO'},
    { name: 'CONNECTICUT', abbreviation: 'CT'},
    { name: 'DELAWARE', abbreviation: 'DE'},
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
    { name: 'FLORIDA', abbreviation: 'FL'},
    { name: 'GEORGIA', abbreviation: 'GA'},
    { name: 'HAWAII', abbreviation: 'HI'},
    { name: 'IDAHO', abbreviation: 'ID'},
    { name: 'ILLINOIS', abbreviation: 'IL'},
    { name: 'INDIANA', abbreviation: 'IN'},
    { name: 'IOWA', abbreviation: 'IA'},
    { name: 'KANSAS', abbreviation: 'KS'},
    { name: 'KENTUCKY', abbreviation: 'KY'},
    { name: 'LOUISIANA', abbreviation: 'LA'},
    { name: 'MAINE', abbreviation: 'ME'},
    { name: 'MARYLAND', abbreviation: 'MD'},
    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
    { name: 'MICHIGAN', abbreviation: 'MI'},
    { name: 'MINNESOTA', abbreviation: 'MN'},
    { name: 'MISSISSIPPI', abbreviation: 'MS'},
    { name: 'MISSOURI', abbreviation: 'MO'},
    { name: 'MONTANA', abbreviation: 'MT'},
    { name: 'NEBRASKA', abbreviation: 'NE'},
    { name: 'NEVADA', abbreviation: 'NV'},
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
    { name: 'NEW JERSEY', abbreviation: 'NJ'},
    { name: 'NEW MEXICO', abbreviation: 'NM'},
    { name: 'NEW YORK', abbreviation: 'NY'},
    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
    { name: 'OHIO', abbreviation: 'OH'},
    { name: 'OKLAHOMA', abbreviation: 'OK'},
    { name: 'OREGON', abbreviation: 'OR'},
    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
    { name: 'RHODE ISLAND', abbreviation: 'RI'},
    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
    { name: 'TENNESSEE', abbreviation: 'TN'},
    { name: 'TEXAS', abbreviation: 'TX'},
    { name: 'UTAH', abbreviation: 'UT'},
    { name: 'VERMONT', abbreviation: 'VT'},
    { name: 'VIRGINIA', abbreviation: 'VA'},
    { name: 'WASHINGTON', abbreviation: 'WA'},
    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
    { name: 'WISCONSIN', abbreviation: 'WI'},
    { name: 'WYOMING', abbreviation: 'WY' }
]                                 
import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { ModalEvents } from '../../Utilities'
import { ApiContact, ApiMailingAddress, SaveMemberContactRequest, USStates } from "../../models/ApiContact"
import { ContainsAuthExpiredError, ErrorDetail } from "../../models/ErrorDetail"
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { Redirect } from 'react-router'

const EditAddessModal: React.FC<{modalEvents: ModalEvents, primaryContact: ApiContact}> = (props) => {
    const [isLoading, set_isLoading] = useState(false);
    const [errors, set_errors] = useState<ErrorDetail[]>([])

    const [line1, set_line1] = useState(props.primaryContact.Address.Line1)
    const [line2, set_line2] = useState(props.primaryContact.Address.Line2)
    const [city, set_city] = useState(props.primaryContact.Address.City)
    const [state, set_state] = useState(props.primaryContact.Address.StateAbbreviation)
    const [zip, set_zip] = useState(props.primaryContact.Address.Zip)

    const generateAddressObj = (): ApiMailingAddress => {
        return {
            Id: props.primaryContact.Address.Id,
            Line1: line1,
            Line2: line2,
            City: city,
            StateAbbreviation: state,
            Zip: zip, 
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        set_isLoading(true)

        // Editing the address really means editing the PRIMARY CONTACT and mutating their address and then saving.
        props.primaryContact.Address = generateAddressObj()
        const paramObj: SaveMemberContactRequest = { RoleLabel: "Primary", ContactToUpdate: props.primaryContact }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, _errors] = await MyFfbfClientApi.MemberContacts.SaveMemberContact(paramObj)
        if (_errors.length > 0) {
			set_errors(_errors)
		}
        else {
            props.modalEvents.ModalSharedDataUpdateEvent()
            props.modalEvents.ModalCompletedEvent()
        }
        set_isLoading(false)
    }

    if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }

    return <>
        <Modal show={true} size="lg" animation={false} onHide={props.modalEvents.ModalCompletedEvent}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Your Mailing Address!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <Container>
                        <Form.Group as={Row} className="mb-3">
                            <Col md="3" sm><Form.Label htmlFor="addr1">Address Line 1</Form.Label></Col>
                            <Col md="9" sm>
                                <Form.Control id="addr1" type="text" value={line1} onChange={e => set_line1(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col md="3" sm><Form.Label htmlFor="addr2">Address Line 2</Form.Label></Col>
                            <Col md="9" sm>
                                <Form.Control id="addr2" type="text" value={line2} onChange={e => set_line2(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col md="3" sm><Form.Label htmlFor="city">City</Form.Label></Col>
                            <Col md="9" sm>
                                <Form.Control id="city" type="text" placeholder="City" value={city} onChange={e => set_city(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col md="3" sm><Form.Label htmlFor="state">State</Form.Label></Col>
                            <Col md="9" sm>
                                <Form.Control as="select" id="state" value={state} onChange={e => set_state(e.target.value)}>
                                    <option></option>
                                    { USStates.map(s => <option>{s.abbreviation}</option>) }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col md="3" sm><Form.Label htmlFor="zip">Zip (5-digit)</Form.Label></Col>
                            <Col md="9" sm>
                                <Form.Control type="text" maxLength={5} id="zip" placeholder="ZIP" value={zip} onChange={e => set_zip(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <label style={{fontSize:".8em"}}>
                            Changes to your address will only update your Florida Farm Bureau membership mailing address. 
                            To update your address on any Florida Farm Bureau Insurance Company insurance policies, please visit&nbsp; 
                            <a href="https://floridafarmbureau.com">https://floridafarmbureau.com</a> or contact your agent.
                        </label>
                   </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        { isLoading 
                            ? <Button disabled type="submit">Saving...Please wait...</Button>
                            : <Button type="submit">Save</Button> }
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default EditAddessModal
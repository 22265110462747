import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import PageHeading from '../../views/PageHeading'
import { ContainsAuthExpiredError, ErrorDetail } from "../../models/ErrorDetail"
import { Redirect } from "react-router-dom"
import CountyOfficeDetails from '../../views/CountyOfficeDetails'
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import OverlaySpinner from '../../views/OverlaySpinner'
import { ValidationSummary } from '../../views/MessageBoxes'

const ContactUs = () => {
    const { errors, payload } = MyFfbfClientApi.Account.UseAccountOverview() 
	const accountOverview = payload
    
    const [messageBody, set_messageBody] = useState("")
    const [isLoading, set_isLoading] = useState(false)
    const [showConfirmation, set_showConfirmation] = useState(false)
    const [messageErrors, set_messageErrors] = useState<ErrorDetail[]>([])

    const inputRef = useRef<HTMLTextAreaElement>(null)

    // Focus on the textarea at pageload.
	useEffect(() => inputRef?.current?.focus(), [])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        set_isLoading(true)
        set_messageErrors([])

        if (!messageBody) {
            set_messageErrors([{ ErrorMessage: "Please enter a message." }])
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, _errors] = await MyFfbfClientApi.Account.SubmitMessageFromUser(messageBody)
            if (_errors.length > 0) {
                set_messageErrors(_errors)
            }
            else {
                set_messageBody("")
                set_showConfirmation(true)
            }
        }
        set_isLoading(false)
    }

	if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }
    if (accountOverview && accountOverview.MembershipSummary.IsTerminated) {
        return <Redirect to="/myMembership/PayDues" />
    }

    return <>
        { isLoading && <OverlaySpinner /> }
        <PageHeading>
            Contact <b>Us</b>
        </PageHeading>
        <Container className="pageContent">
            <ValidationSummary errors={messageErrors} />
            { showConfirmation && 
                <Row>
                    <Col>
                        <div>
                            <Alert variant="primary">
                                We love hearing from our members, and value your feedback. 
                                Thank you for helping us to improve your experience as a Florida Farm Bureau member. 
                                We look forward to getting back in touch with you very soon!
                            </Alert>
                        </div> 
                    </Col>
                </Row>
            }
            { accountOverview && 
                <Row>
                    <Col md={7} sm>
                        <h2>
                            <img style={{maxWidth: "50px", marginRight: "10px"}} alt="message us" src={'https://apps.floridafarmbureau.com/_cdn/img/mail-5709-128.png'}/>                          
                            MESSAGE US
                        </h2>
                        <Form onSubmit={handleSubmit}>
                            <p>
                                We are here to help you have the best experience possible with both your membership and your 
                                MyFFBF experience. Please submit any comments, feedback, or questions you have, and a member 
                                of our team will get back to you as soon as possible.
                            </p>
                            <Form.Group controlId="formMessage" className="mb-3">
                                <Form.Label htmlFor="messageBody">Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="messageBody"
                                    ref={inputRef}
                                    value={messageBody}
                                    onChange={e => set_messageBody(e.target.value)}
                                />
                            </Form.Group>
                            <Button color="primary" type="submit">Send your message now</Button>
                        </Form>
                        <br/>
                        <p>
                            Please consider taking our member satisfaction survey about your experience.<br/>
                            <a href='https://www.surveymonkey.com/r/ffbfmember'>Click here</a>
                        </p>
                    </Col>
                    <Col md={1} className="mb-3" sm></Col>
                    <Col md={4} sm>
                        <h2>
                            <img style={{maxWidth: "50px", marginRight: "10px"}} alt="get in touch" src={'https://apps.floridafarmbureau.com/_cdn/img/pin-50-128.png'}/>  
                            GET IN TOUCH
                        </h2>
                        <CountyOfficeDetails countyOffice={accountOverview.CountyOffice} />
                    </Col>
                </Row>
            }
        </Container>
    </>
}

export default ContactUs
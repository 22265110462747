import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { Keys } from "../../Utilities"
import OverlaySpinner from '../../views/OverlaySpinner'
import PageHeading from "../../views/PageHeading"

const Logout: React.FC = () => {
    const [readyToRedirect, set_readyToRedirect] = useState(false)

    useEffect(() => {
        // todo - if there's time, add an API call/route to kill the session on the server.
        sessionStorage.removeItem(Keys.authorizationGuidKeyName);
        set_readyToRedirect(true)
    }, [])

    if (readyToRedirect) {
        return <Redirect to="/login" />;
    }

    return <>
        <PageHeading>Logging <b>Out</b></PageHeading>
        <Container className="pageContent">
            <OverlaySpinner />
        </Container>
    </>
}

export default Logout
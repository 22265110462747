import React, { useEffect, useMemo, useState } from "react"
import { Elements, ElementsConsumer, CardElement } from "@stripe/react-stripe-js"
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js"
import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { ContainsErrorAfterVendorPayment, ErrorDetail } from "../../models/ErrorDetail"
import { Keys, MakeRandomId, StripeUtilities } from "../../Utilities"
import { ValidationSummary } from "../../views/MessageBoxes"
import CriticalPaymentErrorMsg from '../../views/CriticalPaymentErrorMsg'
import AccountOverview, { GenerateEmptyAcctOverview } from "../../models/AccountOverview"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import { GenerateCardPaymentDetail, GeneratePaymentHelperObj } from "../../models/PaymentDetail"
import { ApiContact, ApiMailingAddress, ChildContact, ContactPrefixes, ContactSuffixes, ContactType, USStates } from "../../models/ApiContact"
import { NewMemberSignupRequest } from "../../models/NewMemberSignup"
import OverlaySpinner from "../../views/OverlaySpinner"
import Commodity from '../../models/Commodity'
import PaymentTermsAndConditions from '../../views/PaymentTermsAndConditions'
import HomesteadLogoLink from "../../layout/HomesteadLogoLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons"

const PurchaseMembership = () => {
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [isLoading, set_isLoading] = useState(false)
    const isLocal = (window.location.hostname === "localhost")
    // This is not a REAL AccountOveriew, but is mocked up here after we fetch the County Farm Bureau Office.
    // It is mocked and not fetched from the server, so not all props are hydrated.
    // Why do this? - Because the payment form has helper methods that need AccountOverview and mocking
    // it up here seems easier than re-factoring those helper methods at the moment.
    const [mockAccountOverview, set_mockAccountOverview] = useState<AccountOverview | null>(null)

    // User inputs for new membership basic details
    const [countyId, set_countyId] = useState(isLocal ? "1" : "")
    const [contactTypeString, set_contactTypeString] = useState(isLocal ? "Person" : "")
    const [memberClass, set_memberClass] = useState(isLocal ? "4" : "")
    const [marriageDesc, set_marriageDesc] = useState(isLocal ? "4" : "")
    const [is501c3, set_is501c3] = useState<null | boolean>(null)
    const spouseRequired = (marriageDesc === "1" || marriageDesc === "2")
    const childrenRequired = (marriageDesc === "2" || marriageDesc === "4")

    // Commodities and Interests - fetched after county/contacttype/class selected
    const [commodityProduceOptions, set_commodityProduceOptions] = useState<Commodity[]>([])
    const [commodityInterestOptions, set_commodityInterestOptions] = useState<Commodity[]>([])

    const contactType: ContactType = (contactTypeString === "Person") ? "Person" : "Company"
    const [publicKey, set_publicKey] = useState("")

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            var [_key, _error] = await MyFfbfClientApi.Admin.GetPublicKey();
            if (mounted) {
                if(_error.length === 0){
                    set_publicKey(_key?.Key ?? "")
                } else{
                    set_errors(_error)
                }
            }
        }
        fetchData()
        return () => { mounted = false }
    }, [])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        set_isLoading(true)
        set_errors([])

        if (!countyId)
            set_errors(errors => [{ ErrorMessage: "Please select a county.", ...errors }])

        if (!contactTypeString || !memberClass 
            || (contactTypeString === "Person" && !marriageDesc )
            || (contactTypeString === "Company" && is501c3 == null))
            set_errors(errors => [{ ErrorMessage: "Please choose the statements that best describes you.", ...errors }])   

        if (countyId && contactTypeString && memberClass) {
            const [_county, _errors] = await MyFfbfClientApi.NewMemberSignup.GetFarmBureauOfficeInfo({ CountyId: countyId })
            if (_errors.length > 0) {
                set_errors(_errors)
            }
            else {
                // Generate the mocked AccountOverview here
                const _acctOverview = GenerateEmptyAcctOverview()
                _acctOverview.CountyOffice = _county!
                _acctOverview.MembershipSummary.Balance = _county!.DuesStructure.filter(d => d.MemberClassId === memberClass)[0].DuesAmount
                _acctOverview.MembershipSummary.AllowPacContributions = true
                _acctOverview.MembershipSummary.IsBusinessMembership = (contactTypeString === "Company")
                
                // Fetch commodities and interests from Server, pass them into component
                const _getCommods = MyFfbfClientApi.Admin.GetCommodityProduceOptions()
                const _getIntersts = MyFfbfClientApi.Admin.GetCommodityInterestOptions()

                const [_commods, _CommodsErrors] = await _getCommods
                const [_intersts, _InterstsErrors] = await _getIntersts

                const allErrors = [ ..._CommodsErrors, ..._InterstsErrors ]
                if (allErrors.length > 0) {
                    set_errors(allErrors)
                }
                else {
                    set_commodityProduceOptions(_commods!)
                    set_commodityInterestOptions(_intersts!)
                    set_mockAccountOverview(_acctOverview)
                }
            }
        }

        set_isLoading(false)
    }

    if(!publicKey){
        return <></>
    }
    
    //console.log("ok is" +publicKey) 
    const stripePromise = loadStripe(publicKey);

    return (
        <div className="component_PurchaseMembership">
            <header className='topNavBar blueBackground'>
                <Navbar
                    className='blueBackground'
                    variant='dark'
                    expand='lg'>
                    <Container>
                        <Navbar.Brand href='#home'>
                            <HomesteadLogoLink linkTarget="login" />
                        </Navbar.Brand>
                    </Container>
                </Navbar>
			</header>

            <div className="pageCaption">
                <Container>
                    <h3>Florida Farm Bureau Membership Application</h3>
                    <p>You don't have to be a farmer to be a member of Farm Bureau. Farm Bureau works for us all!</p>
                </Container>
            </div> 

            <Container className='pageContent'>
                { !mockAccountOverview && <>
                    <ValidationSummary errors={errors} />
                    <Form onSubmit={handleSubmit}>
                        <h4 className="newMemberHeader">Membership Type Information</h4>

                        <Form.Group as={Row} className='mb-3' controlId="formCounty">
                            <Form.Label column md={4} sm>
                                Membership County
                            </Form.Label>
                            <Col md={5} sm>
                                <Form.Control as="select" value={countyId}
                                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_countyId(ev.target.value)}>
                                    <option value="">(please select)</option>
                                    <option value="1">ALACHUA</option>
                                    <option value="2">BAKER</option>
                                    <option value="3">BAY</option>
                                    <option value="4">BRADFORD</option>
                                    <option value="5">BREVARD</option>
                                    <option value="6">BROWARD</option>
                                    <option value="7">CALHOUN / GULF</option>
                                    <option value="10">CLAY</option>
                                    <option value="11">COLLIER</option>
                                    <option value="12">COLUMBIA</option>
                                    <option value="13">DADE</option>
                                    <option value="14">DESOTO / CHARLOTTE</option>
                                    <option value="16">DUVAL</option>
                                    <option value="17">ESCAMBIA</option>
                                    <option value="18">FLAGLER</option>
                                    <option value="20">GADSDEN</option>
                                    <option value="21">GILCHRIST</option>
                                    <option value="24">HAMILTON</option>
                                    <option value="25">HARDEE</option>
                                    <option value="26">HENDRY / GLADES</option>
                                    <option value="27">HERNANDO / CITRUS</option>
                                    <option value="28">HIGHLANDS</option>
                                    <option value="29">HILLSBOROUGH</option>
                                    <option value="30">HOLMES</option>
                                    <option value="31">INDIAN RIVER</option>
                                    <option value="32">JACKSON</option>
                                    <option value="33">JEFFERSON</option>
                                    <option value="34">LAFAYETTE</option>
                                    <option value="35">LAKE</option>
                                    <option value="36">LEE</option>
                                    <option value="37">LEON</option>
                                    <option value="38">LEVY</option>
                                    <option value="39">LIBERTY</option>
                                    <option value="40">MADISON</option>
                                    <option value="41">MANATEE</option>
                                    <option value="42">MARION</option>
                                    <option value="43">MARTIN</option>
                                    <option value="45">NASSAU</option>
                                    <option value="46">OKALOOSA</option>
                                    <option value="47">OKEECHOBEE</option>
                                    <option value="48">ORANGE</option>
                                    <option value="49">OSCEOLA</option>
                                    <option value="50">PALM BEACH</option>
                                    <option value="68">WESTERN PALM BEACH</option>
                                    <option value="51">PASCO</option>
                                    <option value="52">PINELLAS</option>
                                    <option value="53">POLK</option>
                                    <option value="54">PUTNAM / ST JOHNS</option>
                                    <option value="56">ST LUCIE</option>
                                    <option value="57">SANTA ROSA</option>
                                    <option value="58">SARASOTA</option>
                                    <option value="59">SEMINOLE</option>
                                    <option value="60">SUMTER</option>
                                    <option value="61">SUWANNEE</option>
                                    <option value="62">TAYLOR</option>
                                    <option value="63">UNION</option>
                                    <option value="64">VOLUSIA</option>
                                    <option value="65">WAKULLA</option>
                                    <option value="66">WALTON</option>
                                    <option value="67">WASHINGTON</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-3' controlId="formYourInfo">
                            <Form.Label column md={4} sm>
                                Choose the statements that best describes you
                            </Form.Label>
                            <Col md={5} sm>
                                <Form.Control as="select" value={contactTypeString}
                                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_contactTypeString(ev.target.value)}>
                                    <option value="">(please select)</option>
                                    <option value="Person">I'm an individual</option>
                                    <option value="Company">I'm representing a Business</option>
                                </Form.Control>
                                <div style={{height: "12px"}}></div>
                                <div style={{height: "12px"}}></div>
                                {contactTypeString == "Company" && <>
                                    Are you a 501(c)3 organization such as a church, 4H, FFA or any other non-profit?
                                    <Form.Control as="select" 
                                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_is501c3(ev.target.value == "true")}>
                                        <option value="">(please select)</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Form.Control>
                                    <div style={{height: "12px"}}></div></>
                                }
                                <Form.Control as="select" value={memberClass}
                                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_memberClass(ev.target.value)}>
                                    <option value="">(please select)</option>
                                    <option value="1">I'm a fulltime farmer</option>
                                    <option value="2">I am a part-time farmer</option>
                                    <option value="3">I am an employee of a farm</option>
                                    <option value="4">I am neither a farmer nor an employee of a farm</option>
                                </Form.Control>
                                <div style={{height: "12px"}}></div>
                                { contactTypeString === "Person" &&
                                    <Form.Control as="select" value={marriageDesc}
                                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_marriageDesc(ev.target.value)}>
                                        <option value="">(please select)</option>
                                        <option value="1">I'm married</option>
                                        <option value="2">I'm married with children (under the age of 21)</option>
                                        <option value="3">I'm single</option>
                                        <option value="4">I'm single with children (under the age of 21)</option>
                                    </Form.Control>
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col md={4} sm></Col>
                            <Col md={6} sm>
                                { isLoading 
                                    ? <Button disabled>Loading...</Button> 
                                    : <Button color="primary" type="submit">Next</Button>}
                            </Col>
                        </Form.Group>

                    </Form>
                </> }

                { mockAccountOverview && commodityProduceOptions && commodityInterestOptions && <>
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>  
                            {({ stripe, elements }) => <>
                                { stripe && elements && 
                                    <PurchaseMembershipPayDuesForm 
                                        stripe={stripe} 
                                        elements={elements}
                                        is501c3 = {is501c3}
                                        mockAccountOverview={mockAccountOverview}
                                        contactType={contactType}
                                        memberClass={memberClass}
                                        commodityProduceOptions={commodityProduceOptions}
                                        commodityInterestOptions={commodityInterestOptions}
                                        countyId={countyId}
                                        spouseRequired={spouseRequired && contactTypeString === "Person"}
                                        childrenRequired={childrenRequired && contactTypeString === "Person"} />
                                }
                            </>}              
                        </ElementsConsumer>
                    </Elements>
                </> }
            </Container>
        </div>
    )
}

export default PurchaseMembership;

interface PurchaseMembershipPayDuesFormProps {
    stripe: Stripe
    elements: StripeElements
    is501c3: null | boolean
    mockAccountOverview: AccountOverview
    contactType: ContactType
    countyId: string
    memberClass: string
    commodityProduceOptions: Commodity[]
    commodityInterestOptions: Commodity[]
    spouseRequired: boolean
    childrenRequired: boolean
}

const PurchaseMembershipPayDuesForm: React.FC<PurchaseMembershipPayDuesFormProps> = (props) => {
    //const myRef = React.createRef()

    const isLocal = (window.location.hostname === "localhost")

    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [isLoading, set_isLoading] = useState(false)
    const [hasCriticalError, set_hasCriticalError] = useState(false)

    // User inputs for card/echeck
    const [paymentTypeIsCard, set_paymentTypeIsCard] = useState(true)
    const [donateToCountyPac, set_donateToCountyPac] = useState(true) // SPAC is checked by default, per biz rules
    const [donateToStatePac, set_donateToStatePac] = useState(true)   // CPAC is checked by default, per biz rules
    const [termsAccepted, set_termsAccepted] = useState(isLocal ? true : false)

    // User inputs for echeck
    const [accountNumber, set_accountNumber] = useState("")
    const [verifyAccountNumber, set_verifyAccountNumber] = useState("")
    const [routingNumber, set_routingNumber] = useState("")
    const [accountIsChecking, set_accountIsChecking] = useState(true)
    const [nameOnAccount, set_nameOnAccount] = useState("")
    const [bankName, set_bankName] = useState("")
    
    const fakeEmail = (MakeRandomId(12) + "@gmail.com")

    // Person contact
    const [firstName, set_firstName] = useState(isLocal ? "Greg" : "")
    const [middleName, set_middleName] = useState(isLocal ? "" : "")
    const [lastName, set_lastName] = useState(isLocal ? "Petterson" : "")
    const [dob, set_dob] = useState(isLocal ? "1978-04-29" : "")
    const [prefix, set_prefix] = useState(isLocal ? "" : "")
    const [suffix, set_suffix] = useState(isLocal ? "" : "")
    const [email, set_email] = useState(isLocal ? fakeEmail : "")
    const [emailConfirm, set_emailConfirm] = useState(isLocal ? fakeEmail : "")  
    const [phone, set_phone] = useState(isLocal ? "555-555-5555" : "")
    const [phoneType, set_phoneType] = useState("home")
    const [addrLine1, set_addrLine1] = useState(isLocal ? "500 Main St" : "")
    const [addrLine2, set_addrLine2] = useState(isLocal ? "" : "") 
    const [addrCity, set_addrCity] = useState(isLocal ? "Gainesville" : "")
    const [addrState, set_addrState] = useState("FL")
    const [addrZip, set_addrZip] = useState(isLocal ? "32606" : "")

    // Spouse contact
    const [spousefirstName, set_spousefirstName] = useState("")
    const [spousemiddleName, set_spousemiddleName] = useState("")
    const [spouselastName, set_spouselastName] = useState("")
    const [spousedob, set_spousedob] = useState("")
    const [spouseprefix, set_spouseprefix] = useState("")
    const [spousesuffix, set_spousesuffix] = useState("")
    const [spouseemail, set_spouseemail] = useState("")
    const [spousephone, set_spousephone] = useState("")
    const [spousephoneType, set_spousephoneType] = useState("")
    // We copy the Primary contact's address into the Spouse contact obj instead of re-collecting it from user.

    const getInitialChildContacts = (): ChildContact[] => {
        return (!props.childrenRequired)
            ? []
            : [getDefaultChildContact()]
    }

    const getDefaultChildContact = () => {
        return { 
            FirstName: isLocal ? "Cindy" : "", 
            MiddleName: isLocal ? "" : "", 
            LastName: isLocal ? "Petters" : "", 
            DateOfBirth: isLocal ? "2009-11-04" : "" 
        }
    }

    // Child contacts
    // ApiContact
    // const [phones, set_phones] = useState(props.contact?.Phones ?? [])
    const [childContacts, set_childContacts] = useState<ChildContact[]>(getInitialChildContacts())

    // Commodity & Interests
    const [selectedProduceCommodityIds, set_selectedProduceCommodityIds] = useState<string[]>([])
    const [selectedInterestCommodityIds, set_selectedInterestCommodityIds] = useState<string[]>([])

    // MyFFBF account details
    const [password, set_password] = useState(isLocal ? "Appleapple01" : "")
	const [passwordConfirm, set_passwordConfirm] = useState(isLocal ? "Appleapple01" : "")

    const [referredBy, set_referredBy] = useState("")

    // For when everything is done and its time to jump into dashboard
    const [readyToRedirect, set_readyToRedirect] = useState(false)

    const handleChildFirstNameChange = (index: number, event: any) => {
        const values = [...childContacts]
        values[index].FirstName = event.target.value
        set_childContacts(values);
    };

    const handleChildMiddleNameChange = (index: number, event: any) => {
        const values = [...childContacts]
        values[index].MiddleName = event.target.value
        set_childContacts(values);
    };

    const handleChildLastNameChange = (index: number, event: any) => {
        const values = [...childContacts]
        values[index].LastName = event.target.value
        set_childContacts(values);
    };

    const handleChildDateOfBirthChange = (index: number, event: any) => {
        const values = [...childContacts]
        values[index].DateOfBirth = event.target.value
        set_childContacts(values);
    };

    const handleAddChild = () => {
        const values = [...childContacts];
        values.push(getDefaultChildContact());
        set_childContacts(values);
    };

    const handleRemoveChild = (index: number) => {
        const values = [...childContacts]
        values.splice(index, 1)
        set_childContacts(values)
    };

    // 'useMemo' is a caching function, it only reconstructs the returned obj on a re-render if something in the 
    // dependecy array has changed. Since users will keyup a lot of details on this page,
    // this caching is beneficial since each keyup triggers a re-render.
    const paymentHelperObj = useMemo(() => 
        GeneratePaymentHelperObj(props.mockAccountOverview, !props.is501c3 && donateToStatePac, !props.is501c3 && donateToCountyPac), 
        [props.mockAccountOverview, donateToStatePac, donateToCountyPac])

    const handleProduceCommodityCheckEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_selectedProduceCommodityIds(selectedIds => {
            return (event.target.checked)
                ? [...selectedIds, event.target.value]
                : selectedIds.filter(id => id !== event.target.value)
        })
    }

    const handleInterestCommodityCheckEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_selectedInterestCommodityIds(selectedIds => {
            return (event.target.checked)
                ? [...selectedIds, event.target.value]
                : selectedIds.filter(id => id !== event.target.value)
        })
    }

    const generateAddressObj = (): ApiMailingAddress => {
        return {
            Id: "",
            Line1: addrLine1,
            Line2: addrLine2,
            City: addrCity,
            StateAbbreviation: addrState,
            Zip: addrZip, 
        }
    }

    const generatePrimaryContactObj = (): ApiContact => {
        return {
            Id: "",
            Email: { Id: "", Address: email },
            Address: generateAddressObj(),
            Phones: [{ Id: "", Number: phone, Type: phoneType }], 
            FirstName: firstName,
            MiddleName: middleName,
            LastOrCompanyName: lastName,
            DateOfBirth: dob,
            Prefix: prefix,
            Suffix: suffix,
            Discriminator: props.contactType,
            DemographicSummary: null
        }
    }

    const generateChildContactObjs = (): ApiContact[] | null => {
        if (!props.childrenRequired)
            return null

        let contacts = childContacts.map(child => {
            return {
                Id: "",
                Email: { Id: "", Address: "" },
                Address: generateAddressObj(),
                Phones: [], 
                FirstName: child.FirstName,
                MiddleName: child.MiddleName,
                LastOrCompanyName: child.LastName,
                DateOfBirth: child.DateOfBirth,
                Prefix: "",
                Suffix: "",
                Discriminator: props.contactType,
                DemographicSummary: null
            }
        })

        //console.log(contacts)
        return contacts;
    }

    const generateSecondaryContactObj= (): ApiContact | null  => {
        if (!props.spouseRequired)
            return null

        return {
            Id: "",
            Email: { Id: "", Address: spouseemail },
            Address: generateAddressObj(),
            Phones: [{ Id: "", Number: spousephone, Type: spousephoneType }], 
            FirstName: spousefirstName,
            MiddleName: spousemiddleName,
            LastOrCompanyName: spouselastName,
            DateOfBirth: spousedob,
            Prefix: spouseprefix,
            Suffix: spousesuffix,
            Discriminator: props.contactType,
            DemographicSummary: null
        }
    }

    const emailValid = function(){
        //Best email format regex as per https://stackoverflow.com/a/201378
        // eslint-disable-next-line no-control-regex
        //return email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
        // We're seeing some valid-looking emails get flagged by this one, switching to a less complex one.
        return email.match(/^\S+@\S+\.\S+$/);
    }

    const zipValid = function(){
        var mathches = addrZip.match(/[0-9]{5}/);
        return mathches != null;
    }

    const passwordValid = function(){
        var matches= password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/);
        return matches != null;
    }

    const ShowPasswordFlag = function(){
        if(password !== ""){
            return passwordValid();
        }
        return null;
    }

    const ShowPasswordConfirmFlag = function(){
        if(passwordConfirm !== ""){
            return (passwordValid() && password === passwordConfirm)
        }
        return null;
    }
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        set_isLoading(true)
        set_errors([])   

        let _validationErrors: ErrorDetail[] = []
        if(!emailValid()){
            _validationErrors.push({ ErrorMessage: "Please enter a valid email address." , ErrorCode: "email"})
        }else if (email !== emailConfirm){
            _validationErrors.push({ ErrorMessage: "Please re-type your email to confirm it.", ErrorCode: "emailConfirm"})
        }

        if(!passwordValid()){
            _validationErrors.push({ ErrorMessage: "Please enter a valid password.", ErrorCode: "password"  })
        } else if (password !== passwordConfirm) {
            _validationErrors.push({ ErrorMessage: "Please re-type your password to confirm it.", ErrorCode: "password" })
        }
        if (!termsAccepted) {
            _validationErrors.push({ ErrorMessage: "Please accept the terms and conditions before proceeding.", ErrorCode: "terms" })
        }

        if(!zipValid()){
            _validationErrors.push({ ErrorMessage: "Please enter a valid 5 digit zip code.", ErrorCode: "address" })
        }

        if (_validationErrors.length > 0) {
            console.log(errors)
            set_errors(_validationErrors)
            set_isLoading(false)
            let doc = document.getElementById('appHeader')
            if (doc)
                doc.scrollIntoView();
            return
        }

        // This is roughly copy-pasted between GuestCheckout.tsx, PurchaseMembership.tsx, and PayDues.tsx 
        if (paymentTypeIsCard) {
            const card = props.elements.getElement(CardElement)
            if (!card) {
                set_errors([{ ErrorMessage: StripeUtilities.genericErrorMsg }])
                let doc = document.getElementById('appHeader')
                if (doc)
                    doc.scrollIntoView();
            }
            else {
                const tokenResult = await props.stripe.createToken(card);
                if (tokenResult.error) {
                    set_errors([{ ErrorMessage: tokenResult.error!.message ?? StripeUtilities.genericErrorMsg}])
                    let doc = document.getElementById('appHeader')
                    if (doc)
                        doc.scrollIntoView();
                }
                else {
                    var paramObj: NewMemberSignupRequest = {
                        PrimaryContactToCreate: generatePrimaryContactObj(),
                        SecondaryContactToCreate: generateSecondaryContactObj(),
                        ChildContactsToCreate: generateChildContactObjs(),
                        StripePayment: GenerateCardPaymentDetail(paymentHelperObj, tokenResult.token.id, false),
                        ECheckPayment: null,
                        MemberClassId: props.memberClass,
                        CountyId: props.countyId, 
                        StatePacContributionSelected: false,
                        IsBusiness: props.mockAccountOverview.MembershipSummary.IsBusinessMembership,
                        IsPacEligible: !props.is501c3,
                        Password: password,
                        CommoditiesSelected: selectedProduceCommodityIds,
                        InterestGroupsSelected: selectedInterestCommodityIds,
                        ReferredBy: referredBy
                    }

                    // Here's the API call that creates the member.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_, _errors] = await MyFfbfClientApi.NewMemberSignup.ProcessNewMemberSignup(paramObj)
                    
                    if (_errors.length > 0) {
                        set_errors(_errors)
                        // Check for error occuring for which we do not want to allow user to continue.
                        // This would be like an error happening after the payment is proccessed but before we're complete.
                        if (ContainsErrorAfterVendorPayment(_errors)) {
                            set_hasCriticalError(true)
                        }
                        else {
                            let doc = document.getElementById('appHeader')
                            if (doc)
                                doc.scrollIntoView();
                        }
                    }
                    else {
                        const paramObj = { EmailUsername: email, Password: password }
                        const [_loginPayload, _loginErrors] = await MyFfbfClientApi.Auth.AttemptLogin(paramObj)
                        if (_loginErrors.length > 0) {
                            set_errors(_loginErrors)
                            set_hasCriticalError(true) // an error at this point would be weird so we don't want them re-trying the payment.
                        }
                        else {
                            // Put the auth guid into session storage so that all further calls from inside the app will include it.
                            // WARNING - this is copy-pasted between Login.tsx, PurchaseMembership.tsx, and Registration.tsx 
                            sessionStorage.setItem(Keys.authorizationGuidKeyName, _loginPayload!.AuthorizationGuid);
                            set_readyToRedirect(true)
                        }
                    }
                }
            }
        }
        else {
            if (accountNumber !== verifyAccountNumber) {
                set_errors([{ ErrorMessage: "Please re-type your account number to confirm it."}])
            }
            else {
                alert("error - not implemented yet")
            }
        }
        set_isLoading(false)
        window.scrollTo(0, 0) 
    }

    if (readyToRedirect) {
        return <Redirect to="/dashboard/isNewlyCreated" />
    }

    if (hasCriticalError) {
        return <>
            <ValidationSummary errors={errors} />
            <CriticalPaymentErrorMsg />
        </>
    }

    return <>
        {isLoading && <OverlaySpinner />}
        <div id="appHeader"></div>
        <ValidationSummary errors={errors} />
        <Form onSubmit={handleSubmit}>
            <h4 className="newMemberHeader">Your Personal Information</h4>

            <Form.Group as={Row} className='mb-3' controlId="formName">
                <Form.Label column md={4} sm>
                    Your Name
                </Form.Label>
                <Col md={7} sm>
                    { props.contactType === "Person" &&  
                        <Row>
                            <Col sm>
                                <Form.Control name="firstName" type="text" value={firstName} onChange={e => set_firstName(e.target.value)} placeholder="First Name" />
                            </Col>
                            <Col sm>
                                <Form.Control name="middleName" type="text" value={middleName} onChange={e => set_middleName(e.target.value)} placeholder="Middle Name" />
                            </Col>
                            <Col sm>
                                <Form.Control name="lastName" type="text" value={lastName} onChange={e => set_lastName(e.target.value)} placeholder="Last Name" />
                            </Col>
                        </Row> }

                    { props.contactType === "Company" &&  
                        <Row>
                            <Col md={4} sm>
                                <Form.Control type="text" name="lastName" value={lastName} onChange={e => set_lastName(e.target.value)} placeholder="Company Name" />
                            </Col>
                        </Row> }
                </Col>
            </Form.Group>

            { props.contactType === "Person" &&  
                <Form.Group as={Row} className='mb-3' controlId="formTitles">
                    <Form.Label column md={4} sm>
                    Date of Birth, Prefix/Suffix
                    </Form.Label>
                    <Col md={7} sm>
                        <Row>
                            <Col md={5} sm>
                                <Form.Control type="text" name="bday" value={dob} onChange={e => set_dob(e.target.value)} placeholder="YYYY-MM-DD" />
                            </Col>
                            <Col md={3} sm>
                                <Form.Control as="select" value={prefix} name="prefix"
                                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_prefix(ev.target.value)}>
                                    <option></option>
                                    { ContactPrefixes.map(p => <option key={p} value={p}>{p}</option>) }
                                </Form.Control>
                            </Col>
                            <Col md={4} sm>
                                <Form.Control as="select" value={suffix} name="suffix"
                                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_suffix(ev.target.value)}>
                                    <option></option>
                                    { ContactSuffixes.map(s => <option key={s} value={s}>{s}</option>) }
                                </Form.Control>
                            </Col>
                        </Row>
                    </Col>
                </Form.Group> }

            <Form.Group as={Row} className='mb-3' controlId="formEmail">
                <Form.Label  column md={4} sm>
                    Email Address 
                </Form.Label>
                <Col md={7} sm>
                    <Row>
                        <Col>
                            <Form.Control type="text" name="email" value={email} onChange={e => set_email(e.target.value)} placeholder="Email" />                           
                        </Col>
                    </Row>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId="formEmailConfirm">
                
            <Form.Label  column md={4} sm>
                    Confirm Email Address 
                </Form.Label>
                <Col md={7} sm>
                    <Row>
                        <Col>
                            <Form.Control type="text" name="emailConfirm" value={emailConfirm} onChange={e => set_emailConfirm(e.target.value)} placeholder="Confirm Email" />
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col><div style={{fontWeight: "bold", marginTop: "10px"}}>The Email provided above will be used as your Username.</div></Col>
                    </Row>
                </Col>
            </Form.Group>
            <Row><Col><ValidationSummary errors={errors} filterCode = "email" /></Col></Row>
            <Form.Group as={Row} className='mb-3' controlId="formPhone">
                <Form.Label column md={4} sm>
                    Phone
                </Form.Label>
                <Col md={7} sm>
                    <Row>
                        <Col>                            
                            <Form.Control type="text" name="phone" value={phone} onChange={e => set_phone(e.target.value)} placeholder="Phone #" />
                        </Col>
                        <Col>
                            <Form.Control as="select" value={phoneType}
                                onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_phoneType(ev.target.value)}>
                                <option value="cell">Cell</option>
                                <option value="home">Home</option>
                                <option value="work">Work</option>
                            </Form.Control>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>

            { props.contactType === "Person" &&  <>

                { props.spouseRequired && <>
                    <Form.Group as={Row} className='mb-3' controlId="formSpouseName">
                        <Form.Label column md={4} sm>
                            Your Spouse's Name
                        </Form.Label>
                        <Col md={7} sm>
                            <Row>
                                <Col>
                                    <Form.Control type="text" name="firstName" value={spousefirstName} onChange={e => set_spousefirstName(e.target.value)} placeholder="Spouse First Name" />
                                </Col>
                                <Col>
                                    <Form.Control type="text" name="middleName" value={spousemiddleName} onChange={e => set_spousemiddleName(e.target.value)} placeholder="Spouse Middle Name" />
                                </Col>
                                <Col>
                                    <Form.Control type="text" name="lastName" value={spouselastName} onChange={e => set_spouselastName(e.target.value)} placeholder="Spouse Last Name" />
                                </Col>
                            </Row> 
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='mb-3' controlId="formSpouseTitles">
                        <Form.Label column md={4} sm>
                            Spouse Date of Birth, Prefix/Suffix
                        </Form.Label>
                        <Col md={7} sm>
                            <Row>
                                <Col md={5} sm>
                                    <Form.Control type="text" name="birthDate" value={spousedob} onChange={e => set_spousedob(e.target.value)} placeholder="YYYY-MM-DD" />
                                </Col>
                                <Col md={3} sm>
                                    <Form.Control as="select" name="prefix" value={spouseprefix}
                                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_spouseprefix(ev.target.value)}>
                                        <option></option>
                                        { ContactPrefixes.map(p => <option key={p} value={p}>{p}</option>) }
                                    </Form.Control>
                                </Col>
                                <Col md={4} sm>
                                    <Form.Control as="select" name="suffix" value={spousesuffix}
                                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_spousesuffix(ev.target.value)}>
                                        <option></option>
                                        { ContactSuffixes.map(s => <option key={s} value={s}>{s}</option>) }
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-3' controlId="formSpouseEmail">
                        <Form.Label column md={4} sm> 
                            Spouse Email Address &amp; Phone
                        </Form.Label>
                        <Col md={7} sm>
                            <Row>
                                <Col>
                                    <Form.Control name="email" type="text" value={spouseemail} onChange={e => set_spouseemail(e.target.value)} placeholder="Email" />
                                    <ValidationSummary errors={errors.filter(er => er.ErrorCode === "password")} />                           
                                </Col>
                                <Col>
                                    <Form.Control name="phone" type="text" value={spousephone} onChange={e => set_spousephone(e.target.value)} placeholder="Phone #" />
                                    <ValidationSummary errors={errors.filter(er => er.ErrorCode === "passwordConfirm")} />                           
                                </Col>
                                <Col>
                                    <Form.Control as="select" value={spousephoneType}
                                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_spousephoneType(ev.target.value)}>
                                        <option value="Cell">Cell</option>
                                        <option value="Home">Home</option>
                                        <option value="Work">Work</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Col>
                    </Form.Group>
                </> }

                { props.childrenRequired && <>
                    <h6 className="newMemberHeader">Your Children's Information</h6>
                    <p>Children under the age of 21 are included in family membership.</p>
                    <Container>
                        <ValidationSummary errors={errors} filterCode = "kids" />
                        { childContacts.map((child, index) => (
                            <Row key={index}>
                                <Col sm>
                                    <Form.Control type="text" placeholder="First"
                                            value={child.FirstName} 
                                            onChange={event => handleChildFirstNameChange(index, event)}  /> 
                                </Col>
                                <Col sm>
                                    <Form.Control type="text" placeholder="Middle"
                                        value={child.MiddleName} 
                                        onChange={event => handleChildMiddleNameChange(index, event)}  /> 
                                </Col>
                                <Col sm>
                                    <Form.Control type="text" placeholder="Last"
                                        value={child.LastName} 
                                        onChange={event => handleChildLastNameChange(index, event)}  /> 
                                </Col>
                                <Col sm>
                                    <Form.Control type="text" placeholder="DOB YYYY-MM-DD"
                                        value={child.DateOfBirth} 
                                        onChange={event => handleChildDateOfBirthChange(index, event)}  /> 
                                </Col>
                                <Col sm>
                                    <Button variant="link" onClick={() => handleRemoveChild(index)}>(remove)</Button>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col sm><Button variant="link" onClick={() => handleAddChild()}>(add another child)</Button></Col>
                            <Col sm></Col>
                            <Col sm></Col>
                        </Row>
                    </Container>
                </> }

            </>}

            <h4 className="newMemberHeader">Address Information</h4>

            <Form.Group as={Row} className='mb-3'>
                <Form.Label column md={4} sm>
                    Street Address
                </Form.Label>
                <Col md={7} sm>
                    <Row style={{marginBottom: "10px"}}>
                        <Col md={6} sm>
                            <Form.Control type="text" name="streetAddress1" value={addrLine1} onChange={e => set_addrLine1(e.target.value)} placeholder="Line 1" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm>
                            <Form.Control type="text" name="streetAddress2" value={addrLine2} onChange={e => set_addrLine2(e.target.value)} placeholder="Line 2" />
                        </Col>
                    </Row>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3'>
                <Form.Label column md={4} sm>
                    City State ZIP (5-digit)
                </Form.Label>
                <Col md={7} sm>
                    <Row>
                        <Col md={4} sm>
                            <Form.Control type="text" name='city' value={addrCity} onChange={e => set_addrCity(e.target.value)} placeholder="City" />
                        </Col>
                        <Col md={3} sm>
                            <Form.Control as="select" name="state" value={addrState}
                                onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_addrState(ev.target.value)}>
                                <option value="">---</option>
                                { USStates.map(s => <option key={s.abbreviation}>{s.abbreviation}</option>) }
                            </Form.Control>
                        </Col>
                        <Col md={3} sm>
                            <Form.Control type="text" maxLength={5} name="zip" value={addrZip} onChange={e => set_addrZip(e.target.value)}  placeholder="ZIP" />
                        </Col>                         
                    </Row>
                </Col>
            </Form.Group>
            <Row><Col><ValidationSummary errors={errors} filterCode = "address" /></Col></Row>

            <h4 className="newMemberHeader">Have you been referred to us by someone? If so, who?</h4>

            <Form.Group as={Row} className='mb-3' controlId="formReferrall">
                <Form.Label column md={4} sm>
                    Referred by
                </Form.Label>
                <Col md={7} sm>
                    <Form.Control 
                        type="text" 
                        value={referredBy} 
                        onChange={e => set_referredBy(e.target.value)} />
                </Col>
            </Form.Group>

            { props.memberClass !== "4" && <>
                <h4 className="newMemberHeader">What commodities do you produce?</h4>
                <div className="commodityList">
                    { props.commodityProduceOptions.map(pc => <>
                        <div style={{minWidth: "20%"}}>
                            <label>
                                <input type="checkbox" value={pc.Id} onChange={handleProduceCommodityCheckEvent} />
                                {" "}
                                {pc.Description}
                            </label>
                        </div>
                    </>)}
                </div>

                <h4 className="newMemberHeader">Are you interested in following any of the special interests below?</h4>
                <div className="commodityList wide">
                    { props.commodityInterestOptions.map(ic => <>
                        <div style={{minWidth: "33%"}}>
                            <label>
                                <input type="checkbox" value={ic.Id} onChange={handleInterestCommodityCheckEvent} />
                                {" "}
                                {ic.Description}
                            </label>
                        </div>
                    </>)}
                </div>
            </> }
            

            <h4 className="newMemberHeader">Farm Bureau MyFFBF Account Details</h4>       

            <Form.Group as={Row} className='mb-3' controlId="formYourPassword">
                <Form.Label column md='3'>
                    Your New Password: 
                </Form.Label>
                <Col md='4' sm>
                    <Form.Control
                        type='password'
                        value={password}
                        onChange={e => set_password(e.target.value)}
                        style={{width:'90%', display:'inline-block'}}
                    />                    
                    {ShowPasswordFlag() && <> <FontAwesomeIcon icon={faCheck} color="green"></FontAwesomeIcon></>}
                    {ShowPasswordFlag() === false && <> <FontAwesomeIcon icon={faX} color="red"></FontAwesomeIcon></>}
                </Col>
                <Col md='5' sm>
                    Your password must be:
                    <ul><li>10 charcters long</li><li>1 lower case letter</li><li>1 upper case letter</li><li>1 number</li></ul>
                    Please do not re-use this password among any other 
                    sites/accounts that you use. 
                </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId="formConfirmYourPassword">
                <Form.Label column md='3' sm>
                    Please re-type Your New Password:
                </Form.Label>
                <Col md='4' sm>
                    <Form.Control
                        type='password'
                        value={passwordConfirm}
                        onChange={e => set_passwordConfirm(e.target.value)}
                        style={{width:'90%', display:'inline-block'}} 
                    />      
                    {ShowPasswordConfirmFlag() && <> <FontAwesomeIcon icon={faCheck} color="green"></FontAwesomeIcon></>}
                    {ShowPasswordConfirmFlag() === false && <> <FontAwesomeIcon icon={faX} color="red"></FontAwesomeIcon></>}
                </Col>
                <Col md='5' sm>
                    Please re-type your password from the line above here to make sure that you 
                    have entered it correctly. 
                </Col>
            </Form.Group>

            <Row><Col><ValidationSummary errors={errors} filterCode = "password" /></Col></Row>
            <h4 className="newMemberHeader">Payment Information</h4>


            <Form.Group as={Row}>
                <Form.Label column md={4} sm>Payment Type</Form.Label>
                <Col md={4} sm>
                    <Form.Control as="select" value={paymentTypeIsCard ? "card" : "echeck"}
                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_paymentTypeIsCard(ev.target.value === "card")}>
                        <option value="card">Credit Card</option>
                    </Form.Control>
                </Col>
            </Form.Group>

            { paymentTypeIsCard && 
                <>
                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Card Details</Form.Label>
                        <Col md={7} sm>
                            <div className="fakeFormFieldHolder">
                                <CardElement options={StripeUtilities.cardElementOptions} />
                            </div>
                            
                        </Col>
                    </Form.Group> 
                </>
            }

            { !paymentTypeIsCard && 
                <>
                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Account Type</Form.Label>
                        <Col md={4} sm>
                            <Form.Control as="select" value={accountIsChecking ? "checking" : "savings"}
                                onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_accountIsChecking(ev.target.value === "checking")}>
                                <option value="checking">Checking</option>
                                <option value="savings">Savings</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Routing Number</Form.Label>
                        <Col md={4} sm>
                            <Form.Control type="text" value={routingNumber} onChange={e => set_routingNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Account Number</Form.Label>
                        <Col md={4} sm>
                            <Form.Control type="text" value={accountNumber} onChange={e => set_accountNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Verify Account Number</Form.Label>
                        <Col md={4} sm>
                            <Form.Control type="text" value={verifyAccountNumber} onChange={e => set_verifyAccountNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Name on Account</Form.Label>
                        <Col md={4} sm>
                            <Form.Control type="text" value={nameOnAccount} onChange={e => set_nameOnAccount(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={4} sm>Bank Name</Form.Label>
                        <Col md={4} sm>
                            <Form.Control type="text" value={bankName} onChange={e => set_bankName(e.target.value)} />
                        </Col>
                    </Form.Group>
                </>
            }

            <Form.Group as={Row}>
                <Form.Label column md={4} sm>County Dues Amount</Form.Label>
                <Col md="6" sm>${paymentHelperObj.duesAmount.toFixed(2)}</Col>
            </Form.Group>

            { paymentHelperObj.allowPacContributions && !props.is501c3 &&
                <Form.Group as={Row}>
                    <Form.Label column md={4} sm>Optional Contributions</Form.Label>
                    <Col md="6" sm>
                        <Form.Check type="checkbox" label={paymentHelperObj.statePacLabelText} name="statePacDonation" id="statePacDonation"
                            checked={donateToStatePac}
                            onChange={() => set_donateToStatePac(donateToStatePac => !donateToStatePac)} />

                        {paymentHelperObj.memberCountyAllowsCountyPac &&
                            <Form.Check type="checkbox" label={paymentHelperObj.countyPacLabelText} name="countyPacDonation" id="countyPacDonation"
                                checked={donateToCountyPac}
                                onChange={() => set_donateToCountyPac(donateToCountyPac => !donateToCountyPac)} />}
                    </Col>
                </Form.Group>
            }

            <Form.Group as={Row} style={{ fontWeight: "bold" }}>
                <Col md={4} sm>Total Amount to charge</Col>
                <Col md="6" sm>{paymentHelperObj.getTotalCost().formatedAmount}</Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md={4} sm>Terms and Conditions</Form.Label>
                <Col md="8" sm>
                    <Form.Check type="checkbox" label="I agree with the Terms and Conditions below" name="termsAccept" id="termsAccept"
                                checked={termsAccepted}
                                onChange={() => set_termsAccepted(termsAccepted => !termsAccepted)} />
                                
                    <PaymentTermsAndConditions />

            
                </Col>
            </Form.Group>
            <Row><Col><ValidationSummary errors={errors} filterCode = "terms" /></Col></Row>
            <Form.Group as={Row}>
                <Col md={4} sm></Col>
                <Col md="6" sm>
                    { isLoading 
                        ? <Button disabled>Payment Processesing...</Button> 
                        : <Button color="primary" type="submit">Pay {paymentHelperObj.getTotalCost().formatedAmount} Now</Button>}
                </Col>
            </Form.Group>

        </Form>
    </>
}


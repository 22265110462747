import React, { useRef, useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import { useReactToPrint } from 'react-to-print'
import { ModalEvents } from '../../Utilities'
import { MetroCard } from '../../views/MetroCard'

const PrintCardModal: React.FC<{modalEvents: ModalEvents, memberNumber: string, name: string, memberClass: string, county: string}> = (props) => {
    const [printedAtLeastOnce, set_printedAtLeastOnce] = useState(false)

    const handlePrint = useReactToPrint({
        content: () => componentRef?.current ?? null
      });

    const handleFinished = async () => {
        props.modalEvents.ModalCompletedEvent()
    }
    const componentRef = useRef(null)
    
    if(!componentRef){
        return <></>
    }
    return <>
        <Modal show={true} animation={false} onHide={handleFinished}>
            <Modal.Header closeButton>
                <Modal.Title>Print Membership Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MetroCard ref={componentRef}
                    memberNumber={props.memberNumber} 
                    memberClass={props.memberClass} 
                    county={props.county} 
                    name={props.name} />
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button variant="primary" onClick={handlePrint}>Print</Button>

                    { printedAtLeastOnce &&
                        <>&nbsp;&nbsp;<Button variant="primary" onClick={handleFinished}>I'm finished</Button></>
                    }
                    
                </div>
            </Modal.Footer>
        </Modal>
    </>
}

export default PrintCardModal

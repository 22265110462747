import React, { useState } from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { ContactType, ApiEmailAddress, ApiContact, GetMemberContactRoleLabel, MemberContactRole, ContactDemographicSummary, ContactPrefixes, ContactSuffixes, IndustryChoices } from "../../models/ApiContact"
import { ValidationSummary } from '../../views/MessageBoxes'
import { ContainsAuthExpiredError, ErrorDetail } from "../../models/ErrorDetail"
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { Link, Redirect } from "react-router-dom"
import { ModalEvents } from "../../Utilities"
import AccountOverview from "../../models/AccountOverview"

interface EditContactModalProps {
    accountOverview: AccountOverview
    contactType: ContactType, 
    contactRoleChoices: MemberContactRole[] | null, // passing this as null means you are EDITING a contact in the modal 
    modalEvents: ModalEvents, // this signals modal saved/completed
    contact: ApiContact | null, // passing this as NULL means you are CREATING a new contact in the modal
}

const EditContactModal: React.FC<EditContactModalProps> = (props) => {
    const [isLoading, set_isLoading] = useState(false);
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    
    // Contact Role, this is set/selected when ADDING a contact.
    const [selectedContactRole, set_selectedContactRole] = useState<MemberContactRole | null>(props.contactRoleChoices ? props.contactRoleChoices[0] : null)

    const [firstName, set_firstName] = useState(props.contact?.FirstName ?? "")
    const [middleName, set_middleName] = useState(props.contact?.MiddleName ?? "")
    const [lastOrCompanyName, set_lastOrCompanyName] = useState(props.contact?.LastOrCompanyName ?? "")
    const [prefix, set_prefix] = useState(props.contact?.Prefix ?? "")
    const [suffix, set_suffix] = useState(props.contact?.Suffix ?? "")
    const [dateOfBirth, set_dateOfBirth] = useState(props.contact?.DateOfBirth ?? "")

    // Demographic data
    const [gender, set_gender] = useState(props.contact?.DemographicSummary?.GenderId ?? "")
    const [hispanic, set_hispanic] = useState(props.contact?.DemographicSummary?.HispanicId ?? "")
    const [ethnicity, set_ethnicity] = useState(props.contact?.DemographicSummary?.EthnicityId ?? "")
    const [industry, set_industry] = useState(props.contact?.DemographicSummary?.IndustryId ?? "")

    const [emailAddress, set_emailAddress] = useState(props.contact?.Email?.Address ?? "")
    const [phones, set_phones] = useState(props.contact?.Phones ?? [])

    const handlePhoneTypeChange = (index: number, event: any) => {
        const values = [...phones]
        values[index].Type = event.target.value
        set_phones(values);
    };

    const handlePhoneNumberChange = (index: number, event: any) => {
        const values = [...phones]
        values[index].Number = event.target.value
        set_phones(values);
    };

    const handleAddPhone = () => {
        const values = [...phones];
        values.push({ Number: "", Type: "Home", Id: ""});
        set_phones(values);
    };
    
    const handleRemovePhone = (index: number) => {
        const values = [...phones]
        values.splice(index, 1)
        set_phones(values)
    };

    const generateAddressObj = () => {
        // If we're editing an existing Contact, pass down the object
        // If we're creating a new Contact, copy the Primary Contact's address into a new address obj w/ no ID.
        if (props.contact) {
            return props.contact.Address
        }
        else {
            const primaryAddr = props.accountOverview.MembershipSummary.MemberContactMappings
                                     .filter(mcm => mcm.RoleLabel === "Primary")[0].Contact.Address
            return {
                Id: "",
                Line1: primaryAddr.Line1,
                Line2: primaryAddr.Line2,
                City: primaryAddr.City,
                StateAbbreviation: primaryAddr.StateAbbreviation,
                Zip: primaryAddr.Zip, 
            }
        }
    }

    const generateEmailObj = () => {
        const email: ApiEmailAddress = {
            Id: props.contact?.Email?.Id ?? "",
            Address: emailAddress,
        }
        return email
    }

    const generateDemographicSummary = () => {
        const demographics: ContactDemographicSummary = {
            ContactId: props.contact?.Id ?? null,
            EthnicityId: ethnicity,
            GenderId: gender,
            HispanicId: hispanic,
            IndustryId: industry,

            // dont bother setting these, we don't read them during an update/insert anyway.
            GenderValue: "",
            EthnicityValue: "",
            HispanicValue: "",
            IndustryValue: "",
        }
        return demographics
    }



    const generateContactObjForSaving = () => {
        // build up new FlatContact
        const newContactVersion: ApiContact = {
            Id: props.contact?.Id ?? "",
            Email: generateEmailObj(),
            Address: generateAddressObj(),
            Phones: phones, 
            FirstName: firstName,
            MiddleName: middleName,
            LastOrCompanyName: lastOrCompanyName,
            DateOfBirth: dateOfBirth,
            Prefix: prefix,
            Suffix: suffix,
            Discriminator: props.contactType,
            DemographicSummary: generateDemographicSummary()
        }
        return newContactVersion;
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        set_isLoading(true)
        const contact = generateContactObjForSaving()
        const paramObj = { RoleLabel: selectedContactRole!, ContactToUpdate: contact }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, _errors] = await MyFfbfClientApi.MemberContacts.SaveMemberContact(paramObj)
        if (_errors.length > 0) {
			set_errors(_errors)
			set_isLoading(false)
		}
        else {
            props.modalEvents.ModalSharedDataUpdateEvent()
            props.modalEvents.ModalCompletedEvent()
        }
    }

    if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }

    return <>
        <Modal show={true} dialogClassName="editContactModal" onHide={props.modalEvents.ModalCompletedEvent} size="lg" animation={false}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{ props.contact ? "Edit" : "Add New" } Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ValidationSummary errors={errors} />
                    <Container>
                        {/* Only show the Relationship dropdown if we are ADDING a Contact */}
                        {!props.contact && 
                            <Form.Group as={Row} className="mb-4">
                                <Col md="1" sm>Relationship</Col>
                                <Col md="11" sm>
                                    <Container>
                                        <Row>
                                            <Col sm>
                                                <Form.Control as="select" value={selectedContactRole!} onChange={e => set_selectedContactRole(e.target.value as MemberContactRole)}>
                                                    {props.contactRoleChoices && props.contactRoleChoices.map(role => {
                                                        // eslint-disable-next-line no-lone-blocks
                                                        {/* Don't add a default value here or the cast to MemberContactRole will error */}
                                                        return <option key={role} value={role}>{GetMemberContactRoleLabel(props.contactType === "Company", role)}</option>
                                                    })}
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className="mb-4">
                            <Col md="1" sm>
                                <div>Name</div>
                            </Col>
                            <Col md="11" sm>
                                <Container>
                                    <Row>
                                        { props.contactType === "Person" ? 
                                            <>
                                                <Col className="mb-3" sm>
                                                    <Form.Label htmlFor="prefix">Prefix</Form.Label>
                                                    <Form.Control as="select"  id="prefix"
                                                        value={prefix} onChange={e => set_prefix(e.target.value)}>
                                                        <option value=""></option>
                                                        { ContactPrefixes.map(p => <option value={p}>{p}</option>) }
                                                    </Form.Control>
                                                </Col>
                                                <Col className="mb-3" sm>
                                                    <Form.Label htmlFor="firstName">First Name<span className="required">*</span></Form.Label>
                                                    <Form.Control id="firstName" type="text" placeholder="First" value={firstName} onChange={e => set_firstName(e.target.value)} />
                                                </Col>
                                                <Col className="mb-3" sm>
                                                    <Form.Label htmlFor="middleName">Middle Name</Form.Label>
                                                    <Form.Control id="middleName" type="text" placeholder="Middle" value={middleName} onChange={e => set_middleName(e.target.value)} />
                                                </Col>
                                                <Col className="mb-3" sm>
                                                    <Form.Label htmlFor="lastName">Last Name<span className="required">*</span></Form.Label>
                                                    <Form.Control id="lastName" type="text" placeholder="Last" value={lastOrCompanyName} onChange={e => set_lastOrCompanyName(e.target.value)} />
                                                </Col>
                                                <Col className="mb-3" sm>
                                                    <Form.Label htmlFor="suffix">Suffix</Form.Label>
                                                    <Form.Control as="select" id="suffix"
                                                        value={suffix} onChange={e => set_suffix(e.target.value)}>
                                                        <option value=""></option>
                                                        { ContactSuffixes.map(p => <option value={p}>{p}</option>) }
                                                    </Form.Control>
                                                </Col>
                                            </> : <>
                                                {/* Company Contact fields here */}
                                                <Col md="6"  sm>
                                                    <Form.Control type="text" 
                                                        placeholder="Name" 
                                                        value={lastOrCompanyName} 
                                                        onChange={e => set_lastOrCompanyName(e.target.value)} />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className="mb-4">
                            <Col md="1" sm><Form.Label htmlFor="email">Email</Form.Label></Col>
                            <Col md="11" sm>
                                <Container>
                                    <Row>
                                        <Col md="6" sm>
                                            <Form.Control type="text" 
                                                placeholder="Email Address" 
                                                value={emailAddress} 
                                                onChange={e => set_emailAddress(e.target.value)} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Col md="1" sm><Form.Label htmlFor="addr1">Phones</Form.Label></Col>
                            <Col md="11" sm>
                                <Container>
                                    {phones.map((phone, index) => (
                                        <Row key={index}>
                                            <Col md="auto" sm>
                                                <Form.Control as="select" size="sm"  className="mt-1"
                                                    value={phone.Type}
                                                    onChange={event => handlePhoneTypeChange(index, event)}>
                                                    <option value="Home">Home</option>
                                                    <option value="Work">Work</option>
                                                    <option value="Cell">Cell</option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm>
                                                <Form.Control type="text" placeholder="phone #"
                                                    value={phone.Number} 
                                                    onChange={event => handlePhoneNumberChange(index, event)}  /> 
                                            </Col>
                                            <Col sm>
                                                <Button variant="link" onClick={() => handleRemovePhone(index)}>(remove)</Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col sm><Button variant="link" onClick={() => handleAddPhone()}>(add another phone)</Button></Col>
                                        <Col sm></Col>
                                        <Col sm></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            <Col md="1" sm><Form.Label htmlFor="addr1">Industry</Form.Label></Col>
                            <Col md="11" sm>
                                <Container>
                                    <Row>
                                        <Col md="4" sm>
                                            <Form.Control as="select" value={industry} onChange={e => set_industry(e.target.value)}>
                                                <option value="">(choose one)</option>
                                                { IndustryChoices.map(i => <option value={i.id}>{i.name}</option>) }
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-4">
                            {/* Extra cols for person contact here */}
                            { props.contactType === "Person" ? 
                                <>
                                    <Col className="mb-3" sm>
                                        <label className="col-form-label col-form-label-sm">Date of Birth<span className="required">*</span></label>
                                        <Form.Control type="text" id="sfsd" placeholder="YYYY-MM-DD" value={dateOfBirth} onChange={e => set_dateOfBirth(e.target.value)} />
                                    </Col>
                                    <Col className="mb-3" sm>
                                        <label className="col-form-label col-form-label-sm">Gender</label>
                                        <Form.Control as="select" value={gender} onChange={e => set_gender(e.target.value)}>
                                            <option value="">(choose one)</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Prefer not to say</option>
                                        </Form.Control>
                                    </Col>
                                    <Col className="mb-3" sm>
                                        <label className="col-form-label col-form-label-sm">Hispanic/Latino/Spanish Origin</label>
                                        <Form.Control as="select" value={hispanic} onChange={e => set_hispanic(e.target.value)}>
                                            <option value="">(choose one)</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                            <option value="3">Prefer not to say</option>
                                        </Form.Control>
                                    </Col>
                                    <Col className="mb-3" sm>
                                        <label className="col-form-label col-form-label-sm">Ethnicity/Race</label>
                                        <Form.Control as="select" value={ethnicity} onChange={e => set_ethnicity(e.target.value)}>
                                            <option value="">(choose one)</option>
                                            <option value="1">American Indian or Alaska Native</option>
                                            <option value="2">Asian </option>
                                            <option value="3">Black or African American </option>
                                            <option value="4">Native Hawaiian or Other Pacific Islander </option>
                                            <option value="5">White </option>
                                            <option value="6">Other </option>
                                            <option value="7">Prefer not to say</option>
                                        </Form.Control>
                                    </Col>
                                </> : <></> }
                        </Form.Group>  
                        <label style={{fontSize:".8em"}}>
                        Please note, changing your contact email does NOT change your login to MyFFBF.org. <br/>
                        To change your login email contact Member Services at 352-374-1502, or send us a message <Link to='/contactUs'>here</Link>.<br/>
                        </label>   
                    </Container>
                </Modal.Body>
                <Modal.Footer>                   
                    { isLoading 
                        ? <Button disabled type="submit">Saving...Please wait...</Button>
                        : <Button type="submit">Save</Button> }
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default EditContactModal
// These are together because they are only served out of the API as a single object:
// /Account/GetAccountOverview/ -> AccountOverview
import { ApiContact, MemberApiContactMapping } from './ApiContact'

export default interface AccountOverview {
    // Details for the user/contact on the membership who registered this login as here
    UserAccount: MyFFBFUserAccount;

    // All the detals of the membership are here
    MembershipSummary: MembershipSummary;

    // This is not specific to the member, ie it will be identical for two different memberships from the same county.
    CountyOffice: FarmBureauOfficeInfo;
}

export interface MyFFBFUserAccount {
    Contact: ApiContact;
    EmailAddress: string;
    ShortName: string;
}

export interface MembershipSummary {
    MemberNumber: string;
    FamilyName: string;
    Balance: number;
    MemberStatus: string;
    MemberClassDescription: string;
    MemberClassId: string;
    IsBusinessMembership: boolean;
    NextRenewalDate: string;
    JoinDate: string;
    DeathBenefit: number;
    AllowPacContributions: boolean;
    IsDelinquent: boolean;
    IsTerminated: boolean;
    CountyId: string;
    //  This should be NULL if they never signed up for EZPay, or if they ended a previous signup.
    ActiveRecurringDuesSignup: ActiveRecurringDuesSignup | null;
    DuesPlanIsEZPay: boolean; // this is 'ActiveRecurringDuesSignup != null' in the API
    DuesPlan: string; // this is ' DuesPlanIsEZPay ? "Membership EZ-Pay" : "Manual Pay"' in the API
    MemberContactMappings: MemberApiContactMapping[] 
}

export interface ActiveRecurringDuesSignup {
    CustomerId: string;
    IncludesStatePacContribution: boolean;
    IncludesCountyPacContribution: boolean;
    StartDate: Date;
    DefaultCardLast4: string;
}


export interface FarmBureauOfficeInfo {
    Id: string;
    Name: string;
    Phone: string;
    Fax: string;
    Address: string;
    City: string;
    Zip: string;
    Secretary: string;
    CountyPacContributionAmount: number | null; // null if this County doesn't have a PAC
    CountyPacId: string | null; // null if this County doesn't have a PAC
    StatePacContributionAmount: number;
    StatePacId: string;
    DuesStructure: DuesStructureEntry[]
}

export interface DuesStructureEntry {
    MemberClassId: string;
    DuesAmount: number;
}

export function HideEzPaySignupPathways(acctOverview: AccountOverview | null) {
    if (!acctOverview)
        return true;

    return acctOverview.MembershipSummary.Balance > 0 && !acctOverview.MembershipSummary.DuesPlanIsEZPay
}

export function GetNextDuesAmount(acctOverview: AccountOverview | null) {
    if (!acctOverview)
        return 0

    const memberClassId = acctOverview.MembershipSummary.MemberClassId
    const duesStruct = acctOverview.CountyOffice.DuesStructure.filter(d => d.MemberClassId === memberClassId)[0]
    return duesStruct ? duesStruct.DuesAmount : 0
}

// Rather than handling NULL, it can be easier to use an 'empty' AccountOverview in some situations
// when handling state in React. Use this 'empty' version in such cases.
export function GenerateEmptyAcctOverview(): AccountOverview {
    const mockContact: ApiContact = {
        Id: "",
        Address: {
            Id: "",
            Line1: "",
            Line2: "",
            City: "",
            StateAbbreviation: "",
            Zip: "",
        },
        Phones: [{
            Id: "",
            Number: "",
            Type: "",
        }],
        // This allows us to distinguish the types
        Discriminator: "Person",
        // This will be LAST or COMPANY name from the IContact
        LastOrCompanyName: "",
        DemographicSummary: null // will be NULL if not yet saved for this contact
    }
    const mockAccountOveview: AccountOverview = {
        UserAccount: {
            Contact: mockContact,
            EmailAddress: "",
            ShortName: "",
        },
        MembershipSummary: {
            MemberNumber: "",
            FamilyName: "",
            Balance: 0,
            MemberStatus: "",
            MemberClassDescription: "",
            MemberClassId: "4",
            IsBusinessMembership: false,
            NextRenewalDate: "1900-01-01",
            JoinDate: "",
            DeathBenefit: 0,
            AllowPacContributions: true,
            IsDelinquent: false,
            IsTerminated: false,
            CountyId: "",
            ActiveRecurringDuesSignup: null,
            DuesPlanIsEZPay: false, // this is 'ActiveRecurringDuesSignup != null' in the API
            DuesPlan: "", // this is ' DuesPlanIsEZPay ? "Membership EZ-Pay" : "Manual Pay"' in the API
            MemberContactMappings: [{
                RoleLabel: "Primary",
                RoleId: 1,
                Contact: mockContact,
                ContactIdentifier: "",
            }] 
        },
        CountyOffice: {
            Id: "",
            Name: "",
            Phone: "",
            Fax: "",
            Address: "",
            City: "",
            Zip: "",
            Secretary: "",
            CountyPacContributionAmount: null, 
            CountyPacId: null,
            StatePacContributionAmount: 2,
            StatePacId: "1",
            DuesStructure: [{
                MemberClassId: "4",
                DuesAmount: 0,
            }]
        },
    }

    return mockAccountOveview
}
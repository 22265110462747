import { toMoney } from "../Utilities";
import AccountOverview from "./AccountOverview";

// This really is more of a Module instead of a Model now....

export interface PaymentDetail
{
    DuesAmount: number
    CountyPacContribution: number
    CountyPacId: string | null
    StatePacContribution: number
    StatePacId: string
}

// Guest Payments (New Member Signup, Guest Checkout) need to pass the Member# as a param to the API calls, unlike logged in payments.
export interface GuestPaymentDetail extends PaymentDetail 
{
    MemberNumber: string
}

function GeneratePaymentBaseObj(paymentHelperObj: PaymentHelperObj) : PaymentDetail {
    return {
        DuesAmount: paymentHelperObj.duesAmount,
        CountyPacContribution: paymentHelperObj.getSelectedCountyPacContributionAmount(),
        CountyPacId: paymentHelperObj.countyPacId,
        StatePacContribution: paymentHelperObj.getSelectedStatePacContributionAmount(),
        StatePacId: paymentHelperObj.statePacId
    }
}

export interface StripeCardPaymentDetail extends PaymentDetail
{
    StripeToken: string
    EnrollInNewRecurringDuesSignup: boolean
}

export interface GuestStripeCardPaymentDetail extends GuestPaymentDetail
{
    StripeToken: string
}

export function GenerateCardPaymentDetail(paymentHelperObj: PaymentHelperObj, stripeToken: string, enrollInEzPay: boolean): StripeCardPaymentDetail {
    return {
        StripeToken: stripeToken,
        EnrollInNewRecurringDuesSignup: enrollInEzPay,
        ...GeneratePaymentBaseObj(paymentHelperObj)
    }
}

export function GenerateGuestCardPaymentDetail(paymentHelperObj: PaymentHelperObj, stripeToken: string, memberNumber: string): GuestStripeCardPaymentDetail {
    return {
        StripeToken: stripeToken,
        MemberNumber: memberNumber,
        ...GeneratePaymentBaseObj(paymentHelperObj)
    }
}

export interface EcheckPaymentDetail extends PaymentDetail
{
    AccountNumber: string
    RoutingNumber: string
    NameOnAccount : string
    BankName : string
    AccountIsChecking : boolean
}

export interface GuestEcheckPaymentDetail extends GuestPaymentDetail, EcheckPaymentDetail  {}

export function GenerateECheckPaymentDetail(paymentHelperObj: PaymentHelperObj, accountNumber: string, routingNumber: string, nameOnAccount: string, bankName: string, accountIsChecking: boolean) : EcheckPaymentDetail {
    return {
        AccountNumber: accountNumber,
        RoutingNumber: routingNumber,
        NameOnAccount : nameOnAccount,
        BankName : bankName,
        AccountIsChecking : accountIsChecking,
        ...GeneratePaymentBaseObj(paymentHelperObj)
    }
}

export function GenerateGuestECheckPaymentDetail(paymentHelperObj: PaymentHelperObj, memberNumber: string, accountNumber: string, routingNumber: string, nameOnAccount: string, bankName: string, accountIsChecking: boolean) : GuestEcheckPaymentDetail {
    return {
        AccountNumber: accountNumber,
        RoutingNumber: routingNumber,
        NameOnAccount : nameOnAccount,
        BankName : bankName,
        AccountIsChecking : accountIsChecking,
        MemberNumber: memberNumber,
        ...GeneratePaymentBaseObj(paymentHelperObj)
    }
}

export interface PaymentConfirmation {
    ConfirmationCode: string
}

export interface ProcessPaymentResult {
    ConfirmationCode: string
}

export interface PublicKey {
    Key: string
}

export interface RecurringDuesSignupDetail {
    StripeToken: string 
    IncludeStatePac: boolean
    IncludeCountyPac: boolean
}

// An object with properties to help handle common/shared UI behavior/state in the Payment forms
export interface PaymentHelperObj {
    getTotalCost: () => { amount: number, formatedAmount: string },
    getSelectedStatePacContributionAmount: () => number,
    getSelectedCountyPacContributionAmount: () => number,
    duesAmount: number,
    allowPacContributions: boolean,
    memberCountyAllowsCountyPac: boolean,
    standardCountyPacAmount: number,
    standardStatePacAmount: number,
    countyPacLabelText: string,
    statePacLabelText: string,
    countyPacId: string | null,
    statePacId: string,
}

export function GeneratePaymentHelperObj(accountOverview: AccountOverview, userWillDonateToStatePac: boolean, userWillDonateToCountyPac: boolean): PaymentHelperObj {
    const memberCountyAllowsCountyPac = accountOverview.CountyOffice.CountyPacContributionAmount !== null
    const standardCountyPacAmount = memberCountyAllowsCountyPac ? accountOverview.CountyOffice.CountyPacContributionAmount as number : 0
    const standardStatePacAmount = accountOverview.CountyOffice.StatePacContributionAmount
    const duesAmount = accountOverview.MembershipSummary.Balance

    const getSelectedStatePacContributionAmount = () => (userWillDonateToStatePac ? standardStatePacAmount : 0)
    const getSelectedCountyPacContributionAmount = () => (userWillDonateToCountyPac ? standardCountyPacAmount : 0)

    const getTotalCost = () => {
        const amount = duesAmount
            + getSelectedStatePacContributionAmount()
            + getSelectedCountyPacContributionAmount()

        const formatedAmount = toMoney(amount)
        return { amount, formatedAmount }
    }

    return {
        getTotalCost,
        getSelectedStatePacContributionAmount,
        getSelectedCountyPacContributionAmount,
        duesAmount,
        allowPacContributions: accountOverview.MembershipSummary.AllowPacContributions,
        memberCountyAllowsCountyPac,
        standardCountyPacAmount,
        standardStatePacAmount,
        countyPacLabelText: !memberCountyAllowsCountyPac ? "" : `$${standardCountyPacAmount}.00 donation to FarmPAC for ${accountOverview.CountyOffice.Name} County Farm Bureau`,
        statePacLabelText: `$${standardStatePacAmount}.00 donation to FarmPAC for Florida Farm Bureau Federation`,
        countyPacId: accountOverview.CountyOffice.CountyPacId,
        statePacId: accountOverview.CountyOffice.StatePacId,
    }
}
import React from 'react'

type metroCardProps =  {memberNumber: string, name: string, memberClass: string, county: string}

export const MetroCard = React.forwardRef<HTMLDivElement, metroCardProps>((props, ref) => { 
    return <>
        <div className="metroCard" ref={ref} style={{position:"relative", color:"#0B398E", fontSize:"15px"}} >
            <img src="https://apps.floridafarmbureau.com/_cdn/img/metroCardFront.png" alt="" />
            <div style={{position:"absolute", left: "152px", top: "63px"}}><b>{props.memberNumber}</b></div>
            <div style={{position:"absolute", left: "23px", top: "90px"}}>Member Name: {props.name}</div>                                            
            <div style={props.memberClass.length >= 20?{fontSize: "12px", paddingTop: "3px", position:"absolute", left: "120px", top: "139px"}:
                {position:"absolute", left: "120px", top: "139px"}}>
                {props.memberClass}
            </div>
            <div style={{position:"absolute", left: "84px", top: "156px"}}>{props.county}</div>
        </div>
    </>
})
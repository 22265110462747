import React, { useEffect } from 'react'
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Route, BrowserRouter as Router, Switch, Redirect, useLocation } from 'react-router-dom'

import LoggedInLayout from '../src/layout/LoggedInLayout'
// import JoinPayLayout from '../src/layout/JoinPayLayout'
// import LoginLayout from '../src/layout/LoginLayout'
// import RegistrationLayout from '../src/layout/RegistrationLayout'

import Login from '../src/pages/login/Login'
import PurchaseMembership from '../src/pages/login/PurchaseMembership'
import Registration from '../src/pages/login/Registration'
import GuestCheckout from '../src/pages/login/GuestCheckout'

import Logout from './pages/account/Logout'
import MyAccount from './pages/account/MyAccount'

import Dashboard from '../src/pages/dashboard/Dashboard'

import MyMembership_Details from '../src/pages/myMembership/Details'
import MyMembership_PayDues from '../src/pages/myMembership/PayDues'
import MyMembership_EZPayEdit from './pages/myMembership/EZPayEdit'
import MyMembership_EZPaySummary from '../src/pages/myMembership/EZPaySummary'

import MemberBenefits_Index from './pages/benefits/Index'
import MemberBenefits_Disabled from './pages/benefits/Disabled'

import ContactUs from './pages/contactUs/ContactUs' 


const App = () => {

  useEffect(()=>{
    document.title = "MyFFBF"
  }, [])

  return (
    <>
      <Router basename={'myffbfportal'}>
        <Switch>

          {/*  Nothing SHOULD point straight to root, but if it does get hit, redirect to Dashboard. */}
          <Route path="/" exact>
            <Redirect to="/dashboard" />
          </Route>

          <Route path="/login" exact component={Login} />
          <Route path="/registration" exact component={Registration} />
          <Route path="/purchaseMembership" exact component={PurchaseMembership} />
          <Route path="/guestCheckout" exact component={GuestCheckout} />

          <Route>
            <LoggedInLayout>
              <Switch>
                <Route path="/dashboard/:isNewlyCreated?" component={Dashboard} />

                <Route path="/myAccount" exact component={MyAccount} />
                <Route path="/logout" exact component={Logout} />

                <Route path="/myMembership/details" exact component={MyMembership_Details} />
                <Route path="/myMembership/payDues" exact component={MyMembership_PayDues} />
                <Route path="/myMembership/eZPayEdit" exact component={MyMembership_EZPayEdit} />
                <Route path="/myMembership/eZPaySummary" exact component={MyMembership_EZPaySummary} />
              
                <Route path="/memberBenefits/disabled" exact component={MemberBenefits_Disabled} />  
                <Route path="/memberBenefits/:permalinkBenefit?" component={MemberBenefits_Index} />


                <Route path="/contactUs" exact component={ContactUs} />
                <Route>
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </LoggedInLayout>
          </Route>

          {/*  Anything else unmatched via the paths above will hit here */}
          
          <Route path="/login" exact component={Login} />

        </Switch>
      </Router>
    </>
  );
}

export default App

function NotFound404() {
  let location = useLocation();
  return (
    <>
      No match for <code>{location.pathname}</code>
    </>
  );
}
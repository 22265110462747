import { useEffect, useState } from "react"
import MyFfbfClientApi from './api/MyFfbfClientApi'

// This pauses the setter of the watches value with a setTimeout of 400ms, perfect for slowling down expensive keyup functions, 
// for example, til the user has paused typing
export function useDebouncedValue(value: string, wait: number) {
    const [debouncedValue, set_debouncedValue] = useState(value)

    useEffect(()=> {
        const id = setTimeout(() => set_debouncedValue(value), wait ?? 400)
        return () => clearTimeout(id)
    }, [value, wait])

    return debouncedValue
}

export const Keys = {
    authorizationGuidKeyName: "usd876sjkjk28k", // intentionally confusing since the client can see this name in their sessionStorage values
    authExpiredErrorCode: "AUTH_INVALID_OR_EXPIRED", // kicked back by the API when the authorization guid is expired/invalid
}

export function toMoney(amount: number | null) {
    return amount ? `${(amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })}` : ''
}

export function toFormatedPhone(phoneNumber: string) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
}

export const StripeUtilities = { 
    genericErrorMsg: 'An error has occured, your card was not charged. Please try again later.',
    cardElementOptions: {
        iconStyle: "solid" as const,
        hidePostalCode: true,
        style: {
            base: {
                iconColor: "rgb(240, 57, 122)",
                color: "#0B398E",
                fontSize: "18px",
                fontFamily: '"Century Gothic", "Open Sans", sans-serif',
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#CFD7DF"
                }
            },
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#303238"
                }
            }
        }
    }
}

// IE: Parent - "Tell me when you update the data you control because I need to get a fresh copy from the server if you do that."
//              "Also, since I control your visibility, tell me when you are done w/ the user and are ready to be hidden."
//     Child - < onClick={()=> {ModalSharedDataUpdateEvent(); ModalCompletedEvent()}}>Save</> < onClick={ModalCompletedEvent}>Cancel</>
export type ModalEvents = {
    ModalCompletedEvent: () => void;
    ModalSharedDataUpdateEvent: () => void;
}

// For generating random strings
export function MakeRandomId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
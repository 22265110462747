import React from 'react'
import { Alert } from 'react-bootstrap'

const PaymentConfirmation: React.FC<{confirmationCode: string, totalCost: string, paymentTypeIsCard: boolean}> = (props) => {
    return <>
        <Alert variant="primary">
            <Alert.Heading>
                Your Membership Dues have now been paid
            </Alert.Heading>
            <br />

            { props.paymentTypeIsCard && 
                <p>
                    <b>{props.totalCost} has been charged to your Credit Card.</b> <br /><br />
                    This payment is accepted on {new Date().toLocaleDateString()}. Confirmation Code: {props.confirmationCode}
                </p>
            }

            { !props.paymentTypeIsCard && 
                <p>
                    <b>{props.totalCost} will be transfered from your Account</b>
                    in 1 to 3 business days.
                    This payment is provisionally accepted on {new Date().toLocaleDateString()}
                    and will be posted as of this date provided that you have entered the correct 
                    financial institution information and funds are available.
                </p>
            }

            <p><b>Thank you for your payment!</b></p>
        </Alert>
    </>
}

export default PaymentConfirmation
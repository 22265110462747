import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import AccountOverview from "../models/AccountOverview"
import { toMoney } from '../Utilities'

const DeathBenefitAmount: React.FC<{accountOverview: AccountOverview}> = (props) => {

    return (
        <div className="component_DeathBenefitAmount">
            <div>
                { (props.accountOverview.MembershipSummary.IsBusinessMembership) 
                    ? <>Inellgible</>
                    : <>{toMoney(props.accountOverview.MembershipSummary.DeathBenefit)}</> 
                }
            </div>
            <Link to="/memberBenefits/Financial"><Button variant="link">(more info)</Button></Link>
        </div>
    )
}

export default DeathBenefitAmount
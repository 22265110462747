import React, {useRef} from "react"
import {useState} from "react"
import {Button, Col, Container, Form, Row} from "react-bootstrap"
import {Link, Redirect} from "react-router-dom"
import {ErrorDetail} from "../../models/ErrorDetail"
import {Keys} from "../../Utilities"
import {ValidationSummary} from "../../views/MessageBoxes"
import OverlaySpinner from "../../views/OverlaySpinner"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import HomesteadLogoLink from "../../layout/HomesteadLogoLink"
import {useEffect} from "react"

const Login: React.FC = () => {
	const [email, set_email] = useState("")
	const [password, set_password] = useState("") // DONT CHECK THIS IN!!

	const [readyToRedirect, set_readyToRedirect] = useState(false)
	const [isLoading, set_isLoading] = useState(false)
	const [errors, set_errors] = useState<ErrorDetail[]>([])

	const emailInputRef = useRef<HTMLInputElement>(null)
	// Detect if 'existingMember' is passed from URL, hide New Member Signup if so.
	//const { enableJoinNow } = useParams<{enableJoinNow: string}>()
	//const allowJoinNow = enableJoinNow !== "false"
	const [allowJoinNow, set_allowJoinNow] = useState(true)
			
	useEffect(() => {
		// Focus on Email input at pageload
		emailInputRef?.current?.focus()

		// Check querystring for param to disable "Join Now" links
		const str = window.location.search.split("?")
		if(str.length > 1) {
			const parms = new Map();
			const kvPairs = str[1].split("&");
			kvPairs.forEach(function(kv){
				const pair = kv.split("=");
				parms.set(pair[0].toString(), pair[1]);
			});

			if (parms.get("allowNewMemSignup") === "false")
				set_allowJoinNow(false);
		}

	}, [])

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_isLoading(true)
		const paramObj = {EmailUsername: email, Password: password}
		const [_loginPayload, _loginErrors] =
			await MyFfbfClientApi.Auth.AttemptLogin(paramObj)
		if (_loginErrors.length > 0) {
			set_errors(_loginErrors)
			set_isLoading(false)
		} else {
			// Put the auth guid into session storage so that all further calls from inside the app will include it.
			// WARNING - this is copy-pasted between Login.tsx, PurchaseMembership.tsx, and Registration.tsx
			sessionStorage.setItem(
				Keys.authorizationGuidKeyName,
				_loginPayload!.AuthorizationGuid
			)
			set_readyToRedirect(true)
		}
	}
	if (readyToRedirect) {
		const str = window.location.search.split("?");
		const parms = new Map();
		
		if(str.length > 1) {
			const kvPairs = str[1].split("&");
			kvPairs.forEach(function(kv){
				const pair = kv.split("=");
				parms.set(pair[0].toString(), pair[1]);
			});
		}
		if(parms.get("p"))
			return <Redirect to={parms.get("p")} />
		return <Redirect to='/dashboard' />
	}

	return (
		<div className="component_Login">
            <div className="splitPageLayout loginLayout">
                <header>
                    <Container>
                        <HomesteadLogoLink linkTarget="login" />
                    </Container>
                </header>                
                <main role='main'>
                    <div className='leftSide'>
						{isLoading && <OverlaySpinner />}
						<ValidationSummary errors={errors} />

						<h3>Member Sign In</h3>

						<Form onSubmit={handleSubmit}>
							<Form.Group className='mb-3'>
								<Form.Label>Email</Form.Label>
								<Form.Control
									ref={emailInputRef}
									type='email'
									placeholder='email@example.com'
									value={email}
									onChange={e => set_email(e.target.value)}
								/>
							</Form.Group>

							<Form.Group className='mb-3'>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type='password'
									placeholder='Password'
									value={password}
									onChange={e => set_password(e.target.value)}
								/>
							</Form.Group>

							<div style={{textAlign: "center"}} className="d-none d-lg-block">
								<Button
									variant='warning'
									size='lg'
									as='input'
									type='submit'
									value='Sign In'
								/>
							</div>
							<div style={{textAlign: "center"}}  className="d-lg-none d-xl-none ">
								<Row>
									<Col>
										<Button
											variant='warning'
											as='input'
											size="lg"
											type='submit'
											value='Sign In'
											style={{
												width:"100%"}}
										/>
									</Col>
									<Col>
										<Link to='/registration'>
											<Button
												variant='warning'
												style={{width:"100%", height:"100%"}}>
													Pay Your Dues
											</Button>
										</Link>
									</Col>
								</Row><br/>
								<Row>
									<Col>
										{
											allowJoinNow && 
												<Link to='PurchaseMembership'>
													<Button
														variant='warning' size="lg"
														style={{width:"100%", height:"100%"}}>
															Not a Member? Join Today
													</Button>
												</Link>
										}
									</Col>
								</Row><br/>
								<Row>
									<Col>
										<div>
											<Link to='/registration'>
												<Button
													variant='warning' size="lg"
													style={{width:"100%", height:"100%"}}>
														Register your Membership Number
												</Button>
											</Link>
											<h5>Are you already a member?!</h5>
											<h5>Use your existing membership number to create a login to MyFFBF.org</h5>
										</div>
									</Col>
								</Row><br/>
								<Row>
									<Col>
										<Link to='/registration?v=p' style={{color: "#fff"}}>
											Forgot your password?
										</Link>
									</Col>
								</Row>
							</div>
						</Form>
						<div className='padded d-none d-lg-block'>
							<Link to='/registration?v=p' style={{color: "#fff"}}>
								Forgot your password<span style={{fontFamily: "sans-serif"}}>?</span>
							</Link>
						</div>
						<br />
						<br />
						<br />
						<div className='padded d-none d-lg-block'>
							<Button variant='warning' size='lg'>
								<Link to='/registration'>Register your Membership Number</Link>
							</Button>
							<h5>Are you already a member?</h5>
							<h5>Use your existing membership number to create a login to MyFFBF.org</h5>
						</div>
						<div className='padded d-none d-lg-block'>
							<Button variant='warning' size='lg'>
								<Link to='/registration'>Pay Membership Dues</Link>
							</Button>
						</div>                  
                    </div>

					<div className="rightSide">
						<div className='d-none d-lg-block'>
							<div>
							{  
								allowJoinNow && <>
									<h3>Not a Member?</h3>
									<div className="padded">
										<Button variant="light" size="lg"><Link to="PurchaseMembership">Join Today</Link></Button>
									</div>
								</>
							}
								
							</div>
						</div>
					</div>	
					
                </main>
            </div>
			<footer></footer>
        </div>		
		
	)
}

export default Login

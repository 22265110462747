import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import { ErrorDetail } from "../../models/ErrorDetail"
import { ValidationSummary } from '../../views/MessageBoxes'
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { ModalEvents } from '../../Utilities'

interface RequestCardModalProps {
    modalEvents:  ModalEvents // this signals modal saved/completed
    contactName: string
    memberNumber: string
}

const RequestCardModal: React.FC<RequestCardModalProps> = (props) => {
    const [loading, set_loading] = useState(false)
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [success, set_success] = useState(false)
    
    const doNewCardRequest = async () => {
        set_loading(true)
        set_errors([])
        var paramObj = {
            ContactName: props.contactName,
            MemberNumber: props.memberNumber
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, _errors] = await MyFfbfClientApi.MemberContacts.RequestReplacementCard(paramObj)
		if (_errors.length > 0) {
			set_errors(_errors)
		}
		else {
            set_success(true)
		}
        set_loading(false)
    }

    return <>
        <Modal show={true} animation={false} onHide={props.modalEvents.ModalCompletedEvent}>
            <Modal.Header closeButton>
                <Modal.Title>Request Replacement Card</Modal.Title>
            </Modal.Header>

            { !success && <>
                <Modal.Body>
                    <ValidationSummary errors={errors} />
                    <h5>Would you like to request a new Membership Card?</h5>
                    <p>We will mail you a new Membership Card within 7-10 business days.</p>
                </Modal.Body>
                <Modal.Footer>
                    { loading 
                        ? <div>
                            <Button variant="primary" disabled>Loading...</Button>
                            </div>
                        : <div>
                            <Button variant="primary" onClick={doNewCardRequest}>
                                Yes, please mail me a replacement card
                            </Button>
                            {" "}
                        </div>
                    }
                </Modal.Footer>
            </> }

            { success && <>
                <Modal.Body>
                    <h5>Your card has been request!</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.modalEvents.ModalCompletedEvent}>
                        Close
                    </Button>
                </Modal.Footer>
            </> }
            
        </Modal>
    </>
}

export default RequestCardModal
import { Keys } from "../Utilities";

export interface ErrorDetail {
    ErrorMessage: string,
    ErrorCode?: string,
}


export function ContainsAuthExpiredError(errors: ErrorDetail[]) {
    return errors.filter(e => e.ErrorCode === Keys.authExpiredErrorCode).length > 0
    // For now, treat ANY errors coming thru here as 'auth errors' so that we can route them to the login page.
    //return errors.length > 0
}

export function ContainsErrorAfterVendorPayment(errors: ErrorDetail[]) {
    return errors.filter(e => e.ErrorCode === "ERROR_AFTER_VENDOR_TRANSACTION" || e.ErrorMessage.indexOf("network Timeout") >= 0).length > 0
}
import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import { ContainsAuthExpiredError, ErrorDetail } from "../../models/ErrorDetail"
import { ValidationSummary } from '../../views/MessageBoxes'
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { Redirect } from 'react-router'
import { ModalEvents } from '../../Utilities'

interface RemoveContactModalProps {
    modalEvents: ModalEvents,  // this signals modal saved/completed
    contactId: string
}

const RemoveContactModal: React.FC<RemoveContactModalProps> = (props) => {
    const [loading, set_loading] = useState(false)
    const [errors, set_errors] = useState<ErrorDetail[]>([])

    const doRemoval = async () => {    
        set_loading(true)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, _errors] = await MyFfbfClientApi.MemberContacts.RemoveMemberContact({ ContactIdToRemove: props.contactId })
		if (_errors.length > 0) {
			set_errors(_errors)
            set_loading(false)
		}
		else {
            set_loading(false)
            props.modalEvents.ModalSharedDataUpdateEvent()
		}
    }

    if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }

    return <>
        <Modal show={true} animation={false} onHide={props.modalEvents.ModalCompletedEvent}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ValidationSummary errors={errors} />
                <h5>Are you sure you want to remove this Contact from the Membership?</h5>
            </Modal.Body>
            <Modal.Footer>
                { loading 
                    ? <div>
                        <Button variant="primary" disabled>Loading...</Button>
                        </div>
                    : <div>
                        <Button variant="primary" onClick={doRemoval}>
                            Yes, remove this Contact now
                        </Button>
                        {" "}
                      </div>
                }
            </Modal.Footer>
        </Modal>
    </>
}

export default RemoveContactModal
import React, {useState, useMemo, useEffect} from "react"
import {ContainsAuthExpiredError, ContainsErrorAfterVendorPayment, ErrorDetail} from "../../models/ErrorDetail"
import PageHeading from "../../views/PageHeading"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import {Redirect} from "react-router-dom"
import AccountOverview from "../../models/AccountOverview"
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap"
import {EZPayPromo, EZPaySignupConfirmation} from "../../views/EZPayAlerts"
import CriticalPaymentErrorMsg from '../../views/CriticalPaymentErrorMsg'
import {loadStripe, Stripe, StripeElements} from "@stripe/stripe-js"
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js"
import {CardElement} from "@stripe/react-stripe-js"
import {StripeUtilities} from "../../Utilities"
import OverlaySpinner from "../../views/OverlaySpinner"
import {ValidationSummary} from "../../views/MessageBoxes"
import {MyMembershipPageTabs} from "../../views/myMembership/MyMembershipPageTabs"
import {
	GeneratePaymentHelperObj,
	GenerateECheckPaymentDetail,
	GenerateCardPaymentDetail
} from "../../models/PaymentDetail"
import PaymentConfirmation from "../../views/PaymentConfirmation"
import PaymentTermsAndConditions from "../../views/PaymentTermsAndConditions"

const PayDues = () => {
	const {errors, payload} = MyFfbfClientApi.Account.UseAccountOverview()
	const [pageErrors, set_errors] = useState<ErrorDetail[]>([])
	const accountOverview = payload
	const [publicKey, set_publicKey] = useState("")

	useEffect(() => {
		let mounted = true
		const fetchData = async () => {
			var [_key, _error] = await MyFfbfClientApi.Admin.GetPublicKey()
			if (_error.length === 0) {
				set_publicKey(_key?.Key ?? "")
			} else {
				set_errors(_error)
			}
		}
		fetchData()

		return () => {
			mounted = false
		}
	}, [])

	console.log("ok is" + publicKey)
	const stripePromise = loadStripe(publicKey)

	if (ContainsAuthExpiredError(errors)) {
		return <Redirect to='/login' />
	}

	return (
		<div className='component_PayDues'>
			<PageHeading>
				My <b>Membership</b>
			</PageHeading>
			<Container className='pageContent'>
				<Row>
					<Col>
						{accountOverview && (
							<>
								<MyMembershipPageTabs
									activeTab='Pay Dues'
									accountOverview={accountOverview}
								/>
								{publicKey ? (
									accountOverview.MembershipSummary.Balance === 0 ? (
										<ZeroBalance accountOverview={accountOverview} />
									) : (
										<Elements stripe={stripePromise}>
											<ElementsConsumer>
												{({stripe, elements}) => (
													<>
														{stripe && elements && (
															<PayDuesForm
																accountOverview={accountOverview}
																stripe={stripe}
																elements={elements}
															/>
														)}
													</>
												)}
											</ElementsConsumer>
										</Elements>
									)
								) : (
									"."
								)}
							</>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default PayDues

export const ZeroBalance: React.FC<{
	accountOverview: AccountOverview
	displayEzPayPromoIfNotEnrolled?: boolean
}> = props => {
	return (
		<>
			<Alert variant='secondary'>
				<Alert.Heading>
					You don't have have Membership dues due at this time.
				</Alert.Heading>
				<div>
					Your next renewal date is{" "}
					{props.accountOverview.MembershipSummary.NextRenewalDate}.
				</div>
			</Alert>

			{props.displayEzPayPromoIfNotEnrolled &&
			!props.accountOverview.MembershipSummary.DuesPlanIsEZPay ? (
				<EZPayPromo />
			) : (
				<></>
			)}
		</>
	)
}

const PayDuesForm: React.FC<{
	accountOverview: AccountOverview
	stripe: Stripe
	elements: StripeElements
}> = props => {
	// Form states
	const [isLoading, set_isLoading] = useState(false)
	const [confirmationCode, set_confirmationCode] = useState("")
	const [errors, set_errors] = useState<ErrorDetail[]>([])
	const [hasCriticalError, set_hasCriticalError] = useState(false)

	// User inputs for card/echeck
	const [paymentTypeIsCard, set_paymentTypeIsCard] = useState(true)
	const [donateToCountyPac, set_donateToCountyPac] = useState(props.accountOverview.MembershipSummary.AllowPacContributions) // SPAC is checked by default, per biz rules
	const [donateToStatePac, set_donateToStatePac] = useState(props.accountOverview.MembershipSummary.AllowPacContributions) // CPAC is checked by default, per biz rules
	const paymentTypeIsEcheck = !paymentTypeIsCard
	const [termsAccepted, set_termsAccepted] = useState(false) 

	// User inputs for echeck
	const [accountNumber, set_accountNumber] = useState("")
	const [verifyAccountNumber, set_verifyAccountNumber] = useState("")
	const [routingNumber, set_routingNumber] = useState("")
	const [accountIsChecking, set_accountIsChecking] = useState(true)
	const [nameOnAccount, set_nameOnAccount] = useState("")
	const [bankName, set_bankName] = useState("")

	// User inputs for card (card # input UI handled by Stripe JS)
	const [enrollInEZPay, set_enrollInEZPay] = useState(false)

	// useMemo is a caching function, it only reconstructs the returned obj on a re-render if something in the
	// dependecy array has changed.
	const paymentHelperObj = useMemo(
		() =>
			GeneratePaymentHelperObj(
				props.accountOverview,
				donateToStatePac,
				donateToCountyPac
			),
		[props.accountOverview, donateToStatePac, donateToCountyPac]
	)

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_isLoading(true)
		set_errors([])

		let _validationErrors: ErrorDetail[] = []
		if (!termsAccepted) {
			_validationErrors.push({
				ErrorMessage:
					"Please accept the terms and conditions before proceeding."
			})
		}

		if (_validationErrors.length > 0) {
			set_errors(_validationErrors)
			set_isLoading(false)
			return
		}

		// This is roughly copy-pasted between GuestCheckout.tsx, PurchaseMembership.tsx, and PayDues.tsx
		if (paymentTypeIsCard) {
			const card = props.elements.getElement(CardElement)
			if (!card) set_errors([{ErrorMessage: StripeUtilities.genericErrorMsg}])
			else {
				const tokenResult = await props.stripe.createToken(card)
				if (tokenResult.error) {
					set_errors([
						{
							ErrorMessage:
								tokenResult.error!.message ?? StripeUtilities.genericErrorMsg
						}
					])
				} else {
					const cardPaymentDetail = GenerateCardPaymentDetail(
						paymentHelperObj,
						tokenResult.token.id,
						enrollInEZPay
					)
					const [_confirmation, _errors] = await MyFfbfClientApi.Payment.ProcessStripeCardPayment(cardPaymentDetail)
					if (_errors.length > 0) {
						set_errors(_errors)
						if (ContainsErrorAfterVendorPayment(_errors)) {
                            set_hasCriticalError(true)
                        }
					}
					else {
						set_confirmationCode(_confirmation!.ConfirmationCode)
					}
				}
			}
		} else {
			// Echeck payment
			if (accountNumber !== verifyAccountNumber) {
				set_errors([
					{ErrorMessage: "Please re-type your account number to confirm it."}
				])
			} else {
				const eCheckPaymentDetail = GenerateECheckPaymentDetail(
					paymentHelperObj,
					accountNumber,
					routingNumber,
					nameOnAccount,
					bankName,
					accountIsChecking
				)
				const [_confirmation, _errors] = await MyFfbfClientApi.Payment.ProcessECheckPayment(eCheckPaymentDetail)
				if (_errors.length > 0) {
					set_errors(_errors)
					if (ContainsErrorAfterVendorPayment(_errors)) {
						set_hasCriticalError(true)
					}
				}
				else {
					set_confirmationCode(_confirmation!.ConfirmationCode)
				}
			}
		}
		set_isLoading(false)
	}

	if (ContainsAuthExpiredError(errors)) {
		return <Redirect to='/login' />
	}

	if (confirmationCode.length) {
		const totalCost = paymentHelperObj.getTotalCost().formatedAmount
		return (
			<>
				<PaymentConfirmation
					confirmationCode={confirmationCode}
					totalCost={totalCost}
					paymentTypeIsCard={paymentTypeIsCard}
				/>
				{enrollInEZPay && <EZPaySignupConfirmation />}
			</>
		)
	}

	if (hasCriticalError) {
        return <>
            <ValidationSummary errors={errors} />
            <CriticalPaymentErrorMsg />
        </>
    }

	return (
		<>
			{isLoading && <OverlaySpinner />}
			<ValidationSummary errors={errors} />
			<Form onSubmit={handleSubmit}>
				<Form.Group as={Row}>
					<Form.Label column sm='3'>
						Payment Type
					</Form.Label>
					<Col sm='6'>
						<Form.Control
							as='select'
							value={paymentTypeIsCard ? "card" : "echeck"}
							onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void =>
								set_paymentTypeIsCard(ev.target.value === "card")
							}>
							<option value='card'>Credit Card</option>
							<option value='echeck'>Checking Account</option>
						</Form.Control>
					</Col>
				</Form.Group>

				{paymentTypeIsCard && (
					<>
						<Form.Group as={Row}>
							<Form.Label column sm='3'>
								Card Details
							</Form.Label>
							<Col lg='6'>
								<div className='fakeFormFieldHolder'>
									<CardElement options={StripeUtilities.cardElementOptions} />
								</div>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm='3'>
								Membership EZ-Pay
							</Form.Label>
							<Col lg='6'>
								<Form.Check
									type='checkbox'
									name='ezPaySignup'
									id='ezPaySignup'
									label='Sign me up for Membership EZ-Pay'
									checked={enrollInEZPay}
									onChange={() => set_enrollInEZPay(!enrollInEZPay)}
								/>
							</Col>
						</Form.Group>
					</>
				)}

				{paymentTypeIsEcheck && (
					<>
						<Form.Group as={Row}>
							<Form.Label column sm='3'>
								Account Type
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									as='select'
									value={accountIsChecking ? "checking" : "savings"}
									onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void =>
										set_accountIsChecking(ev.target.value === "checking")
									}>
									<option value='checking'>Checking</option>
									<option value='savings'>Savings</option>
								</Form.Control>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm='3'>
								Routing Number
							</Form.Label>
							<Col lg='6'>
								<Form.Control
									type='text'
									value={routingNumber}
									onChange={e => set_routingNumber(e.target.value)}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Account Number
							</Form.Label>
							<Col lg='6'>
								<Form.Control
									type='text'
									value={accountNumber}
									onChange={e => set_accountNumber(e.target.value)}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Verify Account Number
							</Form.Label>
							<Col lg='6'>
								<Form.Control
									type='text'
									value={verifyAccountNumber}
									onChange={e => set_verifyAccountNumber(e.target.value)}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Name on Account
							</Form.Label>
							<Col lg='6'>
								<Form.Control
									type='text'
									value={nameOnAccount}
									onChange={e => set_nameOnAccount(e.target.value)}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Bank Name
							</Form.Label>
							<Col lg='6'>
								<Form.Control
									type='text'
									value={bankName}
									onChange={e => set_bankName(e.target.value)}
								/>
							</Col>
						</Form.Group>
					</>
				)}

				<Form.Group as={Row}>
					<Form.Label column sm={3}>
						County Dues Amount
					</Form.Label>
					<Col lg='6'>${paymentHelperObj.duesAmount.toFixed(2)}</Col>
				</Form.Group>

				{paymentHelperObj.allowPacContributions && (
					<Form.Group as={Row}>
						<Form.Label column sm={3}>
							Optional Contributions
						</Form.Label>
						<Col sm='6'>
							<Form.Check
								type='checkbox'
								label={paymentHelperObj.statePacLabelText}
								name='statePacDonation'
								id='statePacDonation'
								checked={donateToStatePac}
								onChange={() => set_donateToStatePac(d => !d)}
							/>

							{paymentHelperObj.memberCountyAllowsCountyPac && (
								<Form.Check
									type='checkbox'
									label={paymentHelperObj.countyPacLabelText}
									name='countyPacDonation'
									id='countyPacDonation'
									checked={donateToCountyPac}
									onChange={() => set_donateToCountyPac(d => !d)}
								/>
							)}
						</Col>
					</Form.Group>
				)}

				<Form.Group as={Row}>
					<Form.Label column sm={3}>
						Terms &amp; Conditions
					</Form.Label>
					<Col sm='6'>
						<Form.Check
							type='checkbox'
							label='I accept the Terms and Conditions below'
							name='statePacDonation'
							id='termsAndConditions'
							checked={termsAccepted}
							onChange={() => set_termsAccepted(t => !t)}
						/>

						<div className='subtle'>
							<PaymentTermsAndConditions />
						</div>
					</Col>
				</Form.Group>

				<Form.Group as={Row} style={{fontWeight: "bold"}}>
					<Col sm='3'>Total Amount to charge</Col>
					<Col sm='6'>{paymentHelperObj.getTotalCost().formatedAmount}</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Col sm='3'></Col>
					<Col sm='6'>
						{isLoading ? (
							<Button disabled>Payment Processesing...</Button>
						) : (
							<Button color='primary' type='submit'>
								Pay {paymentHelperObj.getTotalCost().formatedAmount} Now
							</Button>
						)}
					</Col>
				</Form.Group>
			</Form>
		</>
	)
}

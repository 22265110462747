import React, { useEffect, useMemo, useState } from 'react'
import { Container, Row, Col, Form, Button, Navbar } from 'react-bootstrap'
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { StripeUtilities } from '../../Utilities'
import OverlaySpinner from '../../views/OverlaySpinner'
import HomesteadLogoLink from '../../layout/HomesteadLogoLink'
import { ValidationSummary } from '../../views/MessageBoxes'
import {  ContainsErrorAfterVendorPayment, ErrorDetail } from '../../models/ErrorDetail'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import AccountOverview, { GenerateEmptyAcctOverview } from '../../models/AccountOverview';
import { GeneratePaymentHelperObj, GenerateGuestECheckPaymentDetail, GenerateGuestCardPaymentDetail } from '../../models/PaymentDetail'
import sampleMetroCard_Front from '../../images/sampleMetroCard_Front.png'
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import { ZeroBalance } from '../myMembership/PayDues'
import PaymentConfirmation from '../../views/PaymentConfirmation';
import PaymentTermsAndConditions from '../../views/PaymentTermsAndConditions';
import CriticalPaymentErrorMsg from '../../views/CriticalPaymentErrorMsg'

const GuestCheckout = () => {
    const [isLoading, set_isLoading] = useState(false)
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [memberNumber, set_memberNumber] = useState("")
	const [zip, set_zip] = useState("")
	const [lastName, set_lastName] = useState("")
    const [isValidated, set_isValidated] = useState(false)
    const [accountOverview, set_accountOverview] = useState<AccountOverview>(GenerateEmptyAcctOverview())
    const [publicKey, set_publicKey] = useState("")

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            var [_key, _error] = await MyFfbfClientApi.Admin.GetPublicKey();
            if(_error.length === 0){
                set_publicKey(_key?.Key ?? "")
            } else{
                set_errors(_error)
            }
        }
        fetchData()

        return () => { mounted = false }
    }, [])

    if(!publicKey){
        return <></>
    }
    console.log("ok is" +publicKey) 
    const stripePromise = loadStripe(publicKey);

    const handleValidationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()
        set_isLoading(true)
        const paramObj = { EmailAddress: "", MemberId: memberNumber, LastNameOrCompanyName: lastName, ZipCode: zip }
        const [_acctOverview, _errors] = await MyFfbfClientApi.GuestCheckout.VerifyGuestCheckout(paramObj)
        if (_errors.length > 0) {
			set_errors(_errors)
		}
        else {
            set_isValidated(true)
            set_errors([])
            set_accountOverview(_acctOverview!)
        }
        set_isLoading(false)
    }

    return <>
        <div className="component_GuestCheckout">
            { isLoading && <OverlaySpinner /> }
			<header className='topNavBar blueBackground'>
                <Navbar
                    className='blueBackground'
                    variant='dark'
                    expand='lg'>
                    <Container>
                        <Navbar.Brand href='#home'>
                            <HomesteadLogoLink linkTarget="login" />
                        </Navbar.Brand>
                    </Container>
                </Navbar>
			</header>

            <div className="pageCaption">
                <Container>
                    <div>
                        <h3>Verify Membership and Pay as a Guest</h3>
                        <p>Enter the requested information to pay your annual membership dues.</p>
                    </div>
                </Container>
            </div>
            
            <Container className='pageContent'>
                <ValidationSummary errors={errors} />
                { !isValidated &&
                    <Row>
                        <Col sm={7}>
                            <Container style={{paddingLeft: "0"}}>
                                <Form onSubmit={handleValidationSubmit}>
                                    <Form.Group as={Row} className='mb-3' controlId="formMemberNumber">
                                        <Form.Label column sm='4'>
                                            Your Member Number
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                type='text'
                                                value={memberNumber}
                                                onChange={e => set_memberNumber(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className='mb-3' controlId="formLastName">
                                        <Form.Label column sm='4'>
                                            Your Last Name (If your membership is representing a business, enter your full business name)
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                type='text'
                                                value={lastName}
                                                onChange={e => set_lastName(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className='mb-3' controlId="formZipCode">
                                        <Form.Label column sm='4'>
                                            Your 5-digit Zip Code
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                type='text'
                                                value={zip}
                                                maxLength={5}
                                                onChange={e => set_zip(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className='mb-3'>
                                        <Col sm='4'>
                                        </Col>
                                        <Col sm='8'>
                                            <Button as='input' type='submit' value='Submit' />
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Container>
                        </Col>
                        <Col sm={5}>
                            Your Membership Number can be found on your Farm Bureau Membership Card, Policy Declaration, and/or Billing Statement. For business memberships, enter the full business name.
                            <div style={{width: "375px", margin: "15px auto"}}>
                                <img src={sampleMetroCard_Front} alt="" />
                            </div>
                        </Col>
                    </Row>
                }

                { isValidated && accountOverview && <>
                    { accountOverview.MembershipSummary.Balance === 0 
                        ? <ZeroBalance accountOverview={accountOverview} />
                        : <Elements stripe={stripePromise}>
                            <ElementsConsumer>
                                {({ stripe, elements }) => <>
                                    { stripe && elements && 
                                        <GuestCheckoutPayDuesForm accountOverview={accountOverview} stripe={stripe} elements={elements} />
                                    }
                                </>}
                            </ElementsConsumer>
                            </Elements> }
                </> }
            </Container>
            <footer></footer>
        </div>
    </>
}

export default GuestCheckout

const GuestCheckoutPayDuesForm: React.FC<{accountOverview: AccountOverview, stripe: Stripe; elements: StripeElements}> = (props) => {
    // Form states
    const [isLoading, set_isLoading] = useState(false)
    const [confirmationCode, set_confirmationCode] = useState("")
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [hasCriticalError, set_hasCriticalError] = useState(false)

    // User inputs for card/echeck
    const [paymentTypeIsCard, set_paymentTypeIsCard] = useState(true)
    const [donateToCountyPac, set_donateToCountyPac] = useState(props.accountOverview.MembershipSummary.AllowPacContributions) // SPAC is checked by default, per biz rules
    const [donateToStatePac, set_donateToStatePac] = useState(props.accountOverview.MembershipSummary.AllowPacContributions)   // CPAC is checked by default, per biz rules
    const paymentTypeIsEcheck = !paymentTypeIsCard
    const [termsAccepted, set_termsAccepted] = useState(false)

    // User inputs for echeck
    const [accountNumber, set_accountNumber] = useState("")
    const [verifyAccountNumber, set_verifyAccountNumber] = useState("")
    const [routingNumber, set_routingNumber] = useState("")
    const [accountIsChecking, set_accountIsChecking] = useState(true)
    const [nameOnAccount, set_nameOnAccount] = useState("")
    const [bankName, set_bankName] = useState("")

    // 'useMemo' is a caching function, it only reconstructs the returned obj on a re-render if something in the 
    // dependecy array has changed. Since users will keyup a lot of details
    // if paying by ECheck, this caching is beneficial since each keyup triggers a re-render.
    const paymentHelperObj = useMemo(() => 
        GeneratePaymentHelperObj(props.accountOverview, donateToStatePac, donateToCountyPac), 
        [props.accountOverview, donateToStatePac, donateToCountyPac])

    const handlePaymentSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        set_isLoading(true)
        const memNumber = props.accountOverview.MembershipSummary.MemberNumber

        let _validationErrors: ErrorDetail[] = []
        if (!termsAccepted) {
            _validationErrors.push({ ErrorMessage: "Please accept the terms and conditions before proceeding." })
        }

        if (_validationErrors.length > 0) {
            set_errors(_validationErrors)
            set_isLoading(false)
            return
        }

        // This is roughly copy-pasted between GuestCheckout.tsx, PurchaseMembership.tsx, and PayDues.tsx 
        if (paymentTypeIsCard) {
            const card = props.elements.getElement(CardElement)
            if (!card) {
                set_errors([{ ErrorMessage: StripeUtilities.genericErrorMsg }])
            }
            else {
                const tokenResult = await props.stripe.createToken(card);
                if (tokenResult.error) {
                    set_errors([{ ErrorMessage: tokenResult.error!.message ?? StripeUtilities.genericErrorMsg}])
                } 
                else {
                    const cardPaymentDetail = GenerateGuestCardPaymentDetail(paymentHelperObj, tokenResult.token.id, memNumber) 
                    const [_confirmation, _errors] = await MyFfbfClientApi.GuestCheckout.ProcessGuestStripeCardPayment(cardPaymentDetail)
                    if(_errors.length > 0) {
                        set_errors(_errors)
                        if (ContainsErrorAfterVendorPayment(_errors)) {
                            set_hasCriticalError(true)
                        }
                    }
                    else
                        set_confirmationCode(_confirmation!.ConfirmationCode)
                }
            }
        }
        else {  // Echeck payment
            if (accountNumber !== verifyAccountNumber) {
                set_errors([{ ErrorMessage: "Please re-type your account number to confirm it."}])
            }
            else {
                const eCheckPaymentDetail = GenerateGuestECheckPaymentDetail(paymentHelperObj, memNumber, accountNumber, routingNumber, nameOnAccount, bankName, accountIsChecking)
                const [_confirmation, _errors] = await MyFfbfClientApi.GuestCheckout.ProcessGuestECheckPayment(eCheckPaymentDetail)
                if(_errors.length > 0) {
                    set_errors(_errors)
                    if (ContainsErrorAfterVendorPayment(_errors)) {
                        set_hasCriticalError(true)
                    }
                }
                else
                    set_confirmationCode(_confirmation!.ConfirmationCode)
            }
        }
        set_isLoading(false)
    }

    if (confirmationCode) {
        const totalCost = paymentHelperObj.getTotalCost().formatedAmount
        return <>
            <PaymentConfirmation confirmationCode={confirmationCode} totalCost={totalCost} paymentTypeIsCard={paymentTypeIsCard} />
        </>
    }

    if (hasCriticalError) {
        return <>
            <ValidationSummary errors={errors} />
            <CriticalPaymentErrorMsg />
        </>
    }

    return <>
        { isLoading && <OverlaySpinner /> }
        <ValidationSummary errors={errors} />

        <Form onSubmit={handlePaymentSubmit}>

            <Form.Group as={Row}>
                <Form.Label column sm="3">Payment Type</Form.Label>
                <Col sm="6">
                    <Form.Control as="select" value={paymentTypeIsCard ? "card" : "echeck"}
                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_paymentTypeIsCard(ev.target.value === "card")}>
                        <option value="card">Credit Card</option>
                        <option value="echeck">Checking Account</option>
                    </Form.Control>
                </Col>
            </Form.Group>

            { paymentTypeIsCard && 
                <>
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Card Details</Form.Label>
                        <Col lg="6">
                            <div className="fakeFormFieldHolder">
                                <CardElement options={StripeUtilities.cardElementOptions} />
                            </div>
                        </Col>
                    </Form.Group> 
                </>
            }

            { paymentTypeIsEcheck && 
                <>
                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Account Type</Form.Label>
                        <Col sm="6">
                            <Form.Control as="select" value={accountIsChecking ? "checking" : "savings"}
                                onChange={(ev: React.ChangeEvent<HTMLSelectElement>): void => set_accountIsChecking(ev.target.value === "checking")}>
                                <option value="checking">Checking</option>
                                <option value="savings">Savings</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="3">Routing Number</Form.Label>
                        <Col lg="6">
                            <Form.Control type="text" value={routingNumber} onChange={e => set_routingNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Account Number</Form.Label>
                        <Col lg="6">
                            <Form.Control type="text" value={accountNumber} onChange={e => set_accountNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Verify Account Number</Form.Label>
                        <Col lg="6">
                            <Form.Control type="text" value={verifyAccountNumber} onChange={e => set_verifyAccountNumber(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Name on Account</Form.Label>
                        <Col lg="6">
                            <Form.Control type="text" value={nameOnAccount} onChange={e => set_nameOnAccount(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Bank Name</Form.Label>
                        <Col lg="6">
                            <Form.Control type="text" value={bankName} onChange={e => set_bankName(e.target.value)} />
                        </Col>
                    </Form.Group>
                </>
            }

            <Form.Group as={Row}>
                <Form.Label column sm={3}>County Dues Amount</Form.Label>
                <Col lg="6">${paymentHelperObj.duesAmount.toFixed(2)}</Col>
            </Form.Group>

            { paymentHelperObj.allowPacContributions &&
                <Form.Group as={Row}>
                    <Form.Label column sm={3}>Optional Contributions</Form.Label>
                    <Col sm="6">
                        <Form.Check type="checkbox" label={paymentHelperObj.statePacLabelText} name="statePacDonation" id="statePacDonation"
                            checked={donateToStatePac}
                            onChange={() => set_donateToStatePac(!donateToStatePac)} />

                        {paymentHelperObj.memberCountyAllowsCountyPac &&
                            <Form.Check type="checkbox" label={paymentHelperObj.countyPacLabelText} name="countyPacDonation" id="countyPacDonation"
                                checked={donateToCountyPac}
                                onChange={() => set_donateToCountyPac(!donateToCountyPac)} />}
                    </Col>
                </Form.Group>
            }

            <Form.Group as={Row} style={{ fontWeight: "bold" }}>
                <Col sm="3">Total Amount to charge</Col>
                <Col sm="6">{paymentHelperObj.getTotalCost().formatedAmount}</Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column sm={4}>Terms and Conditions</Form.Label>
                <Col sm="8">
                    <Form.Check type="checkbox" label="I agree with the Terms and Conditions below" name="termsAccept" id="termsAccept"
                                checked={termsAccepted}
                                onChange={() => set_termsAccepted(termsAccepted => !termsAccepted)} />
                    <PaymentTermsAndConditions />
                </Col>
            </Form.Group>            

            <Form.Group as={Row}>
                <Col sm="3"></Col>
                <Col sm="6">
                    { isLoading 
                        ? <Button disabled>Payment Processesing...</Button> 
                        : <Button color="primary" type="submit">Pay {paymentHelperObj.getTotalCost().formatedAmount} Now</Button>}
                </Col>
            </Form.Group>
        </Form>
    </>
}
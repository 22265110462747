import React from "react"
import {Container} from "react-bootstrap"
import {Redirect} from "react-router-dom"
import MyFfbfClientApi from "../api/MyFfbfClientApi"
import {ContainsAuthExpiredError} from "../models/ErrorDetail"

const PageHeading: React.FC = props => {
	const {errors, payload} = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload

	// TODO - Dont re-fetch account every time, set it in localStorage at login
	// All this page needs is member # and county name so those can be set locally.

	if (ContainsAuthExpiredError(errors)) {
		
        return <Redirect to={"/login?p="+window.location.pathname.replace("/myffbfportal", "")}/>
    }

	return (
		<div className='component_PageHeading'>
			<Container className="p-0">
				<h1 className="m-0">
					<span>
						{/* Example:  My <b>Membership</b> */}
						{props.children}
					</span>
					<div>
						<div>
							#
							{accountOverview &&
								accountOverview.MembershipSummary.MemberNumber}
							&nbsp;&nbsp;
							{accountOverview && accountOverview.CountyOffice.Name} County
						</div>
					</div>
				</h1>
			</Container>
		</div>
	)
}

export default PageHeading

import React from "react"
import { Alert, Container, Dropdown, DropdownButton } from "react-bootstrap"
import { Link, Redirect } from "react-router-dom"
import gearIcon from '../images/gearIcon.svg'
import { ContainsAuthExpiredError } from "../models/ErrorDetail"
import MyFfbfClientApi from '../api/MyFfbfClientApi'
import { NotInGoodStanding } from "../views/MessageBoxes"
import HomesteadLogoLink from './HomesteadLogoLink'
import { Nav, Navbar } from "react-bootstrap";

const LoggedInLayout: React.FC = props => {
	const { errors, payload } = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload
	const showNotInGoodStandingBanner = accountOverview?.MembershipSummary.IsDelinquent ?? false
	let showAlert = new Date() < new Date("2023-02-11T17:00:00Z");

	if (ContainsAuthExpiredError(errors)) {
		
       	return <Redirect to={"/login?p="+window.location.pathname.replace("/myffbfportal", "")}/>
    }

	return (
		<>
			<div className="component_LoggedInLayout">
				<header className='topNavBar blueBackground' id='appHeader'>
					{ showNotInGoodStandingBanner && 
						<Container style={{backgroundColor:"#FF671E"}}>
							<NotInGoodStanding	/>
						</Container>
					}
					{ accountOverview&& accountOverview.MembershipSummary.Balance > 0 &&
						<Container>
							<Link className="payDuesAlertLink" to="/MyMembership/PayDues"><b>Pay</b> Membership</Link>
						</Container>
					}
					<Container>
						<div className="greetingSection hideWhenSmall">
							{accountOverview?.UserAccount.ShortName && 
								<h1>Hello { accountOverview.UserAccount.ShortName }</h1>  }
						</div>
					</Container>
					<Navbar
						className='blueBackground'
						variant='dark'
						expand='lg'>
						<Container>
							<HomesteadLogoLink linkTarget="dashboard" />
							
							<Navbar.Toggle aria-controls='basic-navbar-nav' />
							<Navbar.Collapse
								id='basic-navbar-nav'
								className='justify-content-between'>
								<Nav.Link as={Link} to='/myMembership/details'>My Membership</Nav.Link>
								<Nav.Link as={Link} to='/memberBenefits'>Member Benefits</Nav.Link>
								<Nav.Link as={Link} to='/contactUs'>Contact Us</Nav.Link>
								<DropdownButton
									menuAlign="right"
									id='dropdown-basic-button'
									title={<img src={gearIcon} alt='' />}>
									<Dropdown.Item as={Link} to='/myAccount'>
										My Account
									</Dropdown.Item>
									<Dropdown.Item as={Link} to='/logout'>
										Log out
									</Dropdown.Item>
								</DropdownButton>
							</Navbar.Collapse>
						</Container>
					</Navbar>	
					<Container id="art_cheduledMaintenece" hidden={!showAlert}>
						<Alert variant='warning' >
							<Alert.Heading>
								Scheduled Maintenance
							</Alert.Heading>
							<div>
								Please be advised due to scheduled maintenance on Saturday, February 11, 
								the Florida Farm Bureau Federation membership portal (MyFFBF) will be unavailable from 11:00 AM to 5:00 PM, EST. 
								We apologize for any inconvenience this may cause.
							</div>		
						</Alert>
					</Container>		
				</header>
				<main role='main'>{props.children}</main>
				<footer></footer>
			</div>
		</>
	)
}

export default LoggedInLayout

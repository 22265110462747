import React, {useState} from "react"
import PageHeading from "../../views/PageHeading"
import MyFfbfClientApi from "../../api/MyFfbfClientApi"
import {MyMembershipPageTabs} from "../../views/myMembership/MyMembershipPageTabs"
import {Button, Col, Container, Modal, Row} from "react-bootstrap"
import {HideEzPaySignupPathways} from "../../models/AccountOverview"
import {Link, Redirect} from "react-router-dom"
import DeathBenefitAmount from "../../views/DeathBenefitAmount"
import ManageContacts from "../manageContacts/ManageContacts"
import {ContainsAuthExpiredError} from "../../models/ErrorDetail"
import MemberStatus from "../../views/MemberStatus"

const Details: React.FC = () => {
	const {errors, payload} = MyFfbfClientApi.Account.UseAccountOverview()
	const accountOverview = payload

	const [showModal, set_showModal] = useState(false)
	// remove top table border because its ugly right under the pageTabs
	const style = {borderTopWidth: "0"}

	if (ContainsAuthExpiredError(errors)) {
		return <Redirect to='/login' />
	}
	if (accountOverview && accountOverview.MembershipSummary.IsTerminated) {
		return <Redirect to='/myMembership/PayDues' />
	}

	return (
		<div className='component_Details'>
			<PageHeading>
				My <b>Membership</b>
			</PageHeading>
			<Container className='pageContent'>
				{accountOverview && (
					<>
						<MyMembershipPageTabs
							activeTab='Details'
							accountOverview={accountOverview}
						/>
						<Row>
							<Col md={5} sm>
								<table className='table'>
									<tbody>
										<tr>
											<th style={style}>Member&nbsp;Number</th>
											<td style={style}>
												#{accountOverview.MembershipSummary.MemberNumber}
											</td>
										</tr>
										<tr>
											<th>Member&nbsp;Type</th>
											<td>
												{accountOverview.MembershipSummary.MemberClassDescription}
											</td>
										</tr>
										<tr>
											<th>Member&nbsp;Status</th>
											<td>
												<MemberStatus memSummary={accountOverview.MembershipSummary} />
											</td>
										</tr>
										<tr>
											<th>Next Renewal Date</th>
											<td>
												{accountOverview.MembershipSummary.NextRenewalDate}
											</td>
										</tr>
										<tr>
											<th>Membership Dues&nbsp;Plan</th>
											<td>
												{accountOverview.MembershipSummary.DuesPlan}
												{accountOverview.MembershipSummary.DuesPlanIsEZPay ? (
													<div className='subtle'>
														We will automatically bill your card on file each
														year on your Due Date.
													</div>
												) : (
													<></>
												)}
												<Button variant="link" onClick={() => set_showModal(true)}>
													more info
												</Button>
											</td>
										</tr>
										<tr>
											<th>Accidental Death&nbsp;Benefit</th>
											<td>
												<DeathBenefitAmount accountOverview={accountOverview} />
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col md={7} sm>
								<ManageContacts />
							</Col>
						</Row>
					</>
				)}
			</Container>

			{showModal && (
				<Modal
					show={true}
					size='lg'
					onHide={() => set_showModal(false)}
					animation={false}
					dismissable>
					<Modal.Header closeButton>
						<Modal.Title>Membership Dues&nbsp;Plans</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row>
								<Col>
									<h3>Pay as you go</h3>
									<p>
										Payments made by member on an annual basis. You will receive
										an annual membership dues notice 30 days before your renewal
										date.
									</p>
								</Col>
								<Col>
									<h3>EZ Pay</h3>
									<p>
										Your annual membership dues will be automatically paid via a
										credit card on file. You will receive notices 60 days and 30
										days prior to the charge being made.{" "}
									</p>
									{!HideEzPaySignupPathways(accountOverview) && (
										<Link to='/MyMembership/EZPaySummary'>
											Sign up for EZ Pay now!
										</Link>
									)}
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}

export default Details

import React from 'react'
import { Link } from 'react-router-dom'
import AccountOverview, { HideEzPaySignupPathways } from '../../models/AccountOverview'

export const MyMembershipPageTabs: React.FC<{activeTab: "Details" | "Pay Dues" | "EZPay" | "Customize" | "Referral", accountOverview: AccountOverview}> = (props) => {
    return (
        <div className="component_PageTabs">
            <ul className="solidNav" style={{marginBottom: "20px"}}>
                <li className="nav-item">
                    <Link 
                        className={"nav-link " + (props.activeTab === "Details" ? "active " : "")} 
                        to="/myMembership/Details">
                        Membership Details &amp; Family
                    </Link>
                </li>
                <li className="nav-item">
                    <Link 
                        className={"nav-link " + (props.activeTab === "Pay Dues" ? "active " : "")} 
                        to="/myMembership/PayDues">
                        Pay Membership Dues
                    </Link>
                </li>
                { !HideEzPaySignupPathways(props.accountOverview) &&
                    <li className="nav-item">
                        <Link
                            className={"nav-link " + (props.activeTab === "EZPay" ? "active " : "")}
                            to="/myMembership/EZPaySummary">
                            Membership EZ-Pay
                        </Link>
                    </li> }
            </ul>
        </div>
    )
}
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { ErrorDetail } from "../../models/ErrorDetail"
import { Container, Row, Col, Form, Button } from "react-bootstrap" 
import { Link, Redirect } from "react-router-dom"
import { ValidationSummary } from "../../views/MessageBoxes"
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { ContactDetail } from '../../models/RegistrationModels'
import { ResetPasswordRequestObj } from '../../models/AccountModels'
import { Keys } from "../../Utilities"
import OverlaySpinner from "../../views/OverlaySpinner"
import HomesteadLogoLink from '../../layout/HomesteadLogoLink'
import sampleMetroCard_Front from '../../images/sampleMetroCard_Front.png'

const Registration: React.FC = () => {
	const [isPasswordReset, set_isPasswordReset] = useState<null | boolean>(null)
	const [passwordResetState, set_passwordResetState] = useState<"r" | "v" | "p">("r")
	const isRegistration = !isPasswordReset
	const [ResetPasswordRequestObj, set_resetPasswordRequestObj] = useState<ResetPasswordRequestObj>()
	const [verificationCode, set_verificationCode] = useState<string>("")
	const [errors, set_errors] = useState<ErrorDetail[]>([])
	const [isLoading, set_isLoading] = useState(false)

	const [memberNumber, set_memberNumber] = useState("")
	const [email, set_email] = useState("")
	const [zip, set_zip] = useState("")
	const [lastName, set_lastName] = useState("")

	const [registrationContactChoices, set_registrationContactChoices] = useState<ContactDetail[]>([])
	const [selectedContactId, set_selectedContactId] = useState("")

	const [password, set_password] = useState("")
	const [passwordConfirm, set_passwordConfirm] = useState("")
	const [readyToRedirect, set_readyToRedirect] = useState(false)

	const memberNumberInputRef = useRef<HTMLInputElement>(null)

	const generateParamObjBase = () => {
		return { EmailAddress: email, MemberId: memberNumber, LastNameOrCompanyName: lastName, ZipCode: zip }
	}

	// Focus on Email input at pageload
	useEffect(() => {
		memberNumberInputRef?.current?.focus()
		const str = window.location.search.split("?");
		const parms = new Map();
		
		if(str.length > 1) {
			const kvPairs = str[1].split("&");
			kvPairs.forEach(function(kv){
				const pair = kv.split("=");
				parms.set(pair[0].toString(), pair[1]);
			});
		}
		
		set_isPasswordReset(parms.get("v") === "p");
	}, [])

    const handleVerifyRegistrationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_errors([])
		set_isLoading(true)
		const paramObj = generateParamObjBase()
		const [_registrationVerification, _errors] = await MyFfbfClientApi.Registration.VerifyRegistration(paramObj)
		if (_errors.length > 0) {
			set_errors(_errors)
		}
		else {
			const contactChoices = _registrationVerification!.VerifiedContactChoices
			set_registrationContactChoices(contactChoices)
			// if one 1 contact came back from the verification call, then go ahead and make it the selected Contact
			// other wise the UI will ask the user to select the one they are.
			if (contactChoices.length === 1)
				set_selectedContactId(contactChoices[0].ContactId)
		}
		set_isLoading(false)
    }

	const handleCompleteRegistrationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_errors([])
		if (password !== passwordConfirm) {
			set_errors([{ ErrorMessage: "Please re-type your password correctly to confirm it."}])
			return
		}
		set_isLoading(true)
		const paramObj = { ...generateParamObjBase(), NewPasswordForAccount: password, SelectedContactId: selectedContactId }
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, _errors] = await MyFfbfClientApi.Registration.CompleteRegistration(paramObj)
		if (_errors.length > 0) {
			set_errors(_errors)
		}
		else {
			// Go ahead and log the user in, using the creds here, and redirect to Dashboard.
			const paramObj = { EmailUsername: email, Password: password }
			const [_loginPayload, _loginErrors] = await MyFfbfClientApi.Auth.AttemptLogin(paramObj)
			if (_loginErrors.length > 0) {
				set_errors(_loginErrors)
			}
			else {
				// Put the auth guid into session storage so that all further calls from inside the app will include it.
				// WARNING - this is copy-pasted between Login.tsx, PurchaseMembership.tsx, and Registration.tsx 
				sessionStorage.setItem(Keys.authorizationGuidKeyName, _loginPayload!.AuthorizationGuid);
				set_readyToRedirect(true)
			}
		}
		set_isLoading(false)
	}

	const handleResetPasswordRequest = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		set_errors([])
		set_isLoading(true)
		
		var parm = {EmailAddress: email, NewPassword: "",  VerificationCode: ""}
		const [_, _errors] = await MyFfbfClientApi.Account.ResetPasswordRequest(parm)
		
		if (_errors.length > 0) {
			set_errors(_errors)
		}
		else {
			set_passwordResetState("v");		
		}

		set_isLoading(false)
	}

	const handleResetPasswordVerify = async (event: React.FormEvent<HTMLFormElement>) => {
		
		event.preventDefault()
		set_errors([])
		set_isLoading(true)

		var parm = {EmailAddress: email, NewPassword: "", VerificationCode: verificationCode}
		const [ResetPasswordRequestObj, _errors] = await MyFfbfClientApi.Account.ResetPasswordVerification(parm)
		
		if (_errors.length > 0 || ResetPasswordRequestObj == null) {
			set_errors(_errors)
		}
		else {
			set_resetPasswordRequestObj(ResetPasswordRequestObj);
			set_passwordResetState("p")		
		}
		set_isLoading(false)
	}

	const handleResetPasswordComplete = async (event: React.FormEvent<HTMLFormElement>) => {
		
		event.preventDefault()
		set_errors([])

		if (password !== passwordConfirm) {
			set_errors([{ ErrorMessage: "Please re-type your password correctly to confirm it."}])
            return;
        }
		set_isLoading(true)

		if(ResetPasswordRequestObj == null){
			set_readyToRedirect(true);
			return;
		}

		ResetPasswordRequestObj.NewPassword = password;
		ResetPasswordRequestObj.VerificationCode = verificationCode;

        const [_, _errors] = await MyFfbfClientApi.Account.ResetPasswordComplete(ResetPasswordRequestObj)

        if (_errors.length > 0 && false) {
            //ask Graham why this is getting any errors
			set_errors(_errors)
		}
		else {
			// Go ahead and log the user in, using the creds here, and redirect to Dashboard.
			const paramObj = { EmailUsername: email, Password: password }
			const [_loginPayload, _loginErrors] = await MyFfbfClientApi.Auth.AttemptLogin(paramObj)
			if (_loginErrors.length > 0) {
				set_errors(_loginErrors)
			}
			else {
				// Put the auth guid into session storage so that all further calls from inside the app will include it.
				// WARNING - this is copy-pasted between Login.tsx, PurchaseMembership.tsx, and Registration.tsx 
				sessionStorage.setItem(Keys.authorizationGuidKeyName, _loginPayload!.AuthorizationGuid);
				set_readyToRedirect(true)
			}
		}

		set_isLoading(false) 
	}

	if (readyToRedirect) {
        return <Redirect to="/dashboard" />
    }

	return <>
		<div className='registrationPage'>
			{ isLoading && <OverlaySpinner /> }
			<header></header>
			<main role='main'>	
				{isRegistration && <>	
					<Container className="registrationInstructions">
						<div className="registrationLogo">
							<HomesteadLogoLink linkTarget="login" />
						</div>
						<div className="regPage_Instructions d-none d-lg-block">
							<div className="textSection">
								Register Your MyFFBF Account
								<p style={{fontWeight: "bold"}}>
									Already a Farm Bureau member, but logging into MyFFBF for the first time?
									Setup your MyFFBF now with:
								</p>
								<ol>
									<li>Your Farm Bureau Membership number</li>
									<li>Your 5-digit Zip code</li>
									<li>A valid email address</li>
								</ol>						
							</div>				
						</div>				
					
						<div className="regPage_Verification d-none d-lg-block">
							<h4>Membership Verification</h4>

							<p>Your Membership Number can be found on your Farm Bureau Membership Card, Renewal Reminder, Policy Declaration, and/or Billing Statement.</p>

							<div style={{width: "375px", margin: "15px auto"}}>
								<img src={sampleMetroCard_Front} alt="" />
							</div>
						</div>
						
					</Container>
				
					<div className="regPage_Form">
						<ValidationSummary errors={errors} />
						<Container>
							{ registrationContactChoices.length === 0 &&
								<Form onSubmit={handleVerifyRegistrationSubmit}>
									<h3 className="regPage_Verification d-lg-none d-xl-none" style={{textAlign:"center"}}>Register Your Account</h3>
									<div className="d-none d-lg-block">
										<p style={{fontWeight: "bold"}}>When you create your MyFFBF account, you can:</p>
										<ul>
											<li>Pay your Membership Dues</li>
											<li>Sign up for EZ Pay (automatic renewals)</li>
											<li>Update Email, Mailing Address &amp; Phone Number</li>
											<li>Access and view all Member Discounts</li>
										</ul>
									</div>
									<Form.Group controlId="formMemberNumber">
										<Form.Label>
											<strong>Member Number:</strong>
										</Form.Label>
										<Form.Control
											type='text'
											value={memberNumber}
											ref={memberNumberInputRef}
											onChange={e => set_memberNumber(e.target.value)}
										/>
									</Form.Group>

									<Form.Group controlId="formLastName">
										<Form.Label >
										<strong>Your Last Name (If your membership is representing a business, enter your full business name):</strong>
										</Form.Label>
										<Form.Control
											type='text'
											value={lastName}
											onChange={e => set_lastName(e.target.value)}
										/>
									</Form.Group>

									<Form.Group controlId="formZipCode">
										<Form.Label >
											<strong>Zip Code (5-digit):</strong>
										</Form.Label>
										<Form.Control
											type='text'
											value={zip}
											maxLength={5}
											onChange={e => set_zip(e.target.value)} />
									</Form.Group>

									<Form.Group controlId="formYourEmail">
										<Form.Label>
											<strong>Email Address:</strong>
										</Form.Label>
										<Form.Control
											type='email'
											value={email}
											onChange={e => set_email(e.target.value)} />
									</Form.Group>

									<div style={{textAlign: "center"}}>
										<div style={{marginBottom:"15px"}}><Button variant="warning" size="lg" as='input' type='submit' value='Register' /></div>
										<div className="regPage_Guest ">
											<div>
												<Button variant="warning" size="lg"><Link to="/guestCheckout">Continue as Guest</Link></Button>
											</div>
										</div>
										<div style={{marginTop: "40px"}} className="d-none d-lg-block">
											<div  style={{marginBottom:"15px"}}><strong>Already Registered?</strong></div>
											<Button variant="warning" size="lg"><Link to="/login">Login</Link></Button>
										</div>
										<Link to='/registration?v=p' style={{ fontSize:"12px", color: "#fff"}} className="d-lg-none d-xl-none ">
											Already Registered? Click Here
										</Link>
										{/* <div style={{marginTop: "40px"}}>
											Need One Time Access to Pay Membership?<br />
											<Button variant="warning" size="lg"><Link to="/guestCheckout">Continue as Guest</Link></Button>
										</div> */}
									</div>
								</Form>

							}
							
							{ registrationContactChoices.length > 0 && !selectedContactId &&
								<>
									<h3 style={{marginBottom: "30px"}}>We can’t wait to get to know you!<br />Tell us who you are:</h3>
									{ registrationContactChoices.map(rcc => 
										<div key={rcc.ContactId} style={{marginBottom: "15px"}}>
											<Button 
												variant="warning" 
												size="lg"
												onClick={() => set_selectedContactId(rcc.ContactId)}>
												{rcc.FirstName} {rcc.LastNameOrCompanyName}
											</Button>
											&nbsp;&nbsp;&nbsp;
										</div>) }
								</>
							}

                            { selectedContactId &&
								<Form onSubmit={handleCompleteRegistrationSubmit}>
									<h3 style={{marginBottom: "30px"}}>Please create a password below to complete your account:</h3>
									<Form.Group as={Row} className='mb-3' controlId="formYourPassword">
										<Form.Label column sm='3'>
											Your Password: 
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='password'
												value={password}
												onChange={e => set_password(e.target.value)}
											/>
										</Col>
										<Col sm='5'>
											Use at least 10 characters with at least one number, one uppercase and one lowercase letter.
											Please do not re-use this password among any other 
											sites/accounts that you use. 
										</Col>
									</Form.Group>

									<Form.Group as={Row} className='mb-3' controlId="formConfirmYourPassword">
										<Form.Label column sm='3'>
										Please re-type Your Password:
										</Form.Label>
										<Col sm='4'>
											<Form.Control
												type='password'
												value={passwordConfirm}
												onChange={e => set_passwordConfirm(e.target.value)}
											/>
										</Col>
										<Col sm='5'>
											Please re-type your password from the line above here to make sure that you 
											have entered it correctly. 
										</Col>
									</Form.Group>

									<Form.Group as={Row} className='mb-3'>
										<Col sm='3'>
										</Col>
										<Col sm='4'>
											<Button variant="warning" size="lg" as='input' type='submit' value='Submit' />
										</Col>
										<Col sm='5'></Col>
									</Form.Group>
								</Form>
							}
						</Container>						
					</div>
				</>}

				
				{ isPasswordReset && <>
					<Container className="PasswordResetInstructions"> 	
					<div className="d-none d-lg-block" style={{marginBottom: "6em"}}>
						<HomesteadLogoLink linkTarget="login" />
					</div>

					<div className="d-lg-none d-xl-none" style={{marginBottom: "1em"}}>
						<div className="registrationLogo">
							<HomesteadLogoLink linkTarget="login"  />
						</div>
					</div>

					<div className="textSection">
						Forgot Password
						<p style={{fontWeight: "bold"}}>
							Enter in the email address of your MyFFBF account. An email will be sent to you
							with a temporary verification code. Enter the code here to reset your password. 
						</p>
					</div>

					</Container>
				</> }

				{ isPasswordReset && passwordResetState === "r" && <>
					<Container>	
					<ValidationSummary errors={errors} />	
					<div className="passwordreset_Form">
						<Form onSubmit={handleResetPasswordRequest}>
							<h3 style={{marginBottom: "30px"}}>Please enter your email address:</h3>
							<Form.Group controlId="formYourEmail">
									<Form.Label>
										<strong>Email Address:</strong>
									</Form.Label>
									<Form.Control
										type='email'
										value={email}
										onChange={e => set_email(e.target.value)} />
								</Form.Group>
							<div style={{textAlign: "center"}}>
								<Button variant="warning" size="lg" as='input' type='submit' value='Submit' />
							</div>
						</Form>
												
					</div>
					</Container>
				</> }

				{ isPasswordReset && passwordResetState === "v" && <>
					<Container>	
						<ValidationSummary errors={errors} />					
						<Form onSubmit={handleResetPasswordVerify}>
							<h3 style={{marginBottom: "30px"}}>Please enter your verification code:</h3>
							<Form.Group controlId="formverificationCode">
								<Form.Label>
									<strong>Verification Code:</strong>
								</Form.Label>
								<Form.Control
									type="Text"
									value={verificationCode}
									onChange={e => set_verificationCode(e.target.value)} />
							</Form.Group>
							<div style={{textAlign: "center"}}>
								<Button variant="warning" size="lg" as='input' type='submit' value='Submit' />
							</div>
						</Form>
						
					</Container>	
				</> }

				{ isPasswordReset && passwordResetState === "p" && <> 
					<Container>
						<ValidationSummary errors={errors} />	
						<Form onSubmit={handleResetPasswordComplete}>
						<h3 style={{marginBottom: "30px"}}>Please create a password below to complete your account:</h3>
							<Form.Group as={Row} className='mb-3' controlId="formYourPassword">
								<Form.Label column sm='3'>
									Your Password: 
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										type='password'
										value={password}
										onChange={e => set_password(e.target.value)}
									/>
								</Col>
								<Col sm='5'>
									Use at least 10 characters with at least one number, one uppercase and one lowercase letter.
									Please do not re-use this password among any other 
									sites/accounts that you use.
								</Col>
							</Form.Group>

							<Form.Group as={Row} className='mb-3' controlId="formConfirmYourPassword">
								<Form.Label column sm='3'>
								Please re-type Your Password:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										type='password'
										value={passwordConfirm}
										onChange={e => set_passwordConfirm(e.target.value)}
									/>
								</Col>
								<Col sm='5'>
									Please re-type your password from the line above here to make sure that you 
									have entered it correctly. 
								</Col>
							</Form.Group>
							<div style={{textAlign: "center"}}>
								<Button variant="warning" size="lg" as='input' type='submit' value='Submit' />
							</div>
						</Form>	
					</Container>
				</> }
			</main>
			<footer className="d-none d-lg-block">
				<div className="regPage_Guest">
					<div>
						<p><b>Need One Time Access to Pay Membership?</b></p>
						<Button variant="warning" size="lg"><Link to="/guestCheckout">Continue as Guest</Link></Button>
					</div>
				</div>
			</footer>
		</div>
	</>
}

export default Registration
import React, { useState } from 'react'   
import { Alert, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ExternalLink } from './ExternalLink'

export const UpcomingEZPay: React.FC<{defaultCardLastFour: string, showMessageAboutCardReplacement: boolean}> = (props) => {
    return <>
        <Alert variant="primary">
           <Alert.Heading>You have a card on file to automatically pay your current Dues on their due date</Alert.Heading> 
           <b>You do not need to pay your dues today</b> if you'd still like us to charge your card on file when your dues are due to be paid.<br />
                If you'd like to make a payment right now instead, you may use the form below to do so. <br />
                { props.showMessageAboutCardReplacement && 
                    <b>The card you enter below will replace the ending with {props.defaultCardLastFour} 
                        as the card that we will use for next year's dues.</b> 
                }
        </Alert>
    </>
}

export const EZPaySignupConfirmation = () => {
    return <>
        <Alert variant="primary">
            <h4>Thank you for signing up for Farm Bureau Membership EZ-Pay!</h4>
            <p>
                This is the best way to make sure your support for Florida’s agricultural and rural communities continues uninterrupted. Your credit card information has been securely saved and will only be used to automatically pay your annual membership dues in the future.
                Click here to <Link to="/myMembership/ezPaySummary">review your card on file</Link> if needed.
            </p>
        </Alert>
    </>
}

export const EZPayPromo = () => {
    
    return <>
        <Alert variant="primary">
           <Alert.Heading>Is remembering to pay your annual dues a hassle? Let Membership EZ-Pay help! </Alert.Heading> 
           <div>
                <Link to="/myMembership/eZPayEdit">
                    <Button variant="primary" type="button">
                        Yes, sign me up for Membership EZ-Pay
                    </Button>
                </Link>
                <span style={{marginLeft: "30px", display: "inline-block"}}>
                    <EZPayTermsAndConditionsModal showSignupBtn={true} />
                </span>
            </div>
        </Alert>
    </>
}

export const EZPayTermsAndConditionsModal: React.FC<{showSignupBtn: boolean}> = (props) =>  {
    const [modalVisible, set_modalVisible] = useState(false) 
    
    return <>
        <Button variant="link" className="edit" onClick={() => set_modalVisible(true)}>
            Click here for more information about our Terms and Conditions. 
        </Button>

        <Modal show={modalVisible} onHide={() => set_modalVisible(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Membership EZ-Pay is a great way to make sure your annual Farm Bureau member dues are paid on time!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Upon signing up, the credit card information you entered will be saved and used to pay your annual membership dues and any PAC contributions on your listed annual renewal date.
                You will receive a reminder notice to the email address provided in your membership file 60 days prior to the renewal due date.
                You can opt out of Membership EZ-Pay at any time by logging into your account on the Membership Portal at www.Floridafarmbureau.org.</p>

                <div className="termsConditionsHolder">
                    <div><b>Florida Farm Bureau Membership Terms</b></div>

                    <div>Welcome to the terms and conditions ("Terms") for Florida Farm Bureau Federation membership. These Terms are between you and Florida Farm Bureau Federation and/or your county Farm Bureau and govern our respective rights and obligations. Please note that your use of the Florida Farm Bureau Federation website and Farm Bureau membership are also governed by the agreements listed and linked to below, as well as all other applicable terms, conditions, limitations, and requirements on the FloridaFarmBureau.org website, all of which (as changed over time) are incorporated into these Terms. By signing up for a Farm Bureau membership, you accept these terms, conditions, limitations and requirements.</div>

                    <div><ul><li><ExternalLink href="https://www.floridafarmbureau.org/privacy-policy-and-terms-of-use/">Privacy Policy and Terms of Use</ExternalLink></li></ul></div>

                    <div>
                        <b>Membership Cancellation</b>
                        <br />You may cancel your Farm Bureau membership any time by contacting your county Farm Bureau office or the Florida Farm Bureau Federation membership office at 352-374-1502. If you cancel within 10 business days of payment of a Farm Bureau membership, a refund of your full membership fee can be made; provided that you have not taken advantage of any of the member benefits exclusively granted to a Farm Bureau member. No refund will be granted beyond 10 business days. Farm Bureau memberships redeemed through a gift code or promotional code are not refundable.
                    </div>

                    <div>
                        <b>Other Limitations</b><br />
                        <ul>
                            <li>We reserve the right to accept or refuse membership in our discretion.</li>
                            <li>We may send you email and other communications related to your Farm Bureau membership (regardless of any settings or preferences related to your Farm Bureau membership account).</li>
                            <li>You may not transfer or assign your Farm Bureau membership or any Farm Bureau benefits, including promotion codes for Farm Bureau memberships or benefits, except as allowed in these terms.</li>
                            <li>Farm Bureau members are not permitted to purchase products for the purpose of resale, rental, or to ship to their customers or potential customers using Farm Bureau benefits.</li>
                            <li>Some Farm Bureau benefits may require certain purchase thresholds, have quantity or shipping address limitations, or require members to meet specified criteria in order to access them.</li>
                            <li>From time to time, Florida Farm Bureau may choose in its sole discretion to add or remove Farm Bureau membership benefits.</li>
                        </ul>
                    </div>

                    <div>
                        <b>Membership Dues</b><br /> Dues for membership in Farm Bureau is determined by the individual county Farm Bureau. The Farm Bureau membership fee is non-refundable except as expressly set forth in these Terms. As a 501c(5) organization, Farm Bureau membership dues are not tax deductible, but may be deductible as an ordinary and necessary business expense.
                    </div>

                    <div>
                        <b>Membership EZ-Pay</b> <br /> If you sign up for Membership EZ-Pay through the Florida Farm Bureau Federation and are then billed by the Florida Farm Bureau Federation, then the billing terms described below will apply to your membership.
                    </div>

                    <div>
                        If the eligible payment methods we have on file for you are declined for payment of
                        your membership fee, you must provide us a new eligible payment method promptly or
                        your membership will be canceled. If you provide us with a new eligible payment
                        method and are successfully charged, your new membership period will be based on
                        the original renewal date and not the date of the successful charge.
                    </div>

                    <div>
                        UNLESS YOU NOTIFY US BEFORE A CHARGE THAT YOU WANT TO CANCEL OR DO NOT
                        WANT TO AUTO RENEW, YOU UNDERSTAND YOUR FARM BUREAU MEMBERSHIP WILL
                        AUTOMATICALLY CONTINUE AND YOU AUTHORIZE US (WITHOUT NOTICE TO YOU, UNLESS
                        REQUIRED BY APPLICABLE LAW) TO COLLECT THE THEN-APPLICABLE MEMBERSHIP FEE
                        AND ANY TAXES, USING ANY ELIGIBLE PAYMENT METHOD WE HAVE ON RECORD FOR
                        YOU.
                    </div>

                    <div>
                        <b>Agreement Changes</b><br />
                        We may in our discretion change these Terms, Florida Farm Bureau.org Privacy Policy
                        and Terms of Use, or any aspect of Farm Bureau membership, without notice to you. If
                        any change to these terms is found invalid, void, or for any reason unenforceable, that
                        change is severable and does not affect the validity and enforceability of any
                        remaining changes or conditions. YOUR CONTINUED MEMBERSHIP AFTER WE CHANGE
                        THESE TERMS CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES. IF YOU DO NOT AGREE
                        TO ANY CHANGES, YOU MUST CANCEL YOUR MEMBERSHIP.
                    </div>

                    <div>
                        <b>Termination</b><br />
                        We may terminate your Farm Bureau membership at our discretion without notice. If we
                        do so, we will give you a prorated refund based on the number of full months remaining
                        in your membership. However, we will not give any refund for termination related to
                        conduct that we determine, in our discretion, violates these Terms or any applicable
                        law, involves fraud or misuse of the Farm Bureau membership, or is harmful to our
                        interests or another user. Our failure to insist upon or enforce your strict compliance with
                        these Terms will not constitute a waiver of any of our rights.
                    </div>

                    <div>
                        <b>Limitation of Liability</b><br />
                        IN ADDITION TO OTHER LIMITATIONS AND EXCLUSIONS IN
                        FLORIDAFARMBUREAU.ORG PRIVACY POLICY AND TERMS OF USE, OUR TOTAL LIABILITY,
                        WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
                        WILL NOT EXCEED THE LAST MEMBERSHIP FEE YOU PAID. THIS LIMITATION OF LIABILITY WILL
                        APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR
                        TERMINATION OF YOUR FARM BUREAU MEMBERSHIP.
                    </div>

                    <div>
                        <b>Disputes</b><br />
                        Any dispute or claim relating in any way to these Terms or your use of Farm Bureau, or to
                        any products or services sold or distributed by Florida Farm Bureau Federation or
                        through FloridaFarmBureau.org will be resolved by binding arbitration, rather than in
                        court. The Federal Arbitration Act and federal arbitration law apply to this agreement.
                    </div>

                    <div>
                        There is no judge or jury in arbitration, and court review of an arbitration award is
                        limited. However, an arbitrator can award on an individual basis the same damages
                        and relief as a court (including injunctive and declaratory relief or statutory damages),
                        and must follow the Terms as a court would.
                    </div>

                    <div>
                        To begin an arbitration proceeding, you must send a letter requesting arbitration and
                        describing your claim to our registered agent Staci Sims, 5700 SW 34th Street, Gainesville,
                        FL 32608. The arbitration will be conducted by the American Arbitration Association
                        (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-
                        Related Disputes. The AAA's rules are available at www.adr.org or by calling 1-800-778-
                        7879. Payment of all filing, administration and arbitrator fees will be governed by the
                        AAA's rules. We will reimburse those fees for claims totaling less than $10,000 unless the
                        arbitrator determines the claims are frivolous. Likewise, Farm Bureau will not seek
                        attorneys' fees and costs in arbitration unless the arbitrator determines the claims are
                        frivolous. You may choose to have the arbitration conducted by telephone, based on
                        written submissions, or in person in the county where your membership is held or at
                        another mutually agreed location.
                    </div>

                    <div>
                        <b>
                            We each agree that any dispute resolution proceedings will be conducted only on an
                            individual basis and not in a class, consolidated or representative action.
                        </b> If for any
                        reason a claim proceeds in court rather than in arbitration we each waive any right to
                        a jury trial. We also both agree that you or we may bring suit in court to enjoin
                        infringement or other misuse of intellectual property rights.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                { props.showSignupBtn && 
                    <Link to="/myMembership/eZPayEdit">
                        <Button variant="primary" type="button">
                            Yes, sign me up for Membership EZ-Pay
                        </Button>
                    </Link> }
            </Modal.Footer>
        </Modal>
    </>
}


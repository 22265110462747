import React, { useState, useEffect } from "react"
import AccountOverview from '../../models/AccountOverview'
import MyFfbfClientApi from '../../api/MyFfbfClientApi'
import { Redirect } from "react-router-dom"
import { ContainsAuthExpiredError, ErrorDetail } from "../../models/ErrorDetail"
import ContactCard from '../manageContacts/ContactCard'
import { ContactType, MemberContactRole, GetName } from "../../models/ApiContact"
import { Button } from "react-bootstrap"
import EditContactModal from "./EditContactModal"
import { LoadingMessageBox } from "../../views/MessageBoxes"
import PrintCardModal from './PrintCardModal'
import RequestCardModal from './RequestCardModal'
import EditAddessModal from './EditAddressModal'
import { ModalEvents } from "../../Utilities"
import { MetroCard } from "../../views/MetroCard"

const ManageContacts = () => {
    const [loading, set_loading] = useState(true)
    const [errors, set_errors] = useState<ErrorDetail[]>([])
    const [accountOverview, set_accountOverview] = useState<AccountOverview | null>(null)

    // Looks weird, but new Date().getDate() returns TICKS since Epoch.
    // We update this when a modal is closed. We use this as a dependency for loading AccountOverview,
    // so that we re-load Account Overview from the server when any modal from the ContactCard is closed.
    const [ticks, set_ticks] = useState<number>(new Date().getTime())

    const [addContactModalData, set_addContactModalData] = useState<{contactType: ContactType,contactRoleChoices: MemberContactRole[]} | null>(null)

    // Request/Print card modals
    const [showPrintCardModal, set_showPrintCardModal] = useState(false)
    const [showRequestCardModal, set_showRequestCardModal] = useState(false)
    const [showAddressEditModal, set_showAddressEditModal] = useState(false)

    // At component load, fetch the AccountOveriew. Don't use 'UseAccountOverview' because we want to 
    // re-fetch this when the ContactCard Modal's are done with their transactions.
    // The ContactCard modals all signal when they are done via the onCompletedCallbackFunc.
    // Pass in the ticks as a dependency so that this function will be re-ran when the ticks change.
    useEffect(() => {
        async function fetchData() {
            set_loading(true)
            const [_acctOvrview, _errors] = await MyFfbfClientApi.Account.GetAccountOverview()
            if (_errors.length > 0)
                set_errors(_errors)
            else {
                set_accountOverview(_acctOvrview!)
            }
            set_loading(false)
        }
        fetchData()
    }, [ticks])

    const contactMappings = accountOverview?.MembershipSummary.MemberContactMappings ?? []
    const isBusiness = accountOverview?.MembershipSummary.IsBusinessMembership ?? []

    const primaryContactMapping = contactMappings.filter(cm => cm.RoleLabel === "Primary")[0]
    const secondaryContactMapping = contactMappings.filter(cm => cm.RoleLabel === "Secondary")[0]
    const childContactsMappings = contactMappings.filter(cm => cm.RoleLabel === "Other")

    const generateContactRoleChoice = () => {
        let _contactRoleChoices: MemberContactRole[] = []

        if (contactMappings.filter(cm => cm.RoleLabel === "Secondary").length === 0)
            _contactRoleChoices.push("Secondary")

        if (!isBusiness)
            _contactRoleChoices.push("Other")

        return _contactRoleChoices
    }


    if (ContainsAuthExpiredError(errors)) {
        return <Redirect to="/login" />
    }

    // Any time any of the various Modals that can pop up from inside the ContactCard are done with their work
    // (which means they are completed OR cancelled by user), then we want to re-fetch the Account Overview
    // from the server, since the Modals (like the contact add/edit) can change the data rendered in this component.
    // This function resets the addContact modal data obj, and also updates the TICKs so that the accoutOverview
    // is re-fetched (because the ticks are in the dependency array of the useState() func).
    const modalEvents: ModalEvents = {
        ModalCompletedEvent: () => {
            set_addContactModalData(null)
            set_showPrintCardModal(false)
            set_showRequestCardModal(false)
            set_showAddressEditModal(false)
        },
        ModalSharedDataUpdateEvent: () => {
            console.log(ticks)
            set_ticks(_ticks => { return new Date().getTime() })
            // Updating ticks above will re-run the useEffect(()=>,[ticks]) function.
        }
    }

    const handleAddClick = () => {
        set_addContactModalData({ contactType : isBusiness ? "Company" : "Person", contactRoleChoices: generateContactRoleChoice() })
    }

    const contactLabel = accountOverview?.MembershipSummary.IsBusinessMembership ? "Contact" : "Family Member"

    if (loading)
        return <LoadingMessageBox />

    if (accountOverview) {
        return <>
            <div className="mainFamilyMembers">
                { primaryContactMapping &&  
                    <ContactCard 
                        accountOverview={accountOverview}
                        key={primaryContactMapping.ContactIdentifier}
                        modalEvents={modalEvents}
                        contact={primaryContactMapping.Contact}
                        contactRole={primaryContactMapping.RoleLabel} />}

                { secondaryContactMapping
                    ? <ContactCard 
                        accountOverview={accountOverview}
                        key={secondaryContactMapping.ContactIdentifier}
                        modalEvents={modalEvents}
                        contact={secondaryContactMapping.Contact}
                        contactRole={secondaryContactMapping.RoleLabel} /> 

                    : <button className="bigLinkTarget" onClick={handleAddClick}>
                        Add Spouse<br />+
                      </button> }

            </div>

            <div className="otherFamilyMembers">
                { childContactsMappings.map(cm => 
                    <ContactCard 
                        accountOverview={accountOverview}
                        key={cm.ContactIdentifier}
                        modalEvents={modalEvents}
                        contact={cm.Contact}
                        contactRole={cm.RoleLabel} />) }   
                <button className="bigLinkTarget" onClick={handleAddClick}>Add {contactLabel}s<br />+</button> 
            </div>

            <div className="sectionBlock">
                <h3>Your Mailing Address</h3>
                <div>
                    <div style={{fontWeight: "bold", padding: "20px"}}>
                        { GetName(primaryContactMapping.Contact) }<br />
                        { primaryContactMapping.Contact.Address.Line1 }<br />
                        { primaryContactMapping.Contact.Address.Line2 && 
                            <>{ primaryContactMapping.Contact.Address.Line2 }<br /></> }
                        { primaryContactMapping.Contact.Address.City }&nbsp;
                        { primaryContactMapping.Contact.Address.StateAbbreviation }&nbsp;
                        { primaryContactMapping.Contact.Address.Zip }
                    </div>
                    <div className="actions">
                        <Button variant="primary" onClick={() => set_showAddressEditModal(true)}>Change your Mailing Address</Button>
                    </div>
                </div>
            </div>
        
            <div className="sectionBlock">
                <h3>Card Preview</h3>
                <div>
                    <div>
                        <MetroCard
                            memberNumber={accountOverview.MembershipSummary.MemberNumber} 
                            memberClass={accountOverview.MembershipSummary.MemberClassDescription} 
                            county={accountOverview.CountyOffice.Name} 
                            name={GetName(accountOverview.UserAccount.Contact)} />
                    </div>
                    <div className="actions">
                        <Button variant="primary" onClick={() => set_showPrintCardModal(true)}>Print Membership<br />Card Now</Button>
                        <Button variant="warning" onClick={() => set_showRequestCardModal(true)}>Request New<br />Membership Card</Button>
                    </div>
                </div>
            </div>

            {/* "Add Contact" Modal here - its named "EditContactModal" but since we're passing in contact={null} then its in 'Add' mode. */}
            <div>
                { addContactModalData && 
                    <EditContactModal
                        accountOverview={accountOverview}
                        contactType={addContactModalData.contactType}
                        contactRoleChoices={generateContactRoleChoice()}
                        contact={null}
                        modalEvents={modalEvents} /> }

                { showPrintCardModal && 
                    <PrintCardModal 
                        memberNumber={accountOverview.MembershipSummary.MemberNumber} 
                        memberClass={accountOverview.MembershipSummary.MemberClassDescription} 
                        county={accountOverview.CountyOffice.Name} 
                        name={GetName(primaryContactMapping.Contact)}
                        modalEvents={modalEvents} /> }

                { showRequestCardModal && 
                    <RequestCardModal
                        modalEvents={modalEvents}
                        memberNumber={accountOverview.MembershipSummary.MemberNumber}
                        contactName={GetName(accountOverview.UserAccount.Contact)} /> }

                { showAddressEditModal &&
                    <EditAddessModal
                        modalEvents={modalEvents}
                        primaryContact={primaryContactMapping.Contact} /> }

            </div>        
        </>
    }

    return <></>
}

export default ManageContacts
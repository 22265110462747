import React from 'react'
import { MembershipSummary } from '../models/AccountOverview'

const MemberStatus:React.FC<{memSummary: MembershipSummary | undefined}> = (props) => {
    // // #898989 for everything but Current/ZBilled which uses 228422
    if (!props.memSummary) 
        return <></>

    const isCurrent = props.memSummary.MemberStatus === "Current"
    const showBilledText = isCurrent && props.memSummary.Balance > 0
    const htmlColor = isCurrent ? "#228422" : "#898989"

    return <span style={{color: htmlColor}}>{props.memSummary.MemberStatus}{showBilledText ? " (Billed)" : ""}</span>
}

export default MemberStatus
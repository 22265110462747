import React from 'react'
import { Link } from 'react-router-dom'

const HomesteadLogoLink: React.FC<{linkTarget: "dashboard" | "login"}> = (props) => {
    return <>
        <Link className='logoLink' to={"/" + props.linkTarget}>
            <img id={'ffbLogo'}  
                src={'https://apps.floridafarmbureau.com/_cdn/img/FFBFLogoWhite.svg'}
                alt='Florida Farm Bureau MyFFBF'
            />
        </Link>
    </>
}

export default HomesteadLogoLink